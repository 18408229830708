import useUpdateUserSubjectGroups from "api/UserResource/useUpdateUserSubjectGroups";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";

/**
 * @param {Object} props
 * @param {string|number} props.id
 * @param {Array<{id:number, name:string}>} props.assignedSubjectGroups
 * @param {Array<{id:number, name:string}>} props.subjectGroups
 */
const SubjectGroups = (props) => {
	const { id, assignedSubjectGroups, subjectGroups } = props;

	const subjectGroupsList = subjectGroups?.map((subject) => ({
		label: subject.name,
		value: subject.id,
	}));

	const initialValues = {
		subject_group: assignedSubjectGroups || [],
	};

	const { mutateAsync, isLoading } = useUpdateUserSubjectGroups(id);

	const handleUpdate = async (data) => {
		await mutateAsync(data);
	};

	return (
		<div
			className="space-y-2"
			style={{ maxWidth: 600 }}
		>
			<div>
				<h4 className="">Subject groups</h4>
				<p className="text-gray-500">
					Update student's subject groups to assign or remove them from specific
					groups
				</p>
			</div>

			<div className="space-y-2">
				<p>Current subject groups</p>
				<div className="flex gap-1 items-center">
					{subjectGroupsList
						?.filter((sub) => assignedSubjectGroups.includes(sub.value))
						?.map((subjectGroup) => (
							<span
								key={subjectGroup.value}
								className="bg-gray-100 px-2 py-1 rounded-md border border-akgreen"
							>
								{subjectGroup.label}
							</span>
						))}
				</div>
			</div>

			<Formik
				initialValues={initialValues}
				onSubmit={handleUpdate}
			>
				<Form className="pt-10 space-y-6">
					<FormField
						type="multiselect"
						name="subject_group"
						label="Subject group "
						options={subjectGroupsList?.map((subjectGroup) => ({
							label: subjectGroup.label,
							value: subjectGroup.value,
						}))}
					/>

					<button
						type="submit"
						className="px-4 py-2 rounded-xl bg-akgreener text-white flex gap-1 items-center justify-center"
					>
						{isLoading ? (
							<>
								Updating{" "}
								<AiOutlineLoading3Quarters
									size={20}
									className="animate-spin"
								/>
							</>
						) : (
							<>
								Update subject groups <IoIosArrowForward size={20} />
							</>
						)}
					</button>
				</Form>
			</Formik>
		</div>
	);
};

export default SubjectGroups;

/**
 * @typedef {Object} OrdersFilterProps
 * @property {number} numberOfActiveFilters
 *
 * @param {OrdersFilterProps} props
 */

import useInitialQueryData from "hooks/useInitialQueryData";
import { useOrderFilterContext } from "./OrderFilterContext";
import { formData } from "./ordersQueryParams";
import { useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { Sidebar } from "primereact/sidebar";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

const OrdersFilter = (props) => {
	const { numberOfActiveFilters = 0 } = props;

	const [visible, setVisible] = useState(false);

	const { filters } = useOrderFilterContext();

	const { filterValues, setFilterValues } = useInitialQueryData(
		filters,
		formData
	);

	const changeInput = (inputName, value) => {
		setFilterValues((prev) => ({ ...prev, [inputName]: value }));
	};

	const handleApplyFilters = (e) => {
		e.preventDefault();
		filters?.changeAllFiltersAtOnce(filterValues);
		setVisible(false);
	};

	const handleClearFilters = () => {
		const params = filters?.allSearchParams
			?.map((param) => ({
				[param]: "",
			}))
			.reduce((acc, curr) => ({ ...acc, ...curr }), {});

		setFilterValues((prev) => ({ ...prev, ...params }));
		filters?.changeAllFiltersAtOnce(params);
		setVisible(false);
	};

	return (
		<>
			<button
				type="button"
				onClick={() => setVisible(!visible)}
				className="flex items-center gap-1 h-11 border border-gray-300 rounded-lg min-w-max px-4"
			>
				Advanced filter
				<BiMenuAltRight size={20} />
			</button>

			<Sidebar
				visible={visible}
				onHide={() => setVisible(false)}
				position="right"
				blockScroll
				className="max-w-max px-4 py-4 "
			>
				<form
					onSubmit={handleApplyFilters}
					className="mb-4 flex flex-col gap-3  py-4  "
					style={{
						height: "calc(100%-50px)",
					}}
				>
					<p className="font-bold">Filter orders by the following</p>

					<Calendar
						value={new Date(filterValues?.fromDate)}
						name="fromDate"
						dateFormat="yy-mm-dd"
						placeholder="From (yyy-mm-dd)"
						className="h-11 w-64 font-sans"
						inputClassName={`${
							filterValues?.fromDate
								? "border-2 border-akgreener text-akgreener"
								: "border border-gray-300"
						}  bg-white rounded  px-2`}
						showIcon
						readOnlyInput
						showButtonBar
						onChange={(e) =>
							changeInput(
								"fromDate",
								moment(e.target.value).format("YYYY-MM-DD")
							)
						}
						onClearButtonClick={() => changeInput("fromDate", "")}
					/>

					<Calendar
						value={new Date(filterValues?.toDate)}
						name="toDate"
						dateFormat="yy-mm-dd"
						placeholder="To (yyy-mm-dd)"
						className="h-11 w-64 font-sans"
						inputClassName={`${
							filterValues?.toDate
								? "border-2 border-akgreener text-akgreener"
								: "border border-gray-300"
						}  bg-white rounded  px-2`}
						showIcon
						readOnlyInput
						showButtonBar
						onChange={(e) =>
							changeInput("toDate", moment(e.target.value).format("YYYY-MM-DD"))
						}
						onClearButtonClick={() => changeInput("toDate", "")}
					/>

					<Dropdown
						value={filterValues.orderStatus}
						onChange={(e) => changeInput("orderStatus", e.value)}
						options={[
							{ label: "Order status (all)", value: "" },
							{ label: "Completed", value: "Completed" },
							{ label: "Failed", value: "Failed" },
						]}
						optionLabel="label"
						placeholder="Order status"
						className="h-11 w-64 font-sans"
					/>

					<button
						type="submit"
						className="bg-akgreen flex items-center justify-center gap-2 text-white h-11 rounded-lg px-4"
					>
						Apply filters
					</button>

					{numberOfActiveFilters > 0 && (
						<button
							onClick={handleClearFilters}
							className="border border-akgreen flex items-center justify-center gap-2 h-11 rounded-lg px-4"
						>
							Clear filters
							<span className="flex items-center rounded-full justify-center w-8 h-8 border border-akgreen">
								{numberOfActiveFilters}
							</span>
						</button>
					)}
				</form>
			</Sidebar>
		</>
	);
};

export default OrdersFilter;

import React from "react";
import { useLocation } from "react-router-dom";

const useSearchParams = () => {
	/**
	 * @var {Location} location - The location object
	 * @see https://reactrouter.com/en/6.22.0/hooks/use-location
	 */
	const location = useLocation();

	/**
	 * @var {URLSearchParams} searchParams - The URLSearchParams object
	 */
	const searchParams = React.useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	);

	/**
	 * Get a query parameter value
	 * @param {string} key - The query parameter key
	 * @returns {string} - The query parameter value
	 * @example
	 * const { getParam } = useSearchParams();
	 */
	const getParam = React.useCallback(
		(key) => {
			return searchParams.has(key)
				? searchParams.get(key) || undefined
				: undefined;
		},
		[searchParams]
	);

	/**
	 * Set a query parameter value
	 * @param {string} key - The query parameter key
	 * @param {string} value - The query parameter value
	 * @returns {string} - The new search params string
	 * @example
	 * const { setParam } = useSearchParams();
	 */
	const setParam = React.useCallback(
		(key, value) => {
			searchParams.set(key, value);
			return searchParams.toString();
		},
		[searchParams]
	);

	/**
	 * Delete a query parameter
	 * @param {string} key - The query parameter key
	 * @returns {string} - The new search params string
	 * @example
	 * const { deleteParam } = useSearchParams();
	 */
	const deleteParam = React.useCallback(
		(key) => {
			searchParams.delete(key);
			return searchParams.toString();
		},
		[searchParams]
	);

	/**
	 * Check if a query parameter exists
	 * @param {string} key - The query parameter key
	 * @returns {boolean} - True if the query parameter exists, false otherwise
	 * @example
	 *  const { hasParam } = useSearchParams();
	 */
	const hasParam = React.useCallback(
		(key) => {
			return searchParams.has(key);
		},
		[searchParams]
	);

	return {
		getParam,
		setParam,
		deleteParam,
		hasParam,
	};
};

export default useSearchParams;

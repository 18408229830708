import { FiX } from "react-icons/fi";

/**
 * @typedef {Object} ClearFiltersProps
 * @property {number} numberOfActiveFilters
 * @property {Object} filters
 *
 * @param {ClearFiltersProps} props
 */
const ClearFilters = (props) => {
	const { numberOfActiveFilters, filters } = props;

	const handleClearFilters = () => {
		/**
		 * @type {Array<string>} allSearchParams
		 */
		const allSearchParams = filters?.allSearchParams || [];
		const resetParams = allSearchParams
			?.filter((param) => !filters.paginationParams.includes(param))
			?.map((param) => ({
				[param]: "",
			}))
			.reduce((acc, curr) => ({ ...acc, ...curr }), {});

		filters?.changeAllFiltersAtOnce(resetParams);
	};

	return (
		numberOfActiveFilters > 0 && (
			<button
				type="button"
				onClick={handleClearFilters}
				className="relative flex items-center justify-center gap-1 min-w-max h-11 border border-akgreener rounded-lg px-4"
			>
				Clear filters <FiX size={20} />
				<span className="absolute -top-5 -right-2 w-8 h-8 rounded-full flex items-center justify-center bg-akgreener text-white ">
					{numberOfActiveFilters}
				</span>
			</button>
		)
	);
};

export default ClearFilters;

export const Permission = Object.freeze({
	CreateBook: "create book",
	DeleteBook: "delete book",
	ViewBooks: "view book collection",
	ViewBook: "view book details",
	SetWeeklyPick: "set book as pick of the week",
	SetRecommended: "set book as recommended",
	ActivateBook: "activate or deactivate books",
	CreateCategory: "create book category",
	ViewCategory: "view book category",
	UpdateCategory: "update book category",
	DeleteCategory: "delete book category",
	CreateGenre: "create book genre",
	ViewGenre: "view book genre",
	UpdateGenre: "update book genre",
	DeleteGenre: "delete book genre",
	CreateFx: "create exchange rate",
	ViewFx: "view exchange rate",
	UpdateFx: "update exchange rate",
	DeleteFx: "delete exchange rate",
	CreateInstitution: "create institution",
	ViewInstitution: "view institution",
	UpdateInstitution: "update institution",
	DeleteInstitution: "delete institution",
	CreateOrder: "create order",
	ViewOrder: "view order",
	UpdateOrder: "update order",
	DeleteOrder: "delete order",
	CreateUser: "create user",
	ViewUser: "view user",
	UpdateUser: "update user",
	DeleteUser: "delete user",
	CreatePublisher: "create publisher",
	ViewPublisher: "view publisher",
	UpdatePublisher: "update publisher",
	DeletePublisher: "delete publisher",
	CreateRating: "create rating",
	ViewRating: "view rating",
	UpdateRating: "update rating",
	DeleteRating: "delete rating",
	PublishRating: "publish rating",
	CreateSubscription: "create subscription",
	ViewSubscription: "view subscription",
	UpdateSubscription: "update subscription",
	DeleteSubscription: "delete subscription",
	PublishSubscription: "publish subscription",
	ViewUserRegistrations: "view user registrations",
	ViewLibRevenue: "view library revenue generated",
	ViewLibBooksReport: "view library books report",
	ViewPubRevenue: "view publisher's revenue report",
	ViewPubBooksReport: "view publisher's books report",
	CreateVoucher: "create voucher",
	ViewVoucher: "view voucher",
	UpdateVoucher: "update voucher",
	RecordPubPayouts: "record publisher payments",
	ViewErrors: "view error",
	RunCli: "run cli",
	ViewSoldBooks: "view sold book",
	ViewBaReceipts: "view BA clients payment",
	RecordBaPayouts: "pay BA",
	ViewBa: "view BA",
	ViewBaStats: "view BA stats",
	CreateSubjectGroup: "create subject group",
	ViewSubjectGroup: "view subject group",
	UpdateSubjectGroup: "update subject group",
	DeleteSubjectGroup: "delete subject group",
	CreateRole: "create role",
	ViewRole: "view role",
	UpdateRole: "update role",
});

import WavyLoader from "components/v2/WavyLoader";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";

const LoadingCart = () => {
	return (
		<Container>
			<PaddingX>
				<div className="space-y-4 pt-10 ">
					<div className="flex justify-between items-center gap-4">
						<WavyLoader
							height={20}
							width={250}
						/>
						<WavyLoader
							height={40}
							width={100}
							className="border border-green-200 rounded-full"
						/>
					</div>

					<WavyLoader
						height={384}
						width={"100%"}
					/>
				</div>
			</PaddingX>
		</Container>
	);
};

export default LoadingCart;

import useBook from "api/BookResource/useBook";
import { ButtonLoader } from "components";
import StarRating from "components/v2/BookItem/StarRating";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import React from "react";
import { useParams } from "react-router-dom";
import useCurrencyStore from "store/currencyStore";
import useAddToPicks from "api/BookResource/useAddToPicks";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import usePrice from "hooks/usePrice";
import useAllowedBookActions from "hooks/useAllowedBookActions";
import useRemoveFromLibrary from "api/BookResource/useRemoveFromLibrary";
import useAddToCart from "api/BookResource/useAddToCart";

import AddToCartSuccessful from "components/v2/AddToCartSuccessful";
import useAuthStore from "store/authStore";
import { Role } from "utils/server-constants/roles";
import ActionsCard from "./ActionsCard";

const Book = () => {
	const { bookId } = useParams();
	const { currency, setCurrency } = useCurrencyStore();
	const { data: book, isLoading: isLoadingBook } = useBook(bookId);
	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();

	const [cartQuantity, setCartQuantity] = React.useState(1);
	const [format, setFormat] = React.useState("digital");

	const { hasRoles } = useAuthStore();

	const handleChangeCartQuantity = (value) => {
		if (value <= 0) return setCartQuantity(1);
		return setCartQuantity(value);
	};

	const price = usePrice({
		isFree: Boolean(book?.isFree),
		usdPrice: book?.price,
		zwlPrice: book?.zwl_price,
		sellable: Boolean(book?.sellable),
	});

	const physicalPrice = usePrice({
		isFree: Boolean(book?.isFree),
		usdPrice: book?.physical_usd_price,
		zwlPrice: book?.physical_zwl_price,
		sellable: Boolean(book?.sellable),
	});

	const { addToCart, addToPicks, removeFromPicks } = useAllowedBookActions({
		isFree: Boolean(book?.isFree),
		in_library: Boolean(book?.in_library),
		is_purchased: Boolean(book?.is_purchased),
		sellable: Boolean(book?.sellable),
		subscribable: Boolean(book?.subscription),
		hasSubscription: Boolean(isSubscribed?.data),
	});

	const { mutateAsync, isLoading: isAddingToPicks } = useAddToPicks();
	const handleAddToPicks = () => {
		mutateAsync({ booksToSubscribe: book?.id });
	};

	const {
		mutateAsync: mutateRemoveFromLibrary,
		isLoading: isRemovingFromLibrary,
	} = useRemoveFromLibrary(book?.id);
	const removeFromLibrary = async () => {
		await mutateRemoveFromLibrary();
	};

	const { mutateAsync: mutateAddToCart, isLoading: isAddingToCart } =
		useAddToCart();

	const handleAddToCart = async () => {
		await mutateAddToCart({
			book_id: bookId,
			quantity: cartQuantity,
			format,
		});
	};

	return (
		<Container className="">
			{isLoadingBook || isLoadingSubscribed ? (
				<div className="flex justify-center mt-10">
					<ButtonLoader size={100} />
				</div>
			) : (
				<PaddingX>
					<div className="book_container md:flex justify-center md:gap-10 p-0">
						<div className="flex-1  mb-5 lg:flex  lg:space-x-10">
							<div className="image-container  bg-gray-200 flex items-center justify-center p-2 relative  ">
								{/* <span className="font-cera-black  absolute -left-3 md:-left-10 -top-6 h-20 w-20 rounded-full bg-akgreener text-white flex items-center justify-center">
									{Boolean(book?.isFree) ? "Free" : "Premium"}
								</span> */}

								{book?.book_cover?.url && (
									<img
										src={book?.book_cover?.url}
										alt={book?.title}
									/>
								)}
							</div>

							<div className="flex-1   my-7 md::my-0">
								<h2
									title={book?.title}
									className=" font-cera-black leading-tight "
								>
									{book?.title}
								</h2>
								<div className=" my-1">
									<StarRating number={book?.rating} />
								</div>
								{book?.author && (
									<p className="flex items-center gap-1 text-base">
										<span>Written by</span>
										<span className="font-bold"> {book?.author}</span>
									</p>
								)}

								{book?.publisher && (
									<p
										className="text-lg"
										style={{ maxWidth: 500 }}
									>
										Published by{" "}
										<span className="text-base font-bold text-akgreener">
											{book?.publisher}
										</span>
									</p>
								)}

								{book?.description && (
									<p
										style={{ maxWidth: 500 }}
										dangerouslySetInnerHTML={{ __html: book?.description }}
									/>
								)}
							</div>
						</div>

						<div className="">
							<ActionsCard
								price={price}
								physicalPrice={physicalPrice}
								currency={currency}
								book={book}
								addToPicks={addToPicks}
								handleAddToPicks={handleAddToPicks}
								removeFromPicks={removeFromPicks}
								isAddingToPicks={isAddingToPicks}
								isRemovingFromLibrary={isRemovingFromLibrary}
								removeFromLibrary={removeFromLibrary}
								addToCart={addToCart}
								isAddingToCart={isAddingToCart}
								handleAddToCart={handleAddToCart}
								cartQuantity={cartQuantity}
								handleChangeCartQuantity={handleChangeCartQuantity}
								hasRoles={hasRoles}
								Role={Role}
								format={format}
								setFormat={setFormat}
							/>
						</div>
					</div>
				</PaddingX>
			)}

			<AddToCartSuccessful />
		</Container>
	);
};

export default Book;

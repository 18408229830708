import useLogout from "api/UserResource/useLogout";
import { ButtonLoader } from "components";
import useSearchParams from "hooks/useSearchParams";
import React from "react";
import { BsBuilding } from "react-icons/bs";
import { FiCreditCard, FiLock, FiMail, FiUser } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";
import useAuthStore from "store/authStore";
import { getFlushErrorMessage } from "utils/errors";
import { Role } from "utils/server-constants/roles";
import avatar from "assets/images/avatar.jpg";
import {
	AiOutlineDollarCircle,
	AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { SiGooglemaps } from "react-icons/si";

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {boolean} props.isLoading
 * @param {Object} props.profile
 * @param {string} props.profile.first_name
 * @param {string} props.profile.last_name
 * @param {string} props.profile.email
 * @param {string} props.profile.avatar
 * @param {string} props.profile.avatar
 */
const AccountLayout = (props) => {
	const { children, isLoading, profile, showProfile = true } = props;

	const { hasRoles } = useAuthStore();
	const { getParam } = useSearchParams();
	const flushErrorCode = getParam("flushError") || "";
	const flushErrorMessage = getFlushErrorMessage(flushErrorCode);

	const iconSize = 20;

	const { mutateAsync, isLoading: isLoggingOut } = useLogout();

	const handleLogout = async () => {
		await mutateAsync();
	};

	const location = useLocation();

	const links = [
		{
			label: "Profile",
			path: "/my-account",
			icon: <FiUser size={iconSize} />,
			qs: "My profile",
		},
		{
			label: "Emails",
			path: "/my-account/emails",
			icon: <FiMail size={iconSize} />,
			qs: "Emails",
		},
		{
			label: "Password & authentication",
			path: "/my-account/password",
			icon: <FiLock size={iconSize} />,
			qs: "Password and authentication",
		},
		{
			label: "Subscriptions",
			path: "/my-account/subscriptions",
			icon: <FiCreditCard size={iconSize} />,
			qs: "My subscription",
		},
		{
			label: "Orders",
			path: "/my-account/orders",
			icon: <AiOutlineDollarCircle size={iconSize} />,
			qs: "My orders",
		},

		{
			label: "Addresses",
			path: "/my-account/my-addresses",
			icon: <SiGooglemaps size={iconSize} />,
			qs: "My addresses",
		},

		// FIXME Uncomment the comment
		// {
		// 	label: "Auto-deductions",
		// 	path: "/my-account/auto-deductions",
		// 	icon: <IoCardOutline size={iconSize} />,
		// 	qs: "Auto-deductions",
		// },
	];

	if (hasRoles([Role.SeniorPublisher])) {
		links.push({
			label: "Publisher details",
			path: "/my-account/publisher",
			icon: <BsBuilding size={iconSize} />,
			qs: "Publisher details",
		});
	}

	if (hasRoles([Role.Institution])) {
		links.push({
			label: "Institution details",
			path: "/my-account/institution",
			icon: <BsBuilding size={iconSize} />,
			qs: "Institution details",
		});
	}

	return (
		<div className="space-y-6">
			<div className="flex justify-between items-center">
				<div className="flex items-center gap-1 border-b flex-1 border-gray-300">
					{links.map((link, index) => (
						<NavLink
							exact
							key={index}
							to={link.path + (link.qs ? `?heading=${link.qs}` : "")}
							className="flex border-b-2 items-start sm:items-center gap-1 px-2 xs:px-4 lg:px-3 py-2 hover:bg-gray-200  transition-all duration-200 ease-in-out  border-transparent"
							activeClassName="bg-gray-200 border-b-2 border-akgreener "
						>
							<span className="text-akgreener">{link.icon}</span>
							<span className="text-akgreener hidden lg:inline">
								{link.label}
							</span>
						</NavLink>
					))}
				</div>

				<button
					type="button"
					onClick={handleLogout}
					className="px-3 py-2 hidden rounded-full max-w-max border transform duration-200 hover:scale-105 border-akgreener text-akgreener xs:flex items-center gap-2"
				>
					Logout
					{isLoggingOut && (
						<AiOutlineLoading3Quarters
							size={20}
							className="animate-spin"
						/>
					)}
				</button>
			</div>

			{(function () {
				if (isLoading) {
					return (
						<div className=" flex justify-center items-center">
							<ButtonLoader size={60} />
						</div>
					);
				}

				return (
					<>
						{showProfile && (
							<div className="flex items-center gap-2 border-b pb-4 max-w-max pr-4 sm:pr-10">
								<img
									src={profile?.avatar || avatar}
									alt="Profile"
									className="h-12 w-12 rounded-full "
								/>
								<div className="flex flex-col">
									<h3 className="font-bold">
										{profile?.first_name} {profile?.last_name}
									</h3>
									<span>{profile?.email}</span>
								</div>
							</div>
						)}

						<div>{children}</div>
					</>
				);
			})()}
		</div>
	);
};

export default AccountLayout;

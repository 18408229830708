import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { reactLocalStorage } from "reactjs-localstorage";

import useTokenStore from "store/tokenStore";

const useRefreshCache = () => {
	const { accessToken } = useTokenStore();

	const save = async () => {
		const instance = await InitApi({ accessToken });

		const res = instance.post("/clear-cache");

		return res.data;
	};

	return useMutation(save, {
		onSuccess: async () => {
			toast.success("Cache cleared successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
			}
		},
	});
};

export default useRefreshCache;

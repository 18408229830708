import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useUpdatePublisher = (id, returnUrl) => {
	const queryClient = useQueryClient();
	const { storeUser, userProfile } = useAuthStore();
	const { accessToken } = useTokenStore();

	const history = useHistory();

	const updateFirm = async (obj) => {
		const instance = await InitApi({ accessToken }, "multipart/form-data");

		const body = deleteEmptyObjects(obj);

		const formData = new FormData();
		Object.keys(body).forEach((key) => {
			formData.append(key, body[key]);
		});

		const response = await instance.post(
			`/publishers/${id}?_method=PATCH`,
			formData
		);
		await queryClient.invalidateQueries("profile");
		return response;
	};

	return useMutation(updateFirm, {
		onSuccess: async ({ data: body }) => {
			storeUser({ ...userProfile, publishers: [body?.data] });

			if (returnUrl) {
				await queryClient.refetchQueries("guards");
				history.push(returnUrl);
			}
			toast.success("Publishing firm updated successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useUpdatePublisher;

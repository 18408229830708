import ServerErrors from "components/SeverErrors";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { deleteEmptyObjects } from "utils";
import InitApi from "api/InitApi";
import useTokenStore from "store/tokenStore";

const useGetStars = (setStars) => {
	const { accessToken } = useTokenStore();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		// const instanze = await ExternalInit();
		const body = deleteEmptyObjects(obj);

		const responze = await instance.post("/fetch-stars", {
			student_number: body.student_number,
			security_answer: body.security_answer,
		});

		return responze.data;
	};

	return useMutation(save, {
		onSuccess: async (body) => {
			if (
				body?.status === "success" &&
				typeof Number(body?.data?.points) === "number"
			) {
				setStars(body?.data?.points);
			}
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useGetStars;

import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { Link } from "react-router-dom";
import useAuthStore from "store/authStore";
import { dataThatCannotBeRecovered } from "utils";

const AccountDeletionManual = () => {
	const { loggedIn } = useAuthStore();

	return (
		<Container>
			<PaddingX>
				<div
					className="mt-10 mx-auto"
					style={{ maxWidth: 500 }}
				>
					<h2>Delete your Akello Library account</h2>

					<div className="mt-8 space-y-8">
						<div>
							<h3>1. Implications of deleting your account</h3>
							<p>
								Deleting your account will permanently remove all your data from
								our servers and cannot be recovered. This includes:
							</p>
							<ul className="list-outside list-disc mt-2">
								{dataThatCannotBeRecovered.map((data, index) => (
									<li
										key={index}
										className="list-item ml-4"
									>
										{data}
									</li>
								))}
							</ul>
						</div>

						<div>
							<h3>2. Steps to delete account</h3>
							<ul className="list-outside list-disc mt-2">
								<li className="list-item ml-4">
									Go to your{" "}
									{loggedIn ? (
										<Link
											to="/my-account"
											className="text-blue-500 font-bold "
										>
											profile
										</Link>
									) : (
										<span className="font-bold">profile</span>
									)}
								</li>
								<li className="list-item ml-4">
									Scroll down to "Delete Account"
								</li>
								<li className="list-item ml-4">
									Click on the "Delete Account" button
								</li>
								<li className="list-item ml-4">
									Confirm that you want to delete your account
								</li>
								<li className="list-item ml-4">Your account will be deleted</li>
							</ul>
						</div>
					</div>
				</div>
			</PaddingX>
		</Container>
	);
};

export default AccountDeletionManual;

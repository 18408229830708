import { useUrlQuery } from "hooks/useUrlQuery";
import Admin2 from "layouts/Admin2";
import { Link, useLocation, useParams } from "react-router-dom";
import Profile from "./Profile";
import useFetchUser from "api/UserResource/useFetchUser";
import AdminPageLoader from "components/AdminPageLoader";
import { BiMessageDetail } from "react-icons/bi";
import { profileNavTabs } from "./utils";
import Security from "./Security";
import useAuthStore from "store/authStore";
import { Role } from "utils/server-constants/roles";
import useFetchPermissions from "api/RoleResource/useFetchPermissions";
import Permissions from "./Permissions";
import { UserModel } from "models/UserModel";
import { Fragment } from "react";
import useFetchSubjectGroups from "api/SubjectGroupResource/useFetchSubjectGroups";
import SubjectGroups from "./SubjectGroups";

const User = () => {
	const { userId } = useParams();

	const location = useLocation();

	const searchParams = useUrlQuery();

	const activeTabName = searchParams.get("tab") || profileNavTabs.Profile;

	const { hasRoles } = useAuthStore();

	const baseSectionUrl = `/portal/users/${userId}`;

	const { data: subjectGroupsData, isLoading: isLoadingSubjectGroups } =
		useFetchSubjectGroups({
			no_pagination: true,
		});

	const subjectGroups = subjectGroupsData?.data || [];

	const { data: allPermissions, isLoading: isLoadingPermissions } =
		useFetchPermissions();

	const { data: payload, isLoading } = useFetchUser(userId);

	const user = payload?.data?.data;

	/** @type {string} role */
	const role = user?.role_name?.[0] || "";

	/** @type {Array<string>} permissions */
	const permissions = user?.permission_names;

	const assignedSubjectGroups =
		user?.assignedSubjectGroups?.map((i) => i.id) || [];

	const userModel = new UserModel({
		id: user?.id,
		firstName: user?.first_name,
		lastName: user?.last_name,
		role,
		permissions,
		email: user?.email,
		mobileNumber: user?.mobile_number,
	});

	function canShowTab(tab) {
		if (tab === profileNavTabs.Security) {
			return hasRoles([Role.SuperAdmin, Role.Admin]);
		}
		if (tab === profileNavTabs.Iam) {
			return (
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				userModel.hasAnyRole([
					Role.SuperAdmin,
					Role.Admin,
					Role.SeniorPublisher,
					Role.Publisher,
					Role.Institution,
					Role.Educator,
				])
			);
		}
		if (tab === profileNavTabs.SubjectGroups) {
			return (
				hasRoles([Role.SuperAdmin, Role.Admin, Role.Institution]) &&
				userModel.hasAnyRole([Role.Student])
			);
		}
		return true;
	}

	function canShowTabContents(tab) {
		return canShowTab(tab) && activeTabName === tab;
	}

	const tabSections = [
		{
			label: "Profile",
			tabName: profileNavTabs.Profile,
			show: true,
		},
		{
			label: "Security",
			tabName: profileNavTabs.Security,
			show: canShowTab(profileNavTabs.Security),
		},
		{
			label: "Roles & Permissions",
			tabName: profileNavTabs.Iam,
			show: canShowTab(profileNavTabs.Iam),
		},
		{
			label: "Subject groups",
			tabName: profileNavTabs.SubjectGroups,
			show: canShowTab(profileNavTabs.SubjectGroups),
		},
	];

	if (isLoading || isLoadingPermissions || isLoadingSubjectGroups) {
		return <AdminPageLoader />;
	}

	if (!user) {
		return (
			<Admin2 fullWidth>
				<div className="flex flex-col items-center w-full justify-center flex-1 gap-3">
					<BiMessageDetail
						size={100}
						className=" text-gray-300 "
					/>

					<h3>User not found</h3>

					<Link
						to="/users/admin/all"
						className="text-blue-700"
					>
						Back to users
					</Link>
				</div>
			</Admin2>
		);
	}

	return (
		<Admin2
			fullWidth
			breadCrumbs={[
				{
					name: "Users",
					path: "/users/admin/all",
				},
				{
					name:
						"Account (" +
						tabSections?.find((section) => section.tabName === activeTabName)
							?.label +
						")",
					path: location.pathname,
				},
			]}
		>
			<div className="space-y-8 py-4 w-full flex-1 flex flex-col">
				<div className="space-y-1">
					<h3 className="font-bold flex items-center justify-between gap-2 max-w-max">
						<span>
							{user?.first_name} {user?.last_name}
						</span>

						<span className="text-white font-thin text-sm bg-akgreen px-2 py-1 rounded-full">
							{role}
						</span>
					</h3>
					<p>Manage profile and account settings</p>
				</div>

				<div className="border-t w-full" />

				<div className="flex gap-8 flex-1 ">
					<div className=" flex flex-col gap-2 w-56">
						{tabSections
							?.filter((section) => section.show)
							?.map((section, index) => (
								<Link
									key={index}
									to={`${baseSectionUrl}?tab=${section.tabName}`}
									className={`py-2 px-4 rounded-xl ${
										activeTabName === section?.tabName ? "bg-gray-100" : ""
									}`}
								>
									{section.label}
								</Link>
							))}
					</div>

					<div className="flex-1 p-8 border rounded-md border-gray-200 flex flex-col ">
						<div
							className="flex-1 flex max-w-max "
							style={{
								minWidth: 400,
							}}
						>
							{[
								{
									condition: canShowTabContents(profileNavTabs.Profile),
									component: (
										<Profile
											id={user?.id}
											firstName={user?.first_name}
											lastName={user?.last_name}
											email={user?.email}
											mobileNumber={user?.mobile_number}
											role={role}
										/>
									),
								},
								{
									condition: canShowTabContents(profileNavTabs.Security),
									component: <Security id={user?.id} />,
								},
								{
									condition: canShowTabContents(profileNavTabs.Iam),
									component: (
										<Permissions
											id={user?.id}
											permissions={permissions || []}
											allPermissions={allPermissions?.data || []}
										/>
									),
								},
								{
									condition: canShowTabContents(profileNavTabs.SubjectGroups),
									component: (
										<SubjectGroups
											id={user?.id}
											assignedSubjectGroups={assignedSubjectGroups}
											subjectGroups={subjectGroups}
										/>
									),
								},
							].map(
								({ condition, component }, i) =>
									condition && <Fragment key={i}>{component}</Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</Admin2>
	);
};

export default User;

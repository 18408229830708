import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useSendEnquiry = () => {
	//!

	const { accessToken } = useTokenStore();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);

		instance.defaults.baseURL = `${process.env.REACT_APP_QUIZ_BASE_URL}/library`;
		const response = await instance.post("/hub-queries", body);
		return response.data;
	};

	return useMutation(save, {
		onSuccess: async (body) => {
			toast.success("Message send successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});

	//!
};

export default useSendEnquiry;

// import useEcocash from "api/PaymentsResource/useEcocash";
import useEcocashPayBooks from "api/PaymentsResource/useEcocashPayBooks";
import { ButtonLoader, TextField } from "components";
import { Form, Formik } from "formik";
import React from "react";
import { activeCurrencies, activePaymentMethods, formatCurrency } from "utils";
import * as Yup from "yup";

const EcocashPaymentUsd = ({
	grandTotal,
	books,
	setPaymentIsSuccessful,
	addressId,
}) => {
	const [applyVoucher, setApplyVoucher] = React.useState(false);

	const [paymentMethod, setPaymentMethod] = React.useState(
		activePaymentMethods.ecocashUsd.name
	);

	// console.log({ books });

	const handleApplyVoucher = () => {
		setPaymentMethod("Voucher");
		setApplyVoucher(true);
	};

	const handleBackToEcocash = () => {
		setPaymentMethod(activePaymentMethods.ecocashUsd.name);
		setApplyVoucher(false);
	};

	const initialValues = {
		street_address: "",
		city: "",
		province: "",
		country: "",
		postcode: "",
		payment_method: paymentMethod,
		payment_type: "Purchase",
		subtotal: 0,
		grand_charges: 0,
		grand_total: grandTotal,
		grand_total_zwl: 0,
		order_note: "",
		books: books,
		mobile_number: "",
		initiated_from: "cart",
		currency: activeCurrencies.USD.code,
		total_amount: grandTotal,
		voucher_number: "",
		address_id: addressId,
	};

	const validateCheckout = Yup.object().shape({
		mobile_number:
			paymentMethod === activePaymentMethods.ecocashUsd.name
				? Yup.string()
						.typeError("Mobile number must be numeric")
						.min(9, "Mobile number must be 9 digits and above")
						.required("Mobile number is required")
				: Yup.string().notRequired(),
		voucher_number:
			paymentMethod === "Voucher"
				? Yup.string().required("Voucher number is required")
				: Yup.string().notRequired(),
	});

	const {
		mutateAsync: mutateEcocash,
		isLoading: isLoadingPayment,
		error,
	} = useEcocashPayBooks(setPaymentIsSuccessful);

	const handleSubmit = async (data) => {
		await mutateEcocash({
			...data,
			payment_method: paymentMethod,
		});
	};

	return (
		<div className="relative">
			{isLoadingPayment && (
				<div
					className="absolute -inset-x-0 -inset-y-3 z-10 bg-gray-100 h-auto p-4 rounded-lg flex items-center justify-center gap-2"
					style={{ opacity: 0.95 }}
				>
					<ButtonLoader size={100} />
					<div
						style={{ maxWidth: 250 }}
						className=" space-y-2 "
					>
						<p className="text-sm">
							Please wait for the Ecocash pin prompt on your phone. Enter your
							pin and wait for the transaction to complete.
						</p>
						<p className=" font-bold ">
							Don't close or refresh this page before completing the transaction
						</p>
					</div>
				</div>
			)}

			<p className="text-base mb-2">
				You are about to complete Ecocash payment of{" "}
				{formatCurrency(grandTotal)}
			</p>

			<Formik
				initialValues={initialValues}
				validationSchema={validateCheckout}
				onSubmit={(data) => handleSubmit(data)}
			>
				{(formik) => {
					return (
						<Form
							id="checkoutForm"
							className="mt-4"
						>
							{!applyVoucher && (
								<>
									<TextField
										type="number"
										name="mobile_number"
										label="Please enter your ecocash mobile number to proceed (e.g 0783575000)"
										placeholder="e.g 0783575000"
									/>

									<div className="mt-2 space-y-2">
										<button
											type="submit"
											className="rounded-full px-3 py-2 text-white bg-akgreenest"
										>
											Proceed with payment
										</button>

										<button
											type="button"
											onClick={handleApplyVoucher}
											className="text-xl text-blue-800 font-bold"
										>
											Would you like to pay using a voucher?
										</button>
									</div>
								</>
							)}

							{applyVoucher && (
								<div className="mt-4">
									<p>
										Please enter your {activeCurrencies.USD.label} voucher
										number
									</p>
									<div className="space-y-2 pt-2">
										<div>
											<TextField
												name="voucher_number"
												placeholder="e.g TKRpiLHXXy"
											/>
											{error && (
												<p className="text-red-500 text-xs">
													{error?.response?.data?.message}
												</p>
											)}
										</div>

										<div className="flex items-center gap-1 mt-1">
											<button
												type="submit"
												className="rounded-full px-3 py-2 text-white bg-akgreenest"
											>
												Pay with voucher
											</button>

											<button
												type="button"
												onClick={handleBackToEcocash}
												className="flex items-center gap-2 border border-akgreener py-2 px-3 rounded-full"
											>
												Back to ecocash
											</button>
										</div>
									</div>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);

	//!
};

export default EcocashPaymentUsd;

import useDeleteCartItem from "api/CartResource/useDeleteCartItem";
import useUpdateCartItem from "api/CartResource/useUpdateCartItem";
import { ButtonLoader } from "components";
import usePrice from "hooks/usePrice";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { formatCurrency } from "utils";
import { BookFormat } from "utils/server-constants/bookFormats";

const CartItem = ({
	title,
	coverUrl,
	author,
	cartId,
	quantity,
	format,
	book,
}) => {
	const { mutateAsync: mutateDeleteCart, isLoading: isDeleteCartLoading } =
		useDeleteCartItem(cartId);

	const handleDeleteCart = async () => {
		await mutateDeleteCart();
	};

	const [cartItemQuantity, setCartItemQuantity] = React.useState(quantity);
	const [pendingInputQuantity, setPendingInputQuantity] = React.useState(null);
	const { mutateAsync, isLoading } = useUpdateCartItem(cartId);

	const handleInputChange = (e) => {
		const newQuantity = Math.max(1, parseInt(e.target.value) || 1);
		setPendingInputQuantity(newQuantity);
	};

	const updateInputQuantity = async () => {
		if (pendingInputQuantity && pendingInputQuantity !== cartItemQuantity) {
			await mutateAsync({ quantity: pendingInputQuantity });
			setCartItemQuantity(pendingInputQuantity);
			setPendingInputQuantity(null);
		}
	};

	const price = usePrice({
		isFree: false,
		usdPrice: book?.price,
		zwlPrice: book?.zwl_price,
		sellable: true,
	});

	const physicalPrice = usePrice({
		isFree: false,
		usdPrice: book?.physical_usd_price,
		zwlPrice: book?.physical_zwl_price,
		sellable: true,
	});

	const priceToShow = format === BookFormat.Physical ? physicalPrice : price;

	return (
		<div className="flex flex-row sm:justify-between items-start sm:items-center gap-4 py-4 bg-white ">
			{/* Book Cover */}
			<div className="hidden xs:block w-20 sm:w-24 h-20 sm:h-32 rounded-full sm:rounded-lg overflow-hidden shadow-sm ">
				{coverUrl && (
					<img
						src={coverUrl}
						alt={`${title} Cover`}
						className="w-full h-full object-cover"
					/>
				)}
			</div>

			{/* Book Details */}
			<div className="flex-1  space-y-2 ">
				<div>
					<p
						className="text-base text-gray-700 "
						title={title}
						style={{ maxWidth: 400 }}
					>
						{title}
					</p>
					<p
						className="text-sm text-gray-600 "
						title={author}
					>
						Written by {author}
					</p>
				</div>

				<div className="flex gap-4 lg:gap-8 items-center">
					<div className="hidden sm:flex items-center gap-4 ">
						<span className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-akgreener opacity-70 text-white">
							{format}
						</span>
						<span className="text-base  text-gray-600">
							{formatCurrency(priceToShow)}
						</span>
					</div>

					{/* Quantity Controls */}
					<div className="flex items-center gap-3">
						<div className="relative">
							<input
								type="number"
								value={pendingInputQuantity ?? cartItemQuantity}
								onChange={handleInputChange}
								min="1"
								className="w-20 h-10 text-center border border-gray-200 rounded-lg focus:ring-2 
							focus:ring-akgreener/20 focus:border-akgreener text-gray-900 text-base font-medium
							shadow-sm transition-all duration-200"
							/>
						</div>

						{pendingInputQuantity !== null &&
						pendingInputQuantity !== cartItemQuantity ? (
							<button
								onClick={updateInputQuantity}
								disabled={isLoading}
								className="h-10 w-24 px-4 bg-akgreener text-white text-sm font-medium rounded-lg
						hover:bg-akgreener/90 disabled:opacity-50 disabled:cursor-not-allowed transition-all
						flex items-center gap-2 whitespace-nowrap shadow-sm hover:shadow"
								type="button"
							>
								{isLoading ? (
									<>
										<AiOutlineLoading3Quarters className="w-4 h-4 animate-spin" />
										<span className="hidden sm:inline">Updating...</span>
									</>
								) : (
									"Update"
								)}
							</button>
						) : (
							<div
								className="h-10 w-24 px-4 bg-gray-100 text-gray-600 text-sm font-medium rounded-lg
						flex items-center justify-center"
							>
								Copies
							</div>
						)}
					</div>

					{/* Total Price */}
					<div className="text-right ">
						<p className="text-base sm:text-lg font-bold text-gray-500">
							{formatCurrency(priceToShow * cartItemQuantity)}
						</p>
						{pendingInputQuantity !== null &&
							pendingInputQuantity !== cartItemQuantity && (
								<p className="text-sm text-akgreener font-medium mt-1">
									New: {formatCurrency(priceToShow * pendingInputQuantity)}
								</p>
							)}
					</div>
				</div>

				<div className="xs:hidden flex items-center  ">
					<button
						type="button"
						onClick={handleDeleteCart}
						className=" text-red-600 flex items-center gap-2 py-2"
						title="Remove item"
					>
						Remove from cart
						{isDeleteCartLoading && (
							<AiOutlineLoading3Quarters className="w-4 h-4 animate-spin" />
						)}
					</button>
				</div>
			</div>

			{/* Remove Button */}
			<div className="hidden xs:flex items-center  ">
				{isDeleteCartLoading ? (
					<ButtonLoader size={20} />
				) : (
					<button
						type="button"
						onClick={handleDeleteCart}
						className="p-2 text-red-600 hover:text-red-500 hover:bg-red-50 rounded-full transition-all duration-200"
						title="Remove item"
					>
						<FiTrash size={40} />
					</button>
				)}
			</div>
		</div>
	);
};

export default CartItem;

import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import AutoComplete from "./AutoComplete";
import { useState } from "react";
import { CheckBox } from "components";
import useSaveShippingAddress from "api/AddressResource/useSaveShippingAddress";
import { phoneRegex } from "utils";

/**
 * @param {Object} props
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setShowAddNewDeliveryAddress
 */
const ShippingDetails = (props) => {
	const { setShowAddNewDeliveryAddress } = props;
	const [address, setAddress] = useState(null);

	console.log({ address });

	const initValues = {
		recipient_name: "",
		recipient_phone: "",
		nickname: "",
		suburb: "",
		city: "",
		is_default: false,
	};

	const shippingSchema = Yup.object().shape({
		recipient_name: Yup.string().required("Recipient name is required"),
		recipient_phone: Yup.string()
			.required("Mobile number is required")
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		nickname: Yup.string().required("Address nickname is required"),
		is_default: Yup.boolean().required("Default address is required"),
	});

	const { mutateAsync, isLoading: isSavingAddress } = useSaveShippingAddress();

	const _onSubmit = async (data) => {
		const body = {
			...address,
			country: address?.country_name,
			country_code: address?.country_code,
			google_address_id: address?.id,
			latitude: address?.latitude,
			longitude: address?.longitude,
			distance_in_meters: address?.distanceMeters,
			street_address: address?.street_address,
			...data,
		};

		try {
			await mutateAsync(body);
			setShowAddNewDeliveryAddress(false);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className="w-full ">
			<h3 className="mb-8 font-cera-black">Save delivery address</h3>

			<Formik
				initialValues={initValues}
				onSubmit={(data) => _onSubmit(data)}
				validationSchema={shippingSchema}
			>
				{(formik) => (
					<Form
						className="w-full"
						id="shipping-address-form"
					>
						<div className=" w-full">
							<FormField
								name="recipient_name"
								type="text"
								label="Recipient Name*"
							/>
						</div>

						<div className="w-full mt-6">
							<FormField
								name="recipient_phone"
								type="phone"
								label="Recipient Mobile Number*"
								value={formik.values.recipient_phone}
								_onChange={(phone) => {
									if (phone !== "+")
										formik.values.recipient_phone = "+" + phone;
									else formik.values.recipient_phone = phone;
								}}
								error={Boolean(
									formik.errors.recipient_phone &&
										formik.touched.recipient_phone
								)}
								errorText={formik.errors.recipient_phone}
							/>
						</div>

						<div className=" w-full mt-8">
							<FormField
								name="nickname"
								type="text"
								label="Address identifier (e.g. Home, Work, etc)*"
								helperText="Give this address a name to help you identify it later (e.g. Home, Work, etc)"
							/>
						</div>

						<div className=" w-full mt-8">
							<AutoComplete
								address={address}
								setAddress={(data) => {
									setAddress(data);
									formik.setFieldValue("suburb", data?.suburb || "");
									formik.setFieldValue("city", data?.city || "");

									// for (let key in data) {
									//   formik.setFieldValue(key, data[key]);
									// }
								}}
							/>
						</div>

						<div className=" w-full mt-7">
							<FormField
								name="suburb"
								type="text"
								label="Suburb*"
							/>
						</div>

						<div className=" w-full mt-7">
							<FormField
								name="city"
								type="text"
								label="City*"
							/>
						</div>

						<div className="mt-6">
							<CheckBox
								name="is_default"
								label={
									<span className="text-sm">
										Make this my default delivery address for future orders
									</span>
								}
							/>
						</div>

						<div className="mt-7 flex justify-between gap-4">
							<button
								type="button"
								className="px-4 py-2 flex items-center justify-center gap-2 border border-akgreener text-akgreener rounded-full text-base"
								onClick={() => setShowAddNewDeliveryAddress(false)}
							>
								Cancel
							</button>

							<button
								id="save-address-button"
								type="submit"
								disabled={!address}
								className={`px-4 py-2 flex items-center justify-center gap-2 text-white rounded-full text-base bg-gradient-to-r from-akgreen to-akgreenest ${
									!address ? "opacity-50 cursor-not-allowed" : ""
								}`}
							>
								Save Address
								{isSavingAddress ? (
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								) : undefined}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ShippingDetails;

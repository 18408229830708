import useFetchUsers from "api/UserResource/useFetchUsers";
import { DataTable } from "primereact/datatable";
import { useFormik } from "formik";
import { Column } from "primereact/column";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsPencil, BsSearch } from "react-icons/bs";
import useAuthStore from "store/authStore";
import useAssignBooksToUsers from "api/BookResource/useAssignBooksToUsers";
import { Dialog } from "primereact/dialog";
import { FiArrowRight, FiX } from "react-icons/fi";

const AssignUsers = ({ books, className, children }) => {
	const { userProfile } = useAuthStore();

	const [display, setDisplay] = useState(false);

	const [searchTerm, setSearchTerm] = useState("");
	const [shouldFetch, setShouldFetch] = useState(true);

	function handleChangeSearchTerm(e) {
		if (shouldFetch === true) {
			setShouldFetch(false);
		}
		setSearchTerm(e.target.value);
	}

	const { data: usersData, isLoading: isLoadingUsers } = useFetchUsers(
		{
			page: 1,
			rows_per_page: 5,
			search: searchTerm,
			"filter[role_is]": "Student",
			"filter[institution_is]": userProfile?.institutions[0]?.id,
		},
		shouldFetch
	);

	const [selectedUsers, setSelectedUsers] = useState([]);

	const users = usersData?.data?.records;

	const { mutateAsync, isLoading: isAssigningBooks } = useAssignBooksToUsers();

	const { values, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			users: [...selectedUsers?.map((user) => user.id)],
			books: [...books],
		},
		onSubmit: async (data) => {
			// let books = [];
			const books = data.books
				.filter((book) => Number(book.number_assignable) >= data.users.length)
				.map((book) => book.id);

			const payload = {
				users: data.users,
				books,
			};
			await mutateAsync(payload);

			setDisplay(false);
		},

		enableReinitialize: true,
	});

	const notAssignable = (book) => {
		const assignable = Number(book?.number_assignable);
		return values?.users?.length > assignable || assignable <= 0;
	};

	return (
		<>
			<button
				type="button"
				className={`appearance-none border-0 ${className} `}
				onClick={() => setDisplay(true)}
			>
				{children || <BsPencil size={15} />}
			</button>

			<Dialog
				visible={display}
				onHide={() => setDisplay(false)}
				breakpoints={{
					"960px": "90vw",
					"0px": "100vw",
				}}
				className="font-sans"
				showHeader={false}
				contentClassName="rounded-md relative"
				blockScroll={false}
			>
				<button
					type="button"
					onClick={() => setDisplay(false)}
					className="absolute top-4 right-4"
				>
					<FiX size={30} />
				</button>

				<div
					className="pt-8 pb-1 "
					style={{ minHeight: 630 }}
				>
					<div className="px-4">
						<h4 className="font-cera-black text-akgreenest text-xl ">
							Give students access to the {books?.length} selected book(s)
						</h4>
						<p>
							A book marked is "Not assignable" cannot be assigned because the
							number of copies remaining
							<br /> for assigning is less than the number of users you want to
							assign
						</p>
					</div>

					<div className="grid grid-cols-3 gap-x-6 mt-4 pt-4 border-t px-4 divide-x border-gray-300">
						<div className="col-span-1 space-y-2 py-4">
							<h4 className="font-bold">Books selected for assigning</h4>

							<div className="flex flex-col gap-2">
								{books?.map((book) => (
									<p
										key={book.id}
										className={`pl-3 pr-1 text-sm rounded-full border border-gray-300 flex items-center justify-between gap-2 py-1`}
									>
										<span
											style={{ width: 350 }}
											className={`truncate text-base ${
												notAssignable(book) ? "line-through" : ""
											} `}
										>
											{book?.title} by{" "}
											<span className="font-bold">({book?.author})</span>
										</span>
										{notAssignable(book) ? (
											<span className="bg-red-400 text-white px-2 py-2 rounded-full">
												Not assignable
											</span>
										) : (
											<span className="bg-white text-white px-2 py-2 rounded-full ">
												Assignable
											</span>
										)}
									</p>
								))}
							</div>
						</div>

						<div className={`col-span-2 py-4 flex`}>
							<div className="space-y-4">
								<form
									onSubmit={handleSubmit}
									className="flex flex-col items-center space-y-4 px-4"
									style={{ maxWidth: "100%" }}
								>
									<div className="flex justify-between items-center w-full gap-4 pr-2">
										<div
											className="relative"
											style={{ width: 500 }}
										>
											<input
												type="text"
												name="searchTerm"
												placeholder="Search user to assign"
												className="w-full h-11 border border-gray-300 rounded-lg pr-4 pl-10"
												onChange={handleChangeSearchTerm}
											/>
											<span className="absolute left-3 top-3">
												<BsSearch
													size={20}
													className="text-gray-400"
												/>
											</span>

											<button
												type="button"
												className="absolute right-0 top-0 bg-akgreener h-11 rounded-r-lg text-white px-4"
												onClick={() => setShouldFetch(!shouldFetch)}
											>
												Search
											</button>
										</div>

										<button
											type="submit"
											className="flex items-center gap-2 px-4 py-3 rounded-full bg-akgreener text-white"
										>
											Assign books{" "}
											{isAssigningBooks ? (
												<AiOutlineLoading3Quarters
													size={20}
													className="animate-spin"
												/>
											) : (
												<FiArrowRight size={20} />
											)}
										</button>
									</div>

									{/* <UsersFilter /> */}

									<DataTable
										value={users}
										selection={selectedUsers}
										onSelectionChange={(e) => {
											setSelectedUsers(e.value);
											setFieldValue("users", [
												...e.value?.map((user) => user?.id),
											]);
										}}
										loading={isLoadingUsers}
										tableClassName="font-sans text-sm "
										style={{ width: 700 }}
										autoLayout
									>
										<Column
											selectionMode="multiple"
											headerStyle={{ width: "3em" }}
										></Column>
										<Column
											field="first_name"
											header="First Name"
											sortable
										/>
										<Column
											field="last_name"
											header="Last Name"
											sortable
										/>
										<Column
											field="email"
											header="Email"
											sortable
										/>

										{/* <Column
											header="Phone Number"
											headerClassName="text-center"
											bodyClassName="text-center"
											body={(row) =>
												row.mobile_number ? (
													<span>{row.mobile_number} </span>
												) : (
													<AiOutlineLine size={20} />
												)
											}
											sortable
										/> */}
										{/* <Column
											header="Role"
											headerClassName="text-center"
											body={roleTemplate}
										/> */}
									</DataTable>

									<div className="flex justify-end w-full pr-2">
										<button
											type="submit"
											className="flex items-center gap-2 px-4 py-3 rounded-full bg-akgreener text-white"
										>
											Assign books{" "}
											{isAssigningBooks ? (
												<AiOutlineLoading3Quarters
													size={20}
													className="animate-spin"
												/>
											) : (
												<FiArrowRight size={20} />
											)}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default AssignUsers;

import { createContext, useContext, useState } from "react";
import useOrdersFilters from "./useOrdersFilters";

const OrderFilterContext = createContext(null);

export const OrderFilterProvider = ({ children }) => {
	const filters = useOrdersFilters();

	const formValues = filters?.allSearchParams?.reduce((acc, key) => {
		acc[key] = "";
		return acc;
	}, {});

	const [filterValues, setFilterValues] = useState(formValues);

	const contextValue = {
		filters,
		filterValues,
		setFilterValues,
	};

	return (
		<OrderFilterContext.Provider value={contextValue}>
			{children}
		</OrderFilterContext.Provider>
	);
};

export const useOrderFilterContext = () => {
	const context = useContext(OrderFilterContext);
	if (!context) {
		throw new Error(
			"useOrderFilterContext must be used within a OrderFilterProvider"
		);
	}
	return context;
};

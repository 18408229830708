import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useUserCartItems = () => {
	const { accessToken } = useTokenStore();
	const loggedIn = useAuthStore((state) => state.loggedIn);

	/**
	 * Get the user's cart items
	 * @typedef {Object} CartResource
	 * @property {Array} cart_items
	 * @property {number} total_price
	 * @property {number} total_quantity
	 * @property {number} total_weight
	 * @property {number} total_volume
	 * @property {number} total_distance
	 * @returns {Promise<CartResource>}
	 */
	const fetch = async () => {
		if (!loggedIn) {
			return null;
		}
		const instance = await InitApi({ accessToken });
		const res = await instance.get("carts");

		return res.data?.data;
	};
	return useQuery("user_cart_items", () => fetch(), {
		...fetchConfig,
	});
};

export default useUserCartItems;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useUserCartItems from "api/CartResource/useUserCartItems";
import useFetchCurrencyByCode from "api/RatesResource/useFetchCurrencyByCode";
import useGetUserShippingAddresses from "api/AddressResource/useGetUserShippingAddresses";
import { BookInCart, Empty, ModalNotification } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { AiOutlineCheckCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { BiCheck } from "react-icons/bi";
import { FaTruck, FaWallet } from "react-icons/fa";
import usePrice from "hooks/usePrice";
import useCurrencyStore from "store/currencyStore";
import { activeCurrencies, activePaymentMethods } from "utils";
import { BookFormat } from "utils/server-constants/bookFormats";
import EcocashPayment from "./EcocashPayment";
import EcocashPaymentUsd from "./EcocashPaymentUsd";
import PayPalPayment from "./PayPalPayment";
import RedeemStars from "./RedeemStars";
import ShippingDetails from "./ShippingDetails";
import ShippingAddressToUse from "./ShippingAddressToUse";
import CheckoutPageLoader from "./CheckoutPageLoader";

const Checkout = () => {
	const { currency, paymentMethod, setPaymentMethod } = useCurrencyStore();
	const { data: currencyRate, isLoading: isLoadingCurrency } =
		useFetchCurrencyByCode(currency);

	const [showShippingDetails, setShowShippingDetails] = useState(false);
	const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
	const { data: shippingAddresses, isLoading: isLoadingShippingAddresses } =
		useGetUserShippingAddresses();

	const hasSavedShippingAddresses = shippingAddresses?.length > 0;

	const { data: cart, isLoading: previewIsLoading } = useUserCartItems();

	const [showPaymentCompletedModal, setShowPaymentCompletedModal] =
		useState(false);
	const [paymentIsSuccessful, setPaymentIsSuccessful] = useState(false);
	const [totalPaid, setTotalPaid] = useState(0);
	const [bookCount, setBookCount] = useState(0);
	const [bookItems, setBookItems] = useState(null);
	const [showAddNewDeliveryAddress, setShowAddNewDeliveryAddress] =
		useState(false);

	const booksToBuy = cart?.cart_items?.map((cartItem) => ({
		book_id: cartItem.book_id,
		quantity: cartItem.quantity,
		format: cartItem.format,
	}));

	useEffect(() => {
		if (cart?.cart_items) {
			const hasPhysicalBooks = Boolean(
				cart.cart_items.find((item) => item.format === BookFormat.Physical)
			);
			setShowShippingDetails(hasPhysicalBooks);
		}
	}, [cart]);

	const addressId = showShippingDetails
		? selectedShippingAddress?.id || shippingAddresses?.[0]?.id
		: null;

	const subtotal = usePrice({
		sellable: true,
		usdPrice: cart?.grand_total,
		zwlPrice: cart?.grand_total_zwl,
		isFree: false,
	});

	const deliveryFeePerMeter = usePrice({
		sellable: true,
		usdPrice: cart?.delivery_free_per_meter?.[activeCurrencies.USD.code],
		zwlPrice: cart?.delivery_free_per_meter?.[activeCurrencies.ZWL.code],
		isFree: false,
	});

	const deliveryFee = React.useMemo(() => {
		if (
			!cart?.cart_items?.some((item) => item.format === BookFormat.Physical)
		) {
			return 0;
		}

		if (!selectedShippingAddress) return 0;
		const distance = selectedShippingAddress?.distance_in_meters || 0;
		return distance * deliveryFeePerMeter;
	}, [cart, selectedShippingAddress, deliveryFeePerMeter]);

	const grandTotal = deliveryFee + subtotal;

	const credits = React.useMemo(() => {
		return cart?.cart_items?.reduce(
			(prevValue, currentValue) =>
				prevValue +
				Number(currentValue.credits) * Number(currentValue.quantity),
			0
		);
	}, [cart]);

	const paymentOptionsButtons = [
		{
			paymentMethod: activePaymentMethods.ecocashUsd.name,
			label: activePaymentMethods.ecocashUsd.label,
		},
		{
			paymentMethod: activePaymentMethods.ecocashZwl.name,
			label: activePaymentMethods.ecocashZwl.label,
		},
		{
			paymentMethod: activePaymentMethods.paypal.name,
			label: activePaymentMethods.paypal.label,
		},
		{
			paymentMethod: activePaymentMethods.stars.name,
			label: activePaymentMethods.stars.label,
		},
	];

	if (paymentIsSuccessful) {
		return (
			<Container>
				<div className="mx-auto p-8 mt-12 text-center max-w-lg bg-white rounded-2xl shadow-lg">
					<div className="flex flex-col justify-center items-center gap-6 text-center">
						<span className="rounded-full h-32 w-32 bg-green-50 border-4 border-akgreen flex items-center justify-center animate-appear">
							<BiCheck
								size={80}
								className="text-akgreen"
							/>
						</span>

						<h2 className="text-2xl font-bold text-gray-800">
							Payment Successful!
						</h2>

						<p className="text-lg text-gray-600">
							{paymentMethod === activePaymentMethods.stars.name ? (
								<>
									Your stars were redeemed successfully and you can now access
									your books for reading through the reader app
								</>
							) : (
								<>
									Your {paymentMethod} payment was processed and you can now
									access your books for reading through the reader app.
								</>
							)}
						</p>

						<Link
							to="/my-library"
							className="text-base bg-akgreen text-white px-8 py-4 rounded-xl hover:bg-akgreener transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
						>
							Visit your library
						</Link>
					</div>

					<p className="mt-8 text-gray-600 bg-gray-50 p-4 rounded-lg">
						{paymentMethod === activePaymentMethods.stars.name ? (
							<>
								If your stars were deducted but the book(s) are not accessible,
								please{" "}
								<Link
									to="/support"
									className="font-bold text-akgreen hover:text-akgreener"
								>
									contact support
								</Link>
							</>
						) : (
							<>
								If payment was deducted but the book(s) are not accessible,
								please{" "}
								<Link
									to="/support"
									className="font-bold text-akgreen hover:text-akgreener"
								>
									contact support
								</Link>
							</>
						)}
					</p>
				</div>
			</Container>
		);
	}

	const isLoading =
		previewIsLoading || isLoadingCurrency || isLoadingShippingAddresses;

	const hasNoItems = !cart?.cart_items?.length;

	if (isLoading) {
		return <CheckoutPageLoader />;
	}

	if (hasNoItems) {
		return (
			<Container>
				<div className="mt-12 flex-1">
					<PaddingX>
						<Empty cartIconSize={200}>
							<div className="flex flex-col items-center gap-6">
								<p className="text-xl text-gray-600">Your cart is empty</p>
								<Link
									to="/books"
									className="bg-akgreen px-8 py-4 text-base text-white rounded-xl shadow-md hover:bg-akgreener transition-all duration-300"
								>
									Continue shopping
								</Link>
							</div>
						</Empty>
					</PaddingX>
				</div>
			</Container>
		);
	}

	return (
		<Container>
			<div className="mt-12 flex-1">
				<PaddingX>
					<div
						className={`${
							showShippingDetails ? "lg:grid-cols-5" : "lg:grid-cols-4"
						} grid grid-cols-1 md:grid-cols-4 gap-8 lg:gap-12 mt-4 pb-4`}
					>
						{showShippingDetails && (
							<div className="col-span-2 lg:col-span-3">
								<div className="bg-white rounded-2xl shadow-md p-6 space-y-6">
									<div className="flex items-center gap-3 border-b pb-4">
										<FaTruck className="text-akgreen text-xl" />
										<h2 className="text-xl font-bold text-gray-800">
											Delivery Details
										</h2>
									</div>

									{hasSavedShippingAddresses && !showAddNewDeliveryAddress && (
										<ShippingAddressToUse
											selectedShippingAddress={selectedShippingAddress}
											setSelectedShippingAddress={setSelectedShippingAddress}
											shippingAddresses={shippingAddresses}
										/>
									)}

									{!showAddNewDeliveryAddress && hasSavedShippingAddresses && (
										<button
											className="flex items-center gap-2 text-akgreen hover:text-akgreener transition-colors duration-300"
											onClick={() => setShowAddNewDeliveryAddress(true)}
										>
											<AiOutlinePlusCircle size={25} />
											<span className="font-medium">
												{hasSavedShippingAddresses ? "Add new" : "Add"} delivery
												address
											</span>
										</button>
									)}

									{(showAddNewDeliveryAddress ||
										!hasSavedShippingAddresses) && (
										<ShippingDetails
											setShowAddNewDeliveryAddress={
												setShowAddNewDeliveryAddress
											}
										/>
									)}
								</div>
							</div>
						)}

						<div className="col-span-2 lg:col-span-2">
							<div className="bg-white rounded-2xl shadow-md p-6">
								<div className="flex items-center gap-3 border-b pb-4 mb-6">
									<FaWallet className="text-akgreen text-xl" />
									<h2 className="text-xl font-bold text-gray-800">
										Order Summary
									</h2>
								</div>

								<div className="space-y-4 mb-6">
									<div className="flex justify-between text-gray-600">
										<span>Subtotal</span>
										<span className="font-medium">${subtotal.toFixed(2)}</span>
									</div>
									<div className="flex justify-between text-gray-600">
										<span>Delivery Fee</span>
										<span className="font-medium">
											${deliveryFee.toFixed(2)}
										</span>
									</div>
									<div className="border-t border-gray-200 pt-4">
										<div className="flex justify-between text-lg font-bold text-gray-800">
											<span>Total</span>
											<span>${grandTotal.toFixed(2)}</span>
										</div>
									</div>
								</div>

								<div className="space-y-6">
									<h3 className="font-bold text-lg text-gray-800">
										Payment Method
									</h3>
									<div className="grid grid-cols-2 gap-3">
										{paymentOptionsButtons.map((button) => (
											<button
												key={button.paymentMethod}
												type="button"
												className={`py-3 px-4 rounded-xl font-medium transition-all duration-300 ${
													paymentMethod === button.paymentMethod
														? "bg-akgreen text-white shadow-md"
														: "bg-gray-50 text-gray-700 hover:bg-gray-100"
												}`}
												onClick={() => setPaymentMethod(button.paymentMethod)}
											>
												{button.label}
											</button>
										))}
									</div>

									<div className="mt-6 bg-gray-50 p-4 rounded-xl">
										{paymentMethod === activePaymentMethods.stars.name &&
											currency === activeCurrencies.ZWL.code && (
												<RedeemStars
													credits={credits}
													grandTotal={grandTotal}
													books={booksToBuy}
													setBookCount={setBookCount}
													setTotalPaid={setTotalPaid}
													setBookItems={setBookItems}
													setPaymentIsSuccessful={setPaymentIsSuccessful}
													addressId={addressId}
												/>
											)}

										{paymentMethod === activePaymentMethods.ecocashZwl.name &&
											currency === activeCurrencies.ZWL.code && (
												<EcocashPayment
													grandTotal={grandTotal}
													books={booksToBuy}
													setPaymentIsSuccessful={setPaymentIsSuccessful}
													addressId={addressId}
												/>
											)}

										{paymentMethod === activePaymentMethods.ecocashUsd.name && (
											<EcocashPaymentUsd
												grandTotal={grandTotal}
												books={booksToBuy}
												setPaymentIsSuccessful={setPaymentIsSuccessful}
												addressId={addressId}
											/>
										)}

										{paymentMethod === activePaymentMethods.paypal.name &&
											currency === activeCurrencies.USD.code && (
												<PayPalPayment
													grandTotal={grandTotal}
													setPaymentIsSuccessful={setPaymentIsSuccessful}
													books={booksToBuy}
													addressId={addressId}
												/>
											)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<ModalNotification
						visible={showPaymentCompletedModal}
						setVisible={setShowPaymentCompletedModal}
						title="Payment successful!"
					>
						<div className="flex items-center gap-4 bg-green-50 p-6 rounded-xl">
							<div className="text-akgreen">
								<AiOutlineCheckCircle size={130} />
							</div>
							<div className="space-y-4">
								<p className="text-lg">
									Your <strong>{paymentMethod}</strong> payment was successful.
									Details are as follows:
								</p>
								<ul className="list-none space-y-2">
									<li className="flex items-center gap-2 text-akgreen">
										<span className="font-bold">Total payment:</span>
										<span>${Number(totalPaid).toFixed(2)}</span>
									</li>
									<li className="flex items-center gap-2 text-akgreen">
										<span className="font-bold">Number of books:</span>
										<span>{bookCount}</span>
									</li>
								</ul>
								<p>
									Go to{" "}
									<Link
										to="/my-library"
										className="text-akgreen font-bold hover:text-akgreener"
										onClick={() => setShowPaymentCompletedModal(false)}
									>
										My library
									</Link>{" "}
									to view purchased books
								</p>
							</div>
						</div>

						<div className="my-6 border-t border-gray-200" />

						<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
							{bookItems?.map((bookItem) => (
								<BookInCart
									key={bookItem.id}
									title={bookItem?.book_title}
									coverUrl={bookItem?.book_cover?.url}
									author={bookItem?.author}
									quantity={bookItem?.quantity}
									publisher={bookItem?.user}
									price={(
										bookItem?.price * Number(currencyRate?.data?.rate || 1)
									).toFixed(2)}
									currencySymbol={bookItem?.currency_symbol}
									cartId={bookItem?.id}
									paymentResponse
								/>
							))}
						</div>

						<div className="mt-6 bg-gray-50 p-4 rounded-xl text-center">
							<p>
								Go to{" "}
								<Link
									to="/my-library"
									className="text-akgreen font-bold hover:text-akgreener"
									onClick={() => setShowPaymentCompletedModal(false)}
								>
									My library
								</Link>{" "}
								to view purchased books
							</p>
						</div>
					</ModalNotification>
				</PaddingX>
			</div>
		</Container>
	);
};

export default Checkout;

import { Role } from "utils/server-constants/roles";

/**
 * @typedef {Object} UserModelProps
 * @property {int |string} id
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} role
 * @property {string[]} permissions
 * @property {string} email
 * @property {string} mobileNumber
 * @property {boolean | undefined} [isLoggedIn]
 * @property {boolean | undefined} [hasSubscription]
 */
export class UserModel {
	/** @type {UserModelProps} */
	props = {};

	/**
	 * @param {UserModelProps} props
	 */
	constructor(props) {
		this.props = props;
	}

	getRoles() {
		return Object.values(Role);
	}

	/**
	 * Get a user property e.g id, email etc
	 * @param {string} propName
	 * @returns
	 */
	get(propName) {
		return this.props[propName];
	}

	/**
	 * @param {string|string[]} roles
	 * @returns {boolean}
	 */
	hasAnyRole(roles) {
		const recognisedRoles = Object.values(Role);
		if (typeof roles === "string") {
			roles = [roles];
		}

		if (!roles.every((role) => recognisedRoles.includes(role))) {
			return false;
		}
		return roles.some((role) => role === this.props.role);
	}
}

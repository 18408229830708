import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import useAuthStore from "store/authStore";
import { Role } from "utils/server-constants/roles";
import { useBookFilterContext } from "./BookFilterContext";
import { formData } from "./booksQueryParams";
import useInitialQueryData from "admin/Filters/useInitialQueryData";

const BookFilter = ({
	genres,
	categories,
	publishers,
	numberOfActiveFilters = 0,
}) => {
	const hasRoles = useAuthStore((state) => state.hasRoles);
	const [visible, setVisible] = useState(false);
	const { filters } = useBookFilterContext();

	const { filterValues, setFilterValues } = useInitialQueryData(
		filters,
		formData
	);

	const changeInputValue = (e) => {
		const value = e.target?.value;
		const updatedFilters = { ...filterValues, [e.target.name]: value };
		setFilterValues(updatedFilters);
		// filters?.changeAllFiltersAtOnce(updatedFilters);
		// setVisible(false);
	};

	const handleApplyFilters = (e) => {
		e.preventDefault();
		filters?.changeAllFiltersAtOnce(filterValues);
		setVisible(false);
	};

	const handleClearFilters = () => {
		const params = filters?.allSearchParams
			?.filter((param) => !filters?.paginationParams?.includes(param))
			?.map((param) => ({
				[param]: "",
			}))
			.reduce((acc, curr) => ({ ...acc, ...curr }), {});

		setFilterValues(params);
		filters?.changeAllFiltersAtOnce({
			...params,
		});
		setVisible(false);
	};

	return (
		<>
			<button
				type="button"
				onClick={() => setVisible(!visible)}
				className="flex items-center gap-1 h-11 border border-gray-300 rounded-lg min-w-max px-4"
			>
				Advanced filter
				<BiMenuAltRight size={20} />
			</button>

			<Sidebar
				visible={visible}
				onHide={() => setVisible(false)}
				position="right"
				className="max-w-max px-4 py-4"
			>
				<form
					onSubmit={handleApplyFilters}
					className="mb-4 flex flex-col justify-center gap-3 max-w-max"
				>
					<p className="font-bold">Filter books by the following</p>

					{hasRoles([
						Role.SuperAdmin,
						Role.Admin,
						Role.Institution,
						Role.Educator,
					]) && (
						<select
							name="publisherIs"
							id="publisherIs"
							value={filterValues?.publisherIs}
							onChange={changeInputValue}
							className={`${
								filterValues?.publisherIs
									? "border-2 border-akgreener text-akgreener"
									: "border border-gray-300"
							}  bg-white rounded h-9 w-56 px-2`}
						>
							<option
								label={
									filterValues?.publisherIs
										? "Clear filter [x]"
										: "- Publisher -"
								}
							></option>
							{publishers?.data?.map((publisher) => (
								<option
									key={publisher.id}
									value={publisher.id}
								>
									{publisher.name}
								</option>
							))}
						</select>
					)}

					<select
						name="genreIs"
						id="genreIs"
						value={filterValues?.genreIs}
						onChange={changeInputValue}
						className={`${
							filterValues?.genreIs
								? "border-2 border-akgreener text-akgreener"
								: "border border-gray-300"
						}  bg-white rounded h-9 w-56 px-2`}
					>
						<option
							label={
								filterValues?.genreIs ? "Clear filter [x]" : "- Collection -"
							}
						></option>
						{genres?.data?.data?.map((genre) => (
							<option
								key={genre.id}
								value={genre.name}
							>
								{genre.name}
							</option>
						))}
						<option value="">[x] Clear filter</option>
					</select>

					<select
						name="categoryIs"
						id="categoryIs"
						value={filterValues?.categoryIs}
						onChange={changeInputValue}
						className={`${
							filterValues?.categoryIs
								? "border-2 border-akgreener text-akgreener"
								: "border border-gray-300"
						}  bg-white rounded h-9 w-56 px-2`}
					>
						<option
							label={
								filterValues?.categoryIs ? "Clear filter [x]" : "- Category -"
							}
						></option>
						{categories?.data?.map((category) => (
							<option
								key={category.id}
								value={category.name}
							>
								{category.name}
							</option>
						))}
					</select>

					{hasRoles([
						Role.SuperAdmin,
						Role.Admin,
						Role.SeniorPublisher,
						Role.Publisher,
					]) && (
						<>
							<select
								name="pricingModelIs"
								id="pricingModelIs"
								className={`${
									filterValues?.pricingModelIs
										? "border-2 border-akgreener text-akgreener"
										: "border border-gray-300"
								}  bg-white rounded h-9 w-56 px-2`}
								onChange={changeInputValue}
								value={filterValues.pricingModelIs}
							>
								<option
									label={
										filterValues?.pricingModelIs
											? "Clear filter [x]"
											: "- Pricing model -"
									}
								></option>
								<option value="sellable">Purchase only</option>
								<option value="subscription">Subscription only</option>
								<option value="all">Purchase & subscription</option>
								<option value="free">Free</option>
							</select>

							<select
								name="isActive"
								id="isActive"
								className={`${
									filterValues?.isActive
										? "border-2 border-akgreener text-akgreener"
										: "border border-gray-300"
								}  bg-white rounded h-9 w-56 px-2`}
								onChange={changeInputValue}
								value={filterValues.isActive}
							>
								<option
									label={
										filterValues?.isActive
											? "Clear filter [x]"
											: "- Activation status -"
									}
								></option>
								<option value="1">Active</option>
								<option value="0">Inactive</option>
							</select>

							<select
								name="withoutFileCollection"
								id="withoutFileCollection"
								className={`${
									filterValues?.withoutFileCollection
										? "border-2 border-akgreener text-akgreener"
										: "border border-gray-300"
								}  bg-white rounded h-9 w-56 px-2`}
								onChange={changeInputValue}
								value={filterValues.withoutFileCollection}
							>
								<option
									label={
										filterValues?.withoutFileCollection
											? "Clear filter [x]"
											: "- Missing files -"
									}
								></option>
								<option value="book_cover">Missing book cover</option>
								<option value="book_file">Missing book file</option>
								<option value="all">Missing both files</option>
							</select>

							<select
								name="withFileCollection"
								id="withFileCollection"
								className={`${
									filterValues?.withFileCollection
										? "border-2 border-akgreener text-akgreener"
										: "border border-gray-300"
								}  bg-white rounded h-9 w-56 px-2`}
								onChange={changeInputValue}
								value={filterValues.withFileCollection}
							>
								<option
									label={
										filterValues?.withFileCollection
											? "Clear filter [x]"
											: "- Available files -"
									}
								></option>
								<option value="book_cover">With book cover</option>
								<option value="book_file">With book file</option>
								<option value="all">With both files</option>
							</select>
						</>
					)}

					<button
						type="submit"
						className="bg-akgreen flex items-center justify-center gap-2 text-white h-11 rounded-lg px-4"
					>
						Apply filters
					</button>

					{numberOfActiveFilters > 0 && (
						<button
							onClick={handleClearFilters}
							className="border border-akgreen flex items-center justify-center gap-2 h-11 rounded-lg px-4"
						>
							Clear filters
							<span className="flex items-center rounded-full justify-center w-8 h-8 border border-akgreen">
								{numberOfActiveFilters}
							</span>
						</button>
					)}
				</form>
			</Sidebar>
		</>
	);
};

export default BookFilter;

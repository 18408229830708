import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";
import { Role } from "utils/server-constants/roles";

const useFetchSubjectGroups = (queryParams, shouldFetch = true) => {
	const { hasRoles, loggedIn } = useAuthStore();
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });

		const params = hasRoles([Role.Institution])
			? queryParams
			: { ...queryParams, all: true };

		const parameters = params ? deleteEmptyObjects(params) : null;

		// let url = hasRoles(["Institution"]) ? "/subject-groups" : "/subject-groups?all=true";

		const res = await instance.get("/subject-groups", {
			params: { ...parameters },
		});
		return res.data;
	};
	return useQuery(["subject-groups", queryParams], () => fetch(), {
		...fetchConfig,
		enabled: shouldFetch && loggedIn,
	});
};

export default useFetchSubjectGroups;

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import useRedirectOnUnauthenticated from "hooks/useRedirectOnUnauthenticated";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";

const useMoveUserToTrash = (id, destruction_level = null) => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();

	const toTrash = async () => {
		const instance = await InitApi({ accessToken });
		const response = await instance.delete(
			`/users/${id}?destruction_level=${destruction_level}`
		);
		await queryClient.invalidateQueries(["users"]);
		return response;
	};

	const redirectIfNotAuthenticated = useRedirectOnUnauthenticated();

	return useMutation(toTrash, {
		onSuccess: async ({ data: body }) => {
			toast.success(body?.message);
		},
		onError: async (error) => {
			const statusCode = error?.response?.status;
			redirectIfNotAuthenticated(statusCode);

			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useMoveUserToTrash;

import useTokenStore from "store/tokenStore";
import { useMutation, useQueryClient } from "react-query";
import InitApi from "api/InitApi";
import { deleteEmptyObjects } from "utils";
import toast from "react-hot-toast";
import ServerErrors from "components/SeverErrors";

const useApplyRatesToPrices = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);

		const response = await instance.post("/toggle-apply-rates-to-prices", body);
		await queryClient.invalidateQueries("rates");
		return response.data;
	};

	return useMutation(save, {
		onSuccess: async (body) => {
			toast.success(body.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useApplyRatesToPrices;

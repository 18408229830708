import WavyLoader from "components/v2/WavyLoader";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";

const CheckoutPageLoader = () => {
	return (
		<Container>
			<PaddingX>
				<div className="mt-12 grid grid-cols-1 lg:grid-cols-12 gap-8">
					{/* Left Column - Delivery Details Card */}
					<div className="lg:col-span-8">
						<div className="bg-white rounded-xl ">
							{/* Card Content */}
							<div className="space-y-2">
								<WavyLoader
									height={300}
									width="100%"
									className="p-8 flex gap-2"
								/>
								<WavyLoader
									height={40}
									width={200}
									className="rounded-full"
								/>
							</div>
						</div>
					</div>

					{/* Right Column - Order Summary Card */}
					<div className="lg:col-span-4">
						<div className="bg-white rounded-xl space-y-2">
							{/* Card Title */}
							<WavyLoader
								height={150}
								width={"100%"}
								className="p-8 flex gap-2"
							/>

							<WavyLoader
								height={300}
								width="100%"
							/>
						</div>
					</div>
				</div>
			</PaddingX>
		</Container>
	);
};

export default CheckoutPageLoader;

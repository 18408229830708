import BookCard from "admin/AllBooks2/BookCard";
import BookFilter from "admin/AllBooks2/BookFilter";
import useInstitutionBooks from "api/BookResource/useInstitutionBooks";
import { CustomPaginator } from "components";
import { useFormik } from "formik";
import Admin2 from "layouts/Admin2";
import { useState } from "react";
import { BsCheckAll, BsX } from "react-icons/bs";
import { Link } from "react-router-dom";
import useAuthStore from "store/authStore";
import AssignSubjectGroups from "./AssignSubjectGroups";
import AssignUsers from "./AssignUsers";
import { Role } from "utils/server-constants/roles";
import { BookFilterProvider } from "admin/AllBooks2/BookFilter/BookFilterContext";
// import useBooksFilters from "pages2/Books/useBooksFilters";
import useGenres from "api/GenreResource/useGenres";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useFetchPublishers from "api/PublisherResource/useFetchPublishers";
import AdminPageLoader from "components/AdminPageLoader";
// import useBooksFilters from "admin/AllBooks2/useBooksFilters";
import ClearFilters from "admin/Filters/ClearFilters";
import Search from "admin/Filters/Search";
import { formData } from "admin/AllBooks2/BookFilter/booksQueryParams";
import RowsPerPage from "admin/Filters/RowsPerPage";
import useBooksFilters from "admin/AllBooks2/BookFilter/useBooksFilters";

const InstitutionBooks = () => {
	const filters = useBooksFilters();

	const numberOfActiveFilters = filters?.allSearchParams
		?.filter((param) => !filters?.paginationParams?.includes(param))
		?.filter((param) => filters[param] !== "")?.length;

	const hasRoles = useAuthStore((state) => state.hasRoles);

	const { data: genres, isLoading: isLoadingGenres } = useGenres("yes");
	const { data: categories, isLoading: isLoadingCategories } =
		useFetchCategories("yes");

	const { data: publishers, isLoading: isLoadingPublishers } =
		useFetchPublishers();

	const { data: books, isLoading: isLoadingBooks } = useInstitutionBooks({
		page: filters.page,
		rows_per_page: filters.rowsPerPage,
		"filter[category_is]": filters.categoryIs,
		"filter[genre_is]": filters.genreIs,
		"filter[publisher_is]": filters.publisherIs,
		searchTerm: filters.search,
	});

	const totalRecords = books?.data?.total_records;

	const [selectedBooks, setSelectedBooks] = useState([]);

	const { values, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			books: [...selectedBooks],
			action: "",
		},

		onSubmit: async (data) => {},
		enableReinitialize: true,
	});

	if (
		isLoadingGenres ||
		isLoadingCategories ||
		isLoadingPublishers ||
		isLoadingBooks
	) {
		return <AdminPageLoader />;
	}

	return (
		<Admin2 fullWidth>
			<BookFilterProvider>
				<div className="my-4 space-y-4 w-full">
					<h3 className="font-bold flex items-center justify-between gap-3 rounded-full max-w-max">
						<span>Books' list</span>
						<span className="block bg-gray-400 rounded-full text-white py-1 w-14 text-center">
							{totalRecords || 0}
						</span>
					</h3>

					<div className="mt-4 flex items-center gap-x-2 justify-between ">
						<RowsPerPage
							filters={filters}
							formData={formData}
						/>
						<Search
							filters={filters}
							formData={formData}
						/>
						<ClearFilters
							numberOfActiveFilters={numberOfActiveFilters}
							filters={filters}
						/>
						<BookFilter
							genres={genres}
							categories={categories}
							publishers={publishers}
							numberOfActiveFilters={numberOfActiveFilters}
						/>
					</div>

					{/* <div className="flex flex-col items-center gap-4">
						<p className="text-lg font-bold">Purchased books</p>
						<BookFilter />
					</div> */}

					<form
						onSubmit={handleSubmit}
						className="flex flex-col "
					>
						<div className="flex items-center gap-4 mb-4">
							<label
								htmlFor="selectActions"
								className="flex items-center gap-1"
							>
								<input
									type="checkbox"
									name="select"
									className="h-8 w-8"
									hidden
									checked={
										selectedBooks?.length === books?.data?.records?.length
									}
									onChange={() =>
										setSelectedBooks((prev) =>
											prev?.length <= 0 ? [...books?.data?.records] : []
										)
									}
									id="selectActions"
								/>
								<span
									className={`flex items-center gap-1 h-11  px-2 py-1 ${
										selectedBooks.length <= 0 ? "border" : "border-2"
									} border-gray-300 rounded cursor-pointer`}
								>
									{selectedBooks.length <= 0 ? (
										<>
											Select All <BsCheckAll size={20} />
										</>
									) : (
										<>
											Deselect All <BsX size={20} />
										</>
									)}
								</span>
							</label>

							{values.books?.length > 0 && (
								<div className="flex items-center gap-2">
									<AssignUsers
										books={values.books}
										className="py-1 px-3 gap-2 h-11 border border-gray-300 rounded-md"
									>
										Assign users
									</AssignUsers>

									<AssignSubjectGroups
										books={values.books}
										className="py-1 px-3 gap-2 h-11 border border-gray-300 rounded-md"
									>
										Assign subject groups
									</AssignSubjectGroups>
								</div>
							)}
						</div>

						<div className="grid grid-cols-2 xl:grid-cols-3 gap-6 mt-2">
							{books?.data?.records?.map((book) => (
								<div key={book.id}>
									<label
										htmlFor={`bookitem${book.id}`}
										key={book.id}
										className="relative"
									>
										{(function () {
											const isChecked = values?.books
												?.map((book) => book.id)
												?.includes(book.id);

											const toggleChecked = () => {
												if (isChecked) {
													setFieldValue(
														"books",
														values.books.filter((_book) => _book.id !== book.id)
													);
												} else {
													setFieldValue("books", [...values.books, book]);
												}
											};
											return (
												<>
													<span
														className={`h-7 px-4 text-xs cursor-pointer border-akgreener border rounded-full ${
															isChecked ? "bg-akgreener text-white" : "bg-white"
														} absolute -top-2 -left-2 flex items-center justify-center`}
													>
														{isChecked ? "Deselect" : "Select"}
													</span>

													<input
														type="checkbox"
														name="books"
														hidden
														value={book}
														checked={isChecked}
														onChange={toggleChecked}
														id={`bookitem${book.id}`}
														className={`w-6 h-6 absolute -top-2 -left-1 ${
															!isChecked ? "border-2 border-akgreen" : ""
														}`}
													/>
												</>
											);
										})()}
									</label>

									{/* {values?.books
											?.map((book) => book.id)
											?.includes(book.id) ? (
											<input
												type="checkbox"
												checked
												name="books"
												value={book}
												className="w-6 h-6"
												onChange={() =>
													setFieldValue(
														"books",
														values.books.filter((_book) => _book.id !== book.id)
													)
												}
											/>
										) : (
											<input
												type="checkbox"
												name="books"
												value={book}
												onChange={() =>
													setFieldValue("books", [...values.books, book])
												}
												id={`bookitem${book.id}`}
												className="w-6 h-6 border-2 border-akgreen"
											/>
										)} */}
									<BookCard
										bookId={book?.id}
										title={book?.title}
										author={book?.author}
										price={book?.price}
										zwlPrice={book?.zwl_price}
										isbn={book?.isbn}
										description={book?.description}
										sellable={book?.sellable}
										onSubscription={book?.subscription}
										isActive={book?.is_active}
										isFree={book?.isFree}
										credits={book?.credits}
										bookCover={book?.book_cover?.url}
										publisher={book?.publisher}
										category={book?.category}
										genres={book?.genres[0]}
										hasFile={book?.has_book_file}
										numberOfCopiesPurchased={book?.number_of_copies_purchased}
										copiesAssignable={book?.number_assignable}
										isAdmin={hasRoles([Role.SuperAdmin, Role.Admin])}
									/>
								</div>
							))}
						</div>
					</form>

					<div className="flex justify-center rounded-full mt-4">
						<CustomPaginator
							firstPage={filters?.firstPage}
							rowsPerPage={filters?.rowsPerPage}
							setFirstPage={(val) => filters?.changeFilter("firstPage", val)}
							setRowsPerPage={(val) =>
								filters?.changeFilter("rowsPerPage", val)
							}
							totalRecords={books?.data?.total_records}
							setPage={(val) => filters?.changeFilter("page", val)}
							showRowsPerPageDropdown={false}
						/>
					</div>

					<h4 className="my-4 flex justify-center items-center gap-1">
						<span className="text-3xl">{books?.data?.total_records}</span> book
						titles purchased
					</h4>

					<div className="flex justify-center">
						<Link
							to="/books"
							className="text-blue-700"
						>
							Want to buy more?
						</Link>
					</div>
				</div>
			</BookFilterProvider>
		</Admin2>
	);
};

export default InstitutionBooks;

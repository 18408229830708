import useFetchPublishers from "api/PublisherResource/useFetchPublishers";
import { ButtonLoader } from "components";
import Admin2 from "layouts/Admin2";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import MoreAboutPublisher from "./MoreAboutPublisher";
import DeletePublisher from "./DeletePublisher";
import PublisherStatus from "./PublisherStatus";

const Publishers = () => {
	const { data, isLoading } = useFetchPublishers({
		withUsers: true,
	});

	const publishers = data?.data;

	const totalRecords = data?.data?.length || [];

	const canUseRatePricingTemplate = (row) => {
		return row.use_rate_pricing ? (
			<span className="flex justify-center items-center  bg-akgreenest text-white w-10 rounded-full">
				Yes
			</span>
		) : (
			<span className="flex justify-center items-center border border-akgreener text-akgreener w-10 rounded-full ">
				No
			</span>
		);
	};

	const createdAtTemplate = (row) =>
		moment(row.created_at).format("MMM DD, YYYY");

	const actionBodyTemplate = (row) => (
		<div className="flex justify-center items-center gap-3">
			<MoreAboutPublisher
				publisher={row}
				facilitators={row?.facilitators}
			/>
			<DeletePublisher
				publisherId={row.id}
				publisherName={row?.name}
			/>
		</div>
	);

	const statusTemplate = (row) => {
		return (
			<PublisherStatus
				status={row?.status}
				publisherId={row?.id}
			/>
		);
	};

	if (isLoading) {
		return (
			<Admin2>
				<div className="mt-10">
					<ButtonLoader size={60} />
				</div>
			</Admin2>
		);
	}

	return (
		<Admin2>
			<div className="mt-10 space-y-4">
				<h3 className="font-bold">
					Publishers list ({!isLoading ? totalRecords : "0"})
				</h3>
				<DataTable
					value={publishers}
					dataKey="id"
					tableClassName="font-sans text-sm"
					autoLayout
				>
					<Column
						field="name"
						header="Name"
						sortable
					/>
					<Column
						header="Date registered"
						body={createdAtTemplate}
					/>
					<Column
						header="Uses rate pricing"
						body={canUseRatePricingTemplate}
					/>
					<Column
						header="Approval status"
						bodyClassName="text-center"
						body={statusTemplate}
					/>
					<Column
						header="Actions"
						body={actionBodyTemplate}
					/>
				</DataTable>
			</div>
		</Admin2>
	);
};

export default Publishers;

import { Link } from "react-router-dom";

/**
 * @param {Object} props
 * @param {Array<{ name: string, path: string }>} props.tree
 */

const BreadCrumbs = (props) => {
	const { tree } = props;

	return (
		<nav aria-label="breadcrumb">
			<ol className="flex items-center gap-1 text-gray-500">
				{tree.map((crumb, index) => (
					<li
						key={index}
						className={`breadcrumb-item text-xl gap-1 flex items-center`}
					>
						{index === tree.length - 1 ? (
							<span className="text-gray-500 text-base">{crumb.name}</span>
						) : (
							<Link
								to={crumb.path}
								className="text-blue-900 hover:text-blue-700"
							>
								{crumb.name}
							</Link>
						)}
						{index < tree.length - 1 && <span>/</span>}
					</li>
				))}
			</ol>
		</nav>
	);
};

export default BreadCrumbs;

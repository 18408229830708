import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

/**
 * @param {Object} props
 * @param {Object} props.queryParams
 * @param {string|number} props.userId
 */
const useGetUserOrders = (props) => {
	const queryParams = props?.queryParams;
	const userId = props?.userId;
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const url = userId ? `/orders/user/${userId}` : "/orders/user";
		const res = await instance.get(url, {
			params: { ...params },
		});
		return res.data?.data;
	};

	return useQuery(["user-orders", queryParams, userId], () => fetch(), {
		...fetchConfig,
	});
};

export default useGetUserOrders;

import useBulkActionBooks from "api/BookResource/useBulkActionBooks";
import { Sidebar } from "primereact/sidebar";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import { CgToggleOff, CgToggleOn } from "react-icons/cg";
import { FaGripfire } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import useAuthStore from "store/authStore";
import { Role } from "utils/server-constants/roles";

const BookBulkActions = Object.freeze({
	Activate: "activate",
	Deactivate: "deactivate",
	SetAsFree: "setAsFree",
	SetAsPremium: "setAsPremium",
	Delete: "deletePermanently",
});

/**
 * @typedef {Object} BulkActionsProps
 * @property {string[]|int[]} books
 * @property {()=>void} resetBooks
 * @param {BulkActionsProps} props
 */

const BulkActions = (props) => {
	const { books, resetBooks } = props;

	const countBooks = books?.length || 0;

	const [visible, setVisible] = useState(false);

	const hasRoles = useAuthStore((state) => state.hasRoles);

	useEffect(() => {
		setVisible(countBooks > 0);
	}, [countBooks]);

	const { mutateAsync, isLoading: isActioning } = useBulkActionBooks();

	const handleSubmit = async (action) => {
		await mutateAsync({ books, action });
		resetBooks();
		setVisible(false);
	};

	const handleHide = () => {
		resetBooks();
		setVisible(false);
	};

	return (
		<Sidebar
			visible={visible}
			position="bottom"
			onHide={() => setVisible(false)}
			icons={() => (
				<>
					<div className="text-white flex justify-between items-center flex-1 ">
						<div className="flex items-center  gap-4">
							<p>{countBooks} selected books</p>
							{isActioning && (
								<AiOutlineLoading3Quarters
									size={20}
									className="animate-spin text-white"
								/>
							)}
						</div>

						{/* activate, deactivate, set as free, set as premium */}
						<div className="flex justify-end items-center gap-2">
							<Tooltip
								target=".make-free"
								position="top"
								className="book-bulk-actions-tooltip -mt-4"
							>
								Make books free
							</Tooltip>
							<button
								onClick={() => handleSubmit(BookBulkActions.SetAsFree)}
								className="make-free rounded-full h-9 w-9 flex items-center justify-center bg-akgreen "
							>
								<FaGripfire size={25} />
							</button>

							<Tooltip
								target=".make-premium"
								position="top"
								className="book-bulk-actions-tooltip -mt-4"
							>
								Make books premium
							</Tooltip>
							<button
								onClick={() => handleSubmit(BookBulkActions.SetAsPremium)}
								className="make-premium rounded-full h-9 w-9 flex items-center justify-center bg-akgreen "
							>
								<BsCurrencyDollar size={25} />
							</button>

							{hasRoles([Role.SuperAdmin, Role.Admin]) && (
								<>
									<Tooltip
										target=".activate-book"
										position="top"
										className="book-bulk-actions-tooltip -mt-4"
									>
										Activate books
									</Tooltip>
									<button
										onClick={() => handleSubmit(BookBulkActions.Activate)}
										className="activate-book rounded-full h-9 w-9 flex items-center justify-center bg-akgreen "
									>
										<CgToggleOff size={25} />
									</button>

									<Tooltip
										target=".deactivate-book"
										position="top"
										className="book-bulk-actions-tooltip -mt-4"
									>
										Deactivate books
									</Tooltip>
									<button
										onClick={() => handleSubmit(BookBulkActions.Deactivate)}
										className="deactivate-book rounded-full h-9 w-9 flex items-center justify-center bg-akgreen "
									>
										<CgToggleOn size={25} />
									</button>
								</>
							)}

							<Tooltip
								target=".delete-book"
								position="top"
								className="book-bulk-actions-tooltip -mt-4"
							>
								Permanently delete books
							</Tooltip>
							<button
								onClick={() => handleSubmit(BookBulkActions.Delete)}
								className="delete-book rounded-full h-9 w-9 flex items-center justify-center bg-akgreen "
							>
								<IoTrashOutline size={25} />
							</button>

							<button
								onClick={handleHide}
								type="button"
								className="border-l  ml-2 border-gray-400 p px-2"
							>
								<FiX size={25} />
							</button>
						</div>
					</div>
				</>
			)}
			className="bottom-4 rounded-full bg-akgreenest book-bulk-actions"
			style={{
				width: "500px",
				left: "50%",
				height: "65px",
				transform: "translateX(-50%)",
			}}
		/>
	);
};

export default BulkActions;

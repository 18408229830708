import useUpdateUserPassword from "api/UserResource/useUpdateUserPassword";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import * as Yup from "yup";

/**
 * @typedef  {Object} SecurityProps
 * @property {string|number} id
 *
 * @param {SecurityProps} props
 */
const Security = (props) => {
	const { id } = props;

	const initialValues = {
		password: "",
	};

	const validate = Yup.object().shape({
		password: Yup.string()
			.nullable()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit"),
	});

	const { mutateAsync, isLoading } = useUpdateUserPassword(id);

	const handleUpdatePassword = async (data) => {
		const payload = {
			...data,
			password_confirmation: data.password,
		};
		await mutateAsync(payload);
	};

	return (
		<div
			className="space-y-6"
			style={{ maxWidth: 600 }}
		>
			<div>
				<h4 className="">Update password</h4>
				<p className="text-gray-500">
					Ensure your account is using a long, random password to stay secure.
				</p>
			</div>

			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={handleUpdatePassword}
			>
				<Form>
					<div className="space-y-6">
						<div className="p-4 rounded-xl bg-green-100">
							<p>Your password should conform to the following rules</p>

							<ul className="list-disc list-inside">
								<li>At least 6 characters long</li>
								<li>Contains at least one alphabet character</li>
								<li>Contains at least one digit</li>
							</ul>
						</div>

						<FormField
							type="password"
							name="password"
							label="New user's password *"
						/>

						<button
							type="submit"
							className="px-4 py-2 rounded-xl bg-akgreener text-white flex gap-1 items-center justify-center"
						>
							{isLoading ? (
								<>
									Updating{" "}
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								</>
							) : (
								<>
									Update password <IoIosArrowForward size={20} />
								</>
							)}
						</button>
					</div>
				</Form>
			</Formik>
		</div>
	);
};

export default Security;

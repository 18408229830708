import useSearchParams from "hooks/useSearchParams";
import useProfile from "api/UserResource/useProfile";
import ChangePassword from "../ChangePassword";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import AccountLayout from "../AccountLayout";

const Passwords = () => {
	const { getParam } = useSearchParams();
	const heading = getParam("heading") || "Password and authentication";

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<Container>
			<div className="mt-10 flex-1">
				<PaddingX>
					<AccountLayout
						isLoading={isLoadingProfile}
						profile={profile?.data}
					>
						<div
							className="space-y-4"
							style={{ maxWidth: 500 }}
						>
							<h4>{heading}</h4>

							<div>
								<ChangePassword />
							</div>
						</div>
					</AccountLayout>
				</PaddingX>
			</div>
		</Container>
	);

	// return (
	// 	<ProfileLayout
	// 		heading={heading}
	// 		isLoading={isLoadingProfile}
	// 		profile={profile?.data}
	// 	>
	// 		<div className="pt-4">
	// 			<ChangePassword />
	// 		</div>
	// 	</ProfileLayout>
	// );
};

export default Passwords;

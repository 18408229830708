import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { getActiveCurrencyCodeLabel } from "utils";
import { PaymentType } from "utils/server-constants/paymentTypes";

/**
 * @param {Object} props
 * @param {string} props.order_number
 * @param {string} props.paymentType
 * @param {string} props.status
 * @param {string} props.paymentMethod
 * @param {string} props.reference
 * @param {string} props.created_at
 * @param {{id:number, name:string, duration:number, time_unit:string, cost:number, max_number_of_books:number}} props.subscription
 * @param {Array<{id:number, author:string, title:string, currency:string, total_cost:number, quantity:number, expiry:string, format:string}>} props.books
 */
const MoreOrderDetails = (props) => {
	const {
		order_number,
		paymentType,
		subscription,
		books,
		reference,
		status,
		paymentMethod,
		created_at,
		shippingAddress,
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<button
				onClick={() => setIsOpen(true)}
				type="button"
				className="hover:text-gray-900 text-gray-700 h-9 flex items-center justify-start md:justify-between gap-2 "
				style={{ minWidth: "150px" }}
			>
				<div className="text-sm text-blue-400 sm:text-gray-700 hover:scale-105 transform duration-200 ">
					<span>{order_number}</span>
				</div>

				<div className="flex-grow hidden sm:flex justify-start md:justify-end items-center">
					<FiMoreVertical
						size={20}
						className="cursor-pointer text-akgreener"
					/>
					<span className="h-7 max-w-max sm:flex items-center bg-white text-akgreen rounded-full px-4 py-2 border border-gray-300 text-xs opacity-0 show-content transform duration-100 hidden ">
						open
					</span>
				</div>
			</button>

			<Sidebar
				visible={isOpen}
				onHide={() => setIsOpen(false)}
				blockScroll
				position="right"
				// style={{ minWidth: "700px", maxWidth: "800px" }}
				className="w-full sm:w-500"
			>
				<div className="space-y-4 px-4">
					<div className="space-y-2">
						<h3 className="text-lg font-bold">Order details</h3>
						<div className="text-gray-700">
							<div className="flex items-center justify-between ">
								<span>Order Number</span>
								<span>{order_number}</span>
							</div>
							<div className="flex items-center justify-between sm:hidden">
								<span>Reference</span>
								<span>{reference}</span>
							</div>
							<div className="flex items-center justify-between lg:hidden">
								<span>Model</span>
								<span>{paymentType}</span>
							</div>
							<div className="flex items-center justify-between lg:hidden">
								<span>Payment Method</span>
								<span>{paymentMethod}</span>
							</div>

							<div className="flex items-center justify-between md:hidden">
								<span>Status</span>
								<span>{status}</span>
							</div>
							<div className="flex items-center justify-between md:hidden">
								<span>Transaction Date</span>
								<span>{created_at}</span>
							</div>
						</div>
					</div>

					<div className="border-b border-gray-400" />

					{shippingAddress && (
						<div className="rounded-lg border border-green-200 bg-green-50 p-4">
							<h4>Delivery Address</h4>
							<p>
								{shippingAddress?.street_address} {shippingAddress?.suburb}
							</p>
							<p>{shippingAddress?.city}</p>
							<p>{shippingAddress?.country}</p>
							<p>
								Recipient: {shippingAddress?.recipient_name} (
								{shippingAddress?.recipient_phone})
							</p>
						</div>
					)}

					{subscription && paymentType === PaymentType.Subscription && (
						<div className="space-y-2">
							<h4>Subscription paid for</h4>

							<div className="flex flex-col gap-2 text-sm">
								<p>Subscription name: {subscription.name}</p>
								<p>
									Subscription duration: {subscription.duration}{" "}
									{subscription.time_unit}
								</p>
								<p>Allows access to {subscription.max_number_of_books} books</p>
							</div>
						</div>
					)}

					{books?.length > 0 && paymentType === PaymentType.Purchase && (
						<div className="space-y-2">
							<h4>Books purchased </h4>

							<div className="flex flex-col gap-2 text-sm">
								{books?.map((book, index) => (
									<div
										key={book.id}
										className="flex gap-2 "
									>
										<span className="flex items-center justify-center h-10 w-10 rounded-full border border-gray-500">
											{index + 1}
										</span>
										<div className="py-1 flex-1">
											<p className="font-bold">{book.title}</p>
											<p>Written by {book.author}</p>
											<p>Number of copes bought {book.quantity}</p>
											<div className="flex gap-2">
												<p>
													Cost per copy:{" "}
													{getActiveCurrencyCodeLabel(book.currency)} $
													{book.total_cost}
												</p>
											</div>
											<p>
												Total cost: {getActiveCurrencyCodeLabel(book.currency)}{" "}
												${(book.total_cost * book.quantity).toFixed(2)}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</Sidebar>
		</>
	);
};

export default MoreOrderDetails;

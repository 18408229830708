export const flushErrors = Object.freeze({
	PUBLISHER_PROFILE_INCOMPLETE: {
		code: "error_1",
		message:
			"Please complete the profile of your publishing house. You may need to contact your Senior Publisher to complete the profile if you don't have the permission",
	},
	USER_PROFILE_INCOMPLETE: {
		code: "error_2",
		message: "Please complete your profile",
	},
	INSTITUTION_PROFILE_INCOMPLETE: {
		code: "error_3",
		message:
			"Please complete profile of your institution. You may need to contact your institution admin to complete the profile if you don't have the permission",
	},
});

export function getFlushErrorMessage(errorCode) {
	if (!errorCode) {
		return "";
	}
	return Object.values(flushErrors).find((error) => error.code === errorCode)
		.message;
}

/**
 * Check if the status code is 401
 */
export function isNotAuthenticated(statusCode) {
	return statusCode === 401;
}

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

/**
 * @typedef {object} ChangeBookActivationStatusProps
 * @property {string|id} bookId
 * @property {boolean} isActive
 *
 * @param {ChangeBookActivationStatusProps} props
 */
const useChangeBookActivationStatus = (props) => {
	const { bookId, isActive } = props;

	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const update = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		const response = await instance.patch(
			`/books/change-status/${bookId}`,
			body
		);
		await queryClient.invalidateQueries("books");
		await queryClient.invalidateQueries("myUploadedBooks");
		return response?.data;
	};

	return useMutation(update, {
		onSuccess: async () => {
			const msg = isActive
				? "Book deactivated successfully"
				: "Book activated successfully";
			toast.success(msg);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useChangeBookActivationStatus;

import { activeCurrencies } from "utils";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.author
 * @param {string} props.publisher
 * @param {string} props.category
 * @param {string} props.genre
 * @param {string|number} props.usdPrice
 * @param {string|number} props.zwlPrice
 * @param {string} props.bookCover
 * @param {string} props.isbn
 * @param {string} props.description
 * @param {string} props.physicalUsdPrice
 * @param {string} props.physicalZwlPrice
 */
const Details = (props) => {
	const {
		title,
		author,
		publisher,
		category,
		genre,
		isbn = "N/A",
		description,
		usdPrice = 0,
		zwlPrice = 0,
		physicalUsdPrice = 0,
		physicalZwlPrice = 0,
	} = props;

	return (
		<div className="space-y-4">
			<div className="flex items-center gap-2">
				<h3 className="font-bold">{title}</h3>
			</div>

			<div>
				<p>Written by {author}</p>
				<p>Published by {publisher}</p>
				<p>Category: {category}</p>
				<p>Genre: {genre}</p>
				<p>ISBN: {isbn}</p>
			</div>

			<div className="flex gap-2 font-bold">
				<p>Digital copy prices</p>
				<p>
					{activeCurrencies.USD.label} {activeCurrencies.USD.symbol}
					{usdPrice}
				</p>

				<p>
					{activeCurrencies.ZWL.label} ${zwlPrice}
				</p>
			</div>

			<div className="flex gap-2 font-bold">
				<p>Physical copy prices</p>
				<p>
					{activeCurrencies.USD.label} {activeCurrencies.USD.symbol}
					{physicalUsdPrice}
				</p>

				<p>
					{activeCurrencies.ZWL.label} ${physicalZwlPrice}
				</p>
			</div>

			{description && (
				<div className="p-3 rounded-lg bg-green-100">
					<div dangerouslySetInnerHTML={{ __html: description }} />
				</div>
			)}
		</div>
	);
};

export default Details;

import { AdminSidebar } from "components";
import { useHistory } from "react-router-dom";

import CurrencyRates from "admin/AdminDashboard/CurrencyRates";
import Subscriptions from "admin/Subscriptions";
import useLogout from "api/UserResource/useLogout";
import avatarPlaceholder from "assets/images/avatar.jpg";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import useAuthStore from "store/authStore";
import { Role } from "utils/server-constants/roles";

const Admin = ({ children, pageTitle, ...rest }) => {
	//!

	const menu = useRef(null);

	const hasRoles = useAuthStore((state) => state.hasRoles);

	const history = useHistory();

	const profile = useAuthStore((state) => state.userProfile);

	const { mutateAsync } = useLogout();
	const handleLogout = async () => {
		await mutateAsync();
	};

	const items = [
		{
			label: (
				<div className="flex flex-col">
					<span className="font-bold">
						{profile?.first_name} {profile?.last_name}
					</span>
					<span className="text-sm">{profile?.email}</span>
					<span className="text-xs text-akgreen">My account</span>
				</div>
			),
			command: () => {
				history.push("/my-account");
			},
		},
		{
			label: (
				<span className="flex items-center text-akgreener gap-1">
					Logout
					<AiOutlineLogout size={20} />
				</span>
			),
			command: () => {
				handleLogout();
			},
		},
	];

	const Label = () => (
		<>
			<button
				type="button"
				onClick={(event) => menu.current.toggle(event)}
				aria-controls="profile_menu"
				aria-haspopup
			>
				<img
					src={profile?.avatar || avatarPlaceholder}
					width={20}
					alt="Profile"
					className="rounded-full border-2 border-akgreener h-7 w-7"
				/>
			</button>

			<Menu
				className="font-sans min-w-max"
				model={items}
				popup
				ref={menu}
				id="profile_menu"
			/>
		</>
	);

	return (
		<div className="bg-gray-100 text-gray-700 grid grid-cols-6 min-h-screen gap-4 font-sans font-thin">
			<aside className="min-h-screen col-start-1 col-span-1 bg-gradient-to-r from-akgreenest to-akgreen sticky top-0 py-5">
				<AdminSidebar />
			</aside>

			<main className="min-h-screen col-end-7 col-span-5 flex flex-col items-start justify-start p-4 w-full ">
				<div className="flex justify-between w-full items-center  border-gray-300 mb-4">
					<div className="flex gap-4 items-center">
						<h3 className="mb-2">{pageTitle}</h3>
					</div>

					<div className="flex flex-col gap-3 items-center">
						<div className="flex items-center gap-1">
							{hasRoles([Role.SuperAdmin]) && (
								<>
									<Subscriptions />
									<CurrencyRates />
								</>
							)}

							{rest.headerContent}
						</div>
					</div>

					<Label />
				</div>

				{rest.filters && <div>{rest.filters}</div>}

				<div className="w-full mt-2 pt-4">{children}</div>
			</main>
		</div>
	);

	//!
};

export default Admin;

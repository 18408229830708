const PaddingX = ({ side, children }) => {
	let padding;
	switch (side) {
		case "left":
			padding = "ml-4 lg:ml-10 xl:ml-28 2xl:ml-72";
			break;
		case "right":
			padding = "mr-4 lg:mr-10 xl:mr-28 2xl:mr-72";
			break;
		default:
			padding = "mx-4 lg:mx-10 xl:mx-28 2xl:mx-72";
			break;
	}
	return <div className={`px-2 ${padding}`}>{children}</div>;
};

export default PaddingX;

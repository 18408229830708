import { BiMessageSquareError } from "react-icons/bi";
import {
	BsBookmarks,
	BsCreditCard2Front,
	BsDashCircleDotted,
	BsTerminal,
} from "react-icons/bs";
import { FaRegBuilding } from "react-icons/fa";
import { FiUserCheck, FiUsers } from "react-icons/fi";
import { HiOutlineTicket } from "react-icons/hi";
import { IoKeypadOutline } from "react-icons/io5";
import { MdOutlineSubject } from "react-icons/md";
import { RiProductHuntLine } from "react-icons/ri";
import useAuthStore from "store/authStore";
import { Permission } from "utils/server-constants/permissions";
import { Role } from "utils/server-constants/roles";

const useMenuItems = () => {
	const { hasRoles, hasPermissions } = useAuthStore();
	const ICON_SIZE = 25;
	let adminDashboard = "/admin-dashboard";

	if (hasRoles([Role.SeniorPublisher, Role.Publisher])) {
		adminDashboard = "/publisher-dashboard";
	} else if (hasRoles([Role.Institution, Role.Educator])) {
		adminDashboard = "/institution-dashboard";
	} else if (hasRoles([Role.Ba])) {
		adminDashboard = "/ba-dashboard";
	}

	const menu = [
		{
			to: adminDashboard,
			label: "Dashboard",
			icon: <BsDashCircleDotted size={ICON_SIZE} />,
			show: hasRoles([
				Role.SuperAdmin,
				Role.Admin,
				Role.SeniorPublisher,
				Role.Publisher,
				Role.Institution,
				Role.Educator,
				Role.Ba,
			]),
		},
		{
			to: "/ba/client-payments",
			label: "Statement",
			icon: <RiProductHuntLine size={ICON_SIZE} />,
			show: hasRoles([Role.Ba]),
		},

		{
			to: "/books/admin/all",
			label: "Books",
			icon: <BsBookmarks size={ICON_SIZE} />,
			show:
				hasRoles([
					Role.SuperAdmin,
					Role.Admin,
					Role.SeniorPublisher,
					Role.Publisher,
				]) && hasPermissions([Permission.ViewBooks]),
		},
		{
			to: "/books/institution",
			label: "Books",
			icon: <BsBookmarks size={ICON_SIZE} />,
			show:
				hasRoles([Role.Institution, Role.Educator]) &&
				hasPermissions([Permission.ViewBooks]),
		},
		{
			to: "/books/admin/genres-and-categories",
			label: "Book categories",
			icon: <BsBookmarks size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewCategory, Permission.ViewGenre]),
		},
		{
			to: "/users/admin/all",
			label: "Users",
			icon: <FiUsers size={ICON_SIZE} />,
			show:
				hasRoles([
					Role.SuperAdmin,
					Role.Admin,
					Role.SeniorPublisher,
					Role.Publisher,
					Role.Institution,
					Role.Educator,
				]) && hasPermissions([Permission.ViewUser]),
		},
		// {
		// 	to: "/portal/users/1",
		// 	label: "User",
		// 	icon: <FiUser size={ICON_SIZE} />,
		// 	show:
		// 		hasRoles([
		// 			Role.SUPER_ADMIN,
		// 			Role.ADMIN,
		// 			Role.SENIOR_PUBLISHER,
		// 			Role.PUBLISHER,
		// 			Role.INSTITUTION,
		// 			Role.EDUCATOR,
		// 		]) && hasPermissions([Permission.VIEW_USER]),
		// },
		{
			to: "/brand-ambassadors",
			label: "Brand Ambassadors",
			icon: <FiUserCheck size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewUser]),
		},
		{
			to: "/books/publisher/purchased",
			label: "Sold books",
			icon: <BsBookmarks size={ICON_SIZE} />,
			show:
				hasRoles([Role.SeniorPublisher, Role.Publisher]) &&
				hasPermissions([Permission.ViewSoldBooks]),
		},
		{
			to: "/subject-groups",
			label: "Subject groups",
			icon: <MdOutlineSubject size={ICON_SIZE} />,
			show:
				hasRoles([
					Role.SuperAdmin,
					Role.Admin,
					Role.Institution,
					Role.Educator,
				]) && hasPermissions([Permission.ViewSubjectGroup]),
		},
		{
			to: "/roles-and-permissions",
			label: "Roles/permissions",
			icon: <IoKeypadOutline size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewRole]),
		},
		{
			to: "/vouchers",
			label: "Vouchers",
			icon: <HiOutlineTicket size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewVoucher]),
		},
		{
			to: "/pay-publisher",
			label: "Record publisher payouts",
			icon: <HiOutlineTicket size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.RecordPubPayouts]),
		},
		{
			to: "/pay-brand-ambassador",
			label: "Record BA payouts",
			icon: <BsCreditCard2Front size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.RecordBaPayouts]),
		},
		{
			to: "/publishers",
			label: "Publishers",
			icon: <FaRegBuilding size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewPublisher]),
		},
		{
			to: "/institutions",
			label: "Institutions",
			icon: <FaRegBuilding size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewInstitution]),
		},
		{
			to: "/orders",
			label: "Orders",
			icon: <RiProductHuntLine size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewOrder]),
		},
		{
			to: "/error-report",
			label: "Error report",
			icon: <BiMessageSquareError size={ICON_SIZE} />,
			show:
				hasRoles([Role.SuperAdmin]) && hasPermissions([Permission.ViewErrors]),
		},
		{
			to: "/command-center",
			label: "Command center",
			icon: <BsTerminal size={ICON_SIZE} />,
			show: hasRoles([Role.SuperAdmin]) && hasPermissions([Permission.RunCli]),
		},
	];
	return menu.filter((item) => item.show);
};

export default useMenuItems;

import useAssignBooksToSubjectGroups from "api/BookResource/useAssignBooksToSubjectGroups";
import useFetchSubjectGroups from "api/SubjectGroupResource/useFetchSubjectGroups";
import { useFormik } from "formik";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsPencil, BsSearch } from "react-icons/bs";
import { FiArrowRight, FiX } from "react-icons/fi";

const AssignSubjectGroups = ({ books, className, children }) => {
	// const [rowsPerPage, setRowsPerPage] = useState(10);
	// const [firstPage, setFirstPage] = useState(0);
	// const [page, setPage] = useState(1);

	const [display, setDisplay] = useState(false);

	const [searchTerm, setSearchTerm] = useState("");
	const [shouldFetch, setShouldFetch] = useState(true);

	function handleChangeSearchTerm(e) {
		if (shouldFetch === true) {
			setShouldFetch(false);
		}
		setSearchTerm(e.target.value);
	}

	const { data: subjectGroupsData, isLoading: isLoadingSubjectGroups } =
		useFetchSubjectGroups(
			{
				page: 1,
				rows_per_page: 6,
				search: searchTerm,
			},
			shouldFetch
		);

	const [selectedGroups, setSelectedGroups] = useState([]);

	const subjectGroups = subjectGroupsData?.data?.data;
	// const totalRecords = subjectGroupsData?.data?.total;

	const tableData = subjectGroups?.map((subjectGroup) => ({
		id: subjectGroup.id,
		name: subjectGroup.name,
		created_by:
			subjectGroup?.user?.first_name + " " + subjectGroup?.user?.last_name,
		institution_name: subjectGroup?.user?.institutions[0]?.name,
		institution_contactNumber:
			subjectGroup?.user?.institutions[0]?.contact_number,
		institution_address: subjectGroup?.user?.institutions[0]?.address,
		assigned_users_count: subjectGroup.assigned_users_count,
	}));

	// const onBasicPageChange = (event) => {
	// 	setFirstPage(event.first);
	// 	setRowsPerPage(event.rows);
	// 	setPage(event.page + 1);
	// };

	const { mutateAsync, isLoading: isAssigningBooks } =
		useAssignBooksToSubjectGroups();

	const { values, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			subjectGroups: [...selectedGroups],
			books: [...books],
		},
		onSubmit: async (data) => {
			const countUsersInAllGroups = data.subjectGroups?.reduce(
				(accumulator, group) =>
					accumulator + Number(group.assigned_users_count),
				0
			);
			const books = data.books
				.filter(
					(book) => Number(book.number_assignable) >= countUsersInAllGroups
				)
				.map((book) => book.id);

			const subjectGroups = data.subjectGroups.map((group) => group.id);

			const payload = { subjectGroups, books };

			await mutateAsync(payload);

			setDisplay(false);
		},

		enableReinitialize: true,
	});

	const totalNumberOfUsersInSelectedGroups = values.subjectGroups?.reduce(
		(accumulator, group) => accumulator + group.assigned_users_count,
		0
	);

	const notAssignable = (book) => {
		const assignable = Number(book?.number_assignable);
		return totalNumberOfUsersInSelectedGroups > assignable || assignable <= 0;
	};

	return (
		<>
			<button
				type="button"
				className={`appearance-none border-0 ${className} `}
				onClick={() => setDisplay(true)}
			>
				{children || <BsPencil size={15} />}
			</button>

			<Dialog
				visible={display}
				onHide={() => setDisplay(false)}
				breakpoints={{
					"960px": "90vw",
					"0px": "100vw",
				}}
				className="font-sans"
				showHeader={false}
				contentClassName="rounded-md relative"
				blockScroll={false}
			>
				<button
					type="button"
					onClick={() => setDisplay(false)}
					className="absolute top-4 right-4"
				>
					<FiX size={30} />
				</button>

				<div className="pt-8 pb-1">
					<div className="px-4">
						<h4 className="font-cera-black text-akgreenest text-xl ">
							Give subject groups/areas to the {books?.length} selected book(s)
						</h4>
						<p>
							A book marked is "Not assignable" cannot be assigned because the
							number of copies remaining
							<br /> for assigning is less than the number of users in the
							selected subject groups
						</p>
					</div>

					<div className="grid grid-cols-3 gap-x-6 mt-4 pt-4 border-t px-4 divide-x border-gray-300">
						<div className="col-span-1 space-y-2 py-4">
							<h4 className="font-bold">Books selected for assigning</h4>

							<div className="flex flex-col gap-2">
								{books?.map((book) => (
									<p
										key={book.id}
										className={`pl-3 pr-1 text-sm rounded-full border border-gray-300 flex items-center justify-between gap-2 py-1`}
									>
										<span
											style={{ width: 350 }}
											className={`truncate text-base ${
												notAssignable(book) ? "line-through" : ""
											} `}
										>
											{book?.title} by{" "}
											<span className="font-bold">({book?.author})</span>
										</span>
										{notAssignable(book) ? (
											<span className="bg-red-400 text-white px-2 py-2 rounded-full">
												Not assignable
											</span>
										) : (
											<span className="bg-white text-white px-2 py-2 rounded-full ">
												Assignable
											</span>
										)}
									</p>
								))}
							</div>
						</div>

						<div className={`col-span-2 py-4 flex `}>
							<div className="space-y-4">
								<form
									onSubmit={handleSubmit}
									className="flex flex-col items-center space-y-4 px-4"
									style={{ maxWidth: "100%" }}
								>
									<div className="flex justify-between items-center w-full gap-4 pr-2">
										<div
											className="relative"
											style={{ width: 500 }}
										>
											<input
												type="text"
												name="searchTerm"
												placeholder="Search subject group to assign"
												className="w-full h-11 border border-gray-300 rounded-lg pr-4 pl-10"
												onChange={handleChangeSearchTerm}
											/>
											<span className="absolute left-3 top-3">
												<BsSearch
													size={20}
													className="text-gray-400"
												/>
											</span>

											<button
												type="button"
												className="absolute right-0 top-0 bg-akgreener h-11 rounded-r-lg text-white px-4"
												onClick={() => setShouldFetch(!shouldFetch)}
											>
												Search
											</button>
										</div>

										<button
											type="submit"
											className="flex items-center gap-2 px-4 py-3 rounded-full bg-akgreener text-white"
										>
											Assign books{" "}
											{isAssigningBooks ? (
												<AiOutlineLoading3Quarters
													size={20}
													className="animate-spin"
												/>
											) : (
												<FiArrowRight size={20} />
											)}
										</button>
									</div>

									<DataTable
										value={tableData}
										selection={selectedGroups}
										onSelectionChange={(e) => {
											setSelectedGroups(e.value);
											setFieldValue("subjectGroups", [...e.value]);
										}}
										loading={isLoadingSubjectGroups}
										tableClassName="font-sans text-sm"
										style={{ width: 700 }}
										autoLayout
									>
										<Column
											selectionMode="multiple"
											headerStyle={{ width: "3em" }}
										></Column>
										<Column
											field="name"
											header="Subject Group Name"
										/>
										<Column
											field="created_by"
											header="Created by"
											sortable
										/>
										<Column
											field="institution_name"
											header="Institution"
											sortable
										/>
									</DataTable>

									{/* <Paginator
												first={firstPage}
												rows={rowsPerPage}
												totalRecords={totalRecords}
												rowsPerPageOptions={rowsPerPageOptions}
												onPageChange={onBasicPageChange}
												pageLinkSize={4}
												className="font-sans bg-white mt-4"
												template={{
													layout:
														"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
												}}
											/> */}

									<div className="flex justify-end w-full pr-2">
										<button
											type="submit"
											className="flex items-center gap-2 px-4 py-3 rounded-full bg-akgreener text-white"
										>
											Assign books{" "}
											{isAssigningBooks ? (
												<AiOutlineLoading3Quarters
													size={20}
													className="animate-spin"
												/>
											) : (
												<FiArrowRight size={20} />
											)}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default AssignSubjectGroups;

import useProfile from "api/UserResource/useProfile";
import useAuthStore from "store/authStore";
import UpdateInstitution from "../UpdateInstitution";
import { Role } from "utils/server-constants/roles";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import AccountLayout from "../AccountLayout";

const MyInstitution = () => {
	const { hasRoles } = useAuthStore();

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<Container>
			<div className="mt-10 flex-1">
				<PaddingX>
					<AccountLayout
						isLoading={isLoadingProfile}
						profile={profile?.data}
					>
						<div
							className="space-y-4"
							style={{ maxWidth: 500 }}
						>
							<div>
								{hasRoles([Role.Institution]) && (
									<div>
										<UpdateInstitution
											institutionId={profile?.data?.institutions[0]?.id}
											name={profile?.data?.institutions[0]?.name}
											level={profile?.data?.institutions[0]?.level}
											address={profile?.data?.institutions[0]?.address}
											country={profile?.data?.institutions[0]?.country}
											province={profile?.data?.institutions[0]?.province}
											contactNumber={
												profile?.data?.institutions[0]?.contact_number
											}
										/>
									</div>
								)}
							</div>
						</div>
					</AccountLayout>
				</PaddingX>
			</div>
		</Container>
	);

	// return (
	// 	<ProfileLayout
	// 		heading={heading}
	// 		isLoading={isLoadingProfile}
	// 		profile={profile?.data}
	// 	>
	// 		{hasRoles([Role.INSTITUTION]) && (
	// 			<div>
	// 				<UpdateInstitution
	// 					institutionId={profile?.data?.institutions[0]?.id}
	// 					name={profile?.data?.institutions[0]?.name}
	// 					level={profile?.data?.institutions[0]?.level}
	// 					address={profile?.data?.institutions[0]?.address}
	// 					country={profile?.data?.institutions[0]?.country}
	// 					province={profile?.data?.institutions[0]?.province}
	// 					contactNumber={profile?.data?.institutions[0]?.contact_number}
	// 				/>
	// 			</div>
	// 		)}
	// 	</ProfileLayout>
	// );
};

export default MyInstitution;

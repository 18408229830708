import useAddToCart from "api/BookResource/useAddToCart";
// import useAddToReadingList from "api/BookResource/useAddToReadingList";
import useAddToSubscription from "api/BookResource/useAddToSubscription";
import useRemoveFromLibrary from "api/BookResource/useRemoveFromLibrary";
import StarRating from "components/StarRating";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { AiOutlineDownCircle, AiOutlinePlus } from "react-icons/ai";
import { BsNodeMinus, BsX } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import useAuthStore from "store/authStore";
import useCurrencyStore from "store/currencyStore";
import PayUsingEcocash from "./PayUsingEcocash";
import PayUsingPayPal from "./PayUsingPayPal";
// import { OverlayPanel } from "primereact/overlaypanel";
// import { IoLogoGooglePlaystore } from "react-icons/io5";
// import { GrApple } from "react-icons/gr";
// import DownloadAppsOverlay from "./DownloadAppsOverlay";
import PayUsingStars from "./PayUsingStars";
import { activeCurrencies } from "utils";
import { Role } from "utils/server-constants/roles";

const BookDetails = ({
	visible,
	setVisible,
	coverUrl,
	title,
	rating,
	price,
	zwl_price,
	credits,
	shouldShowPrice,
	description,
	inLibrary,
	canRead,
	bookId,
	subscription,
	isPurchased,
	sellable,
	rate,
	publisher,
	author,
}) => {
	const isAuthenticated = useAuthStore((state) => state.loggedIn);
	const hasRoles = useAuthStore((state) => state.hasRoles);

	const { currency, setCurrency } = useCurrencyStore();

	const location = useLocation();

	const history = useHistory();

	const [quantity, setQuantity] = useState(1);

	const [notification, setNotification] = useState("");
	const [showErrorNotification, setShowErrorNotification] = useState(false);

	const { mutateAsync, isLoading: isAddingSubs } = useAddToSubscription();

	const handleAddToSubscription = async (value) => {
		if (!isAuthenticated || !canRead()) {
			setNotification(
				"Failed to add to library. Please make sure you are logged in and you have subscribed or purchased this book"
			);
			setShowErrorNotification(true);
			return;
		}

		await mutateAsync({ booksToSubscribe: value });
	};

	const { mutateAsync: mutateAddToCart, isLoading: isAddingToCart } =
		useAddToCart();

	const handleAddToCart = async () => {
		if (!isAuthenticated) {
			setNotification("Please login to complete this action");
			setShowErrorNotification(true);
			return;
		}
		await mutateAddToCart({
			quantity: quantity,
			book_id: bookId,
		});
		setVisible(false);
	};

	const {
		mutateAsync: mutateRemoveFromLibrary,
		isLoading: isRemovingFromLibrary,
	} = useRemoveFromLibrary(bookId);

	const removeFromLibrary = async () => {
		await mutateRemoveFromLibrary();
	};

	// const { mutateAsync: mutateReadingList, isLoading: isSavingReadingList } =
	// 	useAddToReadingList(bookId);

	// const handleRead = async () => {
	// 	if (!isAuthenticated || !canRead()) {
	// 		setNotification(
	// 			"Failed to open the book. Please make sure you are logged in and you have subscribed/purchased this book"
	// 		);
	// 		setShowErrorNotification(true);
	// 		return;
	// 	}
	// 	await mutateReadingList();
	// 	if (!isSavingReadingList) {
	// 		history.push(`/books/reader/${bookId}?previous=${location.pathname}`);
	// 	}
	// };

	const [openPayPal, setOpenPayPal] = useState(false);
	const [openEcocash, setOpenEcocash] = useState(false);
	const [openStars, setOpenStars] = useState(false);

	const handleOpenPayPal = () => {
		if (!isAuthenticated) {
			setNotification("Please login to complete this action");
			setShowErrorNotification(true);
			return;
		}
		setOpenPayPal(true);
		setCurrency(activeCurrencies.USD.code);
	};

	const handleOpenEcocash = () => {
		if (!isAuthenticated) {
			setNotification("Please login to complete this action");
			setShowErrorNotification(true);
			return;
		}
		setOpenEcocash(true);
		setCurrency(activeCurrencies.ZWL.code);
	};

	const handleOpenStars = () => {
		if (!isAuthenticated) {
			setNotification("Please login to complete this action");
			setShowErrorNotification(true);
			return;
		}
		setOpenStars(true);
		setCurrency(activeCurrencies.USD.code);
	};

	const handleClosePaymentOptions = () => {
		setOpenEcocash(false);
		setOpenPayPal(false);
		setOpenStars(false);
	};

	const handleAcceptOnNotice = () => {
		if (!isAuthenticated) {
			return history.push(`/login?intended=${location.pathname}`);
		}
		return history.push("/subscriptions");
	};

	return (
		<>
			<Sidebar
				visible={visible}
				position="right"
				onHide={() => setVisible(false)}
				className="mt-4 rounded-lg min-w-max px-4 mr-4 font-sans text-sm"
				style={{
					maxHeight: "95%",
					maxWidth: 600,
				}}
			>
				{!openPayPal && !openEcocash && !openStars && (
					<div>
						<div className="flex flex-col sm:flex-row gap-8 ">
							<div
								style={{
									width: 154,
									minHeight: 234,
								}}
								className="flex items-center border-b-2 border-gray-300 justify-center bg-gradient-to-l from-akgreen opacity-60 via-gray-100 to-white p-2 rounded-tr-full rounded-br-full bg-gray-100"
							>
								<img
									src={coverUrl}
									alt=""
									width={150}
									height={230}
									className="rounded"
									style={{ height: 230 }}
								/>
							</div>

							<div style={{ maxWidth: 200 }}>
								<h4
									className="font-cera-black "
									title={title}
								>
									{title}
								</h4>
								<div className="mt-2">
									<p className="font-cera-black text-xs uppercase">Author</p>
									<span className="rounded-full text- text-center bg-">
										--- {author}
									</span>
								</div>

								<div className="mt-2">
									<p className="font-cera-black text-xs uppercase">Publisher</p>
									<span className="rounded-full text- text-center bg-">
										--- {publisher}
									</span>
								</div>

								<p className="flex gap-1 items-center mb-2 mt-2">
									<StarRating number={rating} />
								</p>

								<div className="mt-2">
									<div className="mb-2 flex gap-2">
										{/* <DownloadAppsOverlay /> */}

										{!Boolean(inLibrary) &&
											Boolean(subscription) &&
											!Boolean(isPurchased) && (
												<button
													className="border flex gap-1 py-1 max-w-max border-akgreener px-2 rounded-lg text-center hover:text-white hover:bg-akgreenest transition "
													type="button"
													onClick={() => handleAddToSubscription(bookId)}
												>
													{isAddingSubs ? (
														<>
															<AiOutlineDownCircle
																size={20}
																className="animate-spin"
															/>{" "}
															Adding...
														</>
													) : (
														<>
															<AiOutlinePlus size={20} /> Add to library
														</>
													)}
												</button>
											)}

										{inLibrary &&
											location.pathname === "/my-library/picks" &&
											!isPurchased && (
												<button
													type="button"
													onClick={removeFromLibrary}
													className="border flex gap-1 py-1 max-w-max border-akgreener px-2 rounded-lg text-center hover:text-white hover:bg-akgreenest transition "
												>
													{isRemovingFromLibrary ? (
														<>
															<AiOutlineDownCircle
																size={20}
																className="animate-spin"
															/>{" "}
															Removing...
														</>
													) : (
														<>
															<BsNodeMinus size={20} />
															Remove from library
														</>
													)}
												</button>
											)}
									</div>
								</div>

								{shouldShowPrice && (
									<div className="mt-2">
										<p className="font-bold font-serif text-lg">
											$
											{currency === activeCurrencies.USD.code
												? price.toFixed(2)
												: zwl_price.toFixed(2)}
										</p>

										{hasRoles([Role.Institution]) && (
											<div className="flex mt-2">
												<p className="border border-gray-400 px-1">Quantity</p>
												<div className="">
													<button
														type="button"
														className="w-10 border border-gray-400 border-l-0"
														onClick={() =>
															setQuantity((prev) =>
																Number(prev) - 1 > 1 ? Number(prev) - 1 : 1
															)
														}
													>
														-
													</button>
													<input
														type="text"
														readOnly
														className="w-10 text-center border-t border-b border-gray-400 outline-none"
														value={quantity}
														onChange={(e) => setQuantity(e.target.value)}
													/>
													<button
														type="button"
														className="w-10 border border-gray-400"
														onClick={() =>
															setQuantity((prev) => Number(prev) + 1)
														}
													>
														+
													</button>
												</div>
											</div>
										)}

										<div className="mt-2 flex flex-col gap-2">
											<button
												type="button"
												onClick={() => handleAddToCart()}
												className="border flex gap-2 py-1 max-w-max border-akgreener px-2 rounded-lg text-center hover:text-white hover:bg-akgreenest transition "
											>
												{isAddingToCart ? (
													<>
														<AiOutlineDownCircle
															size={20}
															className="animate-spin"
														/>{" "}
														Adding...
													</>
												) : (
													<>Add to cart</>
												)}
											</button>

											<div className="flex flex-col gap-2 border-t border-akgreen pt-4 mt-2 ">
												<button
													type="button"
													onClick={handleOpenPayPal}
													className="border flex gap-2 py-1 max-w-max border-akgreener px-2 rounded-lg text-center hover:text-white hover:bg-akgreenest transition "
												>
													Pay with Paypal
												</button>

												<button
													type="button"
													onClick={handleOpenEcocash}
													className="border flex gap-2 py-1 max-w-max border-akgreener px-2 rounded-lg text-center hover:text-white hover:bg-akgreenest transition "
												>
													Pay with Ecocash
												</button>

												<button
													type="button"
													onClick={handleOpenStars}
													className="border flex gap-2 py-1 max-w-max border-akgreener px-2 rounded-lg text-center hover:text-white hover:bg-akgreenest transition "
												>
													Redeem stars
												</button>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>

						{description && (
							<div
								className="mt-4 w-52 sm:w-96 "
								dangerouslySetInnerHTML={{ __html: description }}
							/>
						)}
					</div>
				)}

				{openPayPal && (
					<PayUsingPayPal
						subtotal={price}
						zwl_subtotal={zwl_price}
						books={[[bookId, quantity]]}
						rate={rate}
						bookQuantity={quantity}
						closePaymentOptions={handleClosePaymentOptions}
						closeBookDetails={() => setVisible(false)}
					/>
				)}

				{openEcocash && (
					<PayUsingEcocash
						subtotal={price}
						zwl_subtotal={zwl_price}
						books={[[bookId, quantity]]}
						rate={rate}
						bookQuantity={quantity}
						closePaymentOptions={handleClosePaymentOptions}
						closeBookDetails={() => setVisible(false)}
					/>
				)}

				{openStars && (
					<div
						style={{
							width: 350,
						}}
						className="mt-4"
					>
						<PayUsingStars
							credits={credits}
							subtotal={price}
							books={[[bookId, quantity]]}
							bookQuantity={quantity}
							closePaymentOptions={handleClosePaymentOptions}
						/>
					</div>
				)}

				<ConfirmDialog
					visible={showErrorNotification}
					onHide={() => {
						setShowErrorNotification(false);
						setVisible(false);
					}}
					style={{ maxWidth: 300 }}
					className="font-sans"
					rejectClassName="px-2 border border-akgreener bg-white text-akgreener rounded-full text-sm"
					reject={() => {
						setShowErrorNotification(false);
						setVisible(false);
					}}
					rejectIcon={<BsX size={24} />}
					acceptClassName="bg-white border border-akgreenest rounded-full text-akgreenest mx-auto font-sans text-sm"
					message={<p className="text-akgreen">{notification}</p>}
					header="Notice"
					acceptLabel={`${isAuthenticated ? "Subscribe" : "Login"}`}
					rejectLabel=" "
					icon="pi pi-info"
					accept={handleAcceptOnNotice}
				/>
			</Sidebar>
		</>
	);
};

export default BookDetails;

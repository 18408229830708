import useAddToReadingList from "api/BookResource/useAddToReadingList";
import useAddToSubscription from "api/BookResource/useAddToSubscription";
import useBook from "api/BookResource/useBook";
import useFetchRecommendedBooks from "api/BookResource/useFetchRecommendedBooks";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import { AddToCart, BigLink, BookItemColumn, BookItemRow } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { useHistory, useParams } from "react-router-dom";
import tw from "tailwind-styled-components/dist/tailwind";

const AkPreviewMain = tw.div`
  min-h-screen 
  bg-white 
  bg-library-bg bg-no-repeat bg-center bg-cover
  flex flex-col
`;

const BookPreview = () => {
	const { book_id } = useParams();

	const history = useHistory();

	const { data: body } = useBook(book_id);

	const { data: similarBooks } = useFetchRecommendedBooks(book_id);

	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();

	const { mutateAsync, isLoading } = useAddToSubscription();

	const handleAddToSubscription = async (e) => {
		let value = e.target.value;
		await mutateAsync({ booksToSubscribe: value });
	};

	const { mutateAsync: mutateReadingList, isLoading: isSavingReadingList } =
		useAddToReadingList(book_id);

	const handleRead = async () => {
		await mutateReadingList();
		if (!isSavingReadingList) {
			history.push(`/books/reader/${book_id}`);
		}
	};

	return (
		<Container>
			<AkPreviewMain>
				{/* <Header /> */}

				<div className="flex-grow">
					<PaddingX>
						<div className="flex flex-col-reverse sm:grid grid-cols-6 mt-5 gap-3 sm:gap-5 justify-between">
							<div className="col-start-1 col-span-6 sm:col-span-2">
								<BigLink to="/my-library">Launch My Library</BigLink>
							</div>
							{/* <div className="col-span-6 sm:col-span-4 flex justify-end ">
                <div className="w-full sm:w-2/3 md:w-1/2">
                  <Search />
                </div>
              </div> */}
						</div>

						<div className="grid grid-cols-6 mt-6 gap-5 justify-start">
							<div className="col-start-1 col-span-6 md:col-span-3 lg:col-span-2 ">
								<BookItemColumn
									title={body?.data?.data.title}
									coverUrl={body?.data?.data?.book_cover.url}
									author={body?.data?.data.author}
									isbn={body?.data?.data.isbn}
									publisher={
										body?.data?.data?.user?.publishers[0]?.name ||
										`${body?.data?.data?.user?.first_name} ${body?.data?.data?.user?.last_name}`
									}
									currencyCode={body?.data?.data?.currency_code}
									currencySymbol={body?.data?.data?.currency_symbol}
									price={body?.data?.data?.price}
									credits={body?.data?.data?.credits}
									bookId={body?.data?.data?.id}
									start
								/>

								<div className="hidden md:block">
									<h2 className="my-3">Similar Reads</h2>

									<div className="flex gap-3 pb-5">
										{similarBooks?.data
											?.filter((book) => book?.book_cover)
											?.map((similarBook) => (
												<BookItemRow
													key={similarBook?.id}
													title={similarBook?.title}
													coverUrl={similarBook?.book_cover?.url}
													author={similarBook?.author}
													publisher={
														similarBook?.user?.publishers[0]?.name ||
														`${similarBook?.user?.first_name} ${similarBook?.user?.last_name}`
													}
													rating={similarBook?.rating}
													currencyCode={similarBook?.currency_code}
													currencySymbol={similarBook?.currency_symbol}
													price={similarBook?.price}
													credits={similarBook?.credits}
													bookId={similarBook?.id}
													sellable={similarBook?.sellable}
													subscription={similarBook?.subscription}
												/>
											))}
									</div>
								</div>
							</div>

							<div className="col-span-6 md:col-span-3 lg:col-span-4 ">
								<div className="flex justify-between">
									<div className="w-1/2">
										<p>{body?.data?.data?.title}</p>
										<p>{body?.data?.data?.isbn}</p>
										<p className=" mt-2">
											<span className="font-bold">Published By: </span>
											{body?.data?.data?.user?.publishers[0]?.name ||
												`${body?.data?.data?.user?.first_name} ${body?.data?.data?.user?.last_name}`}
										</p>
										<h2 className="font-bold border-b-2 border-dotted border-akgreen pb-1 mb-2 mt-2">
											Summary
										</h2>
										<p>{body?.data?.data?.description}</p>

										<h5 className="font-bold border-b-2 border-dotted border-akgreen pb-1 my-2">
											Reviews
										</h5>
										<p>{body?.data?.data?.ratings}</p>
									</div>

									<div className="w-1/2 pl-5 flex flex-col items-center">
										<p className="text-3xl mb-2">
											{body?.data?.data?.currency_symbol}
											{body?.data?.data?.price} or {body?.data?.data?.credits}{" "}
											<small>stars</small>
										</p>

										<div
											style={{
												width: 160,
												padding: "0px 10px",
												marginBottom: 7,
											}}
										>
											{/* <Link to="/subscriptions" className="book-item-button">
                      Subscribe
                    </Link> */}
											{!isLoadingSubscribed &&
												isSubscribed?.data &&
												!body?.data?.data?.in_library && (
													<label
														htmlFor={`subscribe${book_id}`}
														className="book-item-button cursor-pointer rounded-full"
													>
														{isLoading ? "adding..." : "Add to Subscription"}

														<input
															id={`subscribe${book_id}`}
															type="checkbox"
															hidden
															name="booksToSubscribe"
															value={book_id}
															onChange={(e) => handleAddToSubscription(e)}
														/>
													</label>
												)}
										</div>

										{body?.data?.data?.is_purchased && (
											<button
												type="button"
												onClick={handleRead}
												className="inline border border-akgreener rounded-full px-5 "
											>
												Read
											</button>
										)}

										{!!body?.data?.data?.sellable &&
											!body?.data?.data?.is_purchased &&
											!isSubscribed?.data && (
												<AddToCart
													title={body?.data?.data.title}
													isbn={body?.data?.data.isbn}
													coverUrl={body?.data?.data?.book_cover.url}
													author={body?.data?.data.author}
													publisher={`${body?.data?.data?.user?.first_name} ${body?.data?.data?.user?.last_name}`}
													currencyCode={body?.data?.data?.currency_code}
													currencySymbol={body?.data?.data?.currency_symbol}
													price={body?.data?.data?.price}
													bookId={body?.data?.data?.id}
													rating={body?.data?.data?.rating}
												/>
											)}
									</div>
								</div>
							</div>
						</div>
					</PaddingX>
				</div>
			</AkPreviewMain>
		</Container>
	);
};

export default BookPreview;

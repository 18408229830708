import RetainBooksInPicks from "components/RetainBooksInPicks";
import useSearchParams from "hooks/useSearchParams";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { useEffect, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { Link } from "react-router-dom";

const SubscriptionSuccessResponse = () => {
	const [showListOfPicksToRetain, setShowListOfPicksToRetain] = useState(false);
	const { getParam } = useSearchParams();

	const paymentMethod = getParam("payment_method");
	const hasPicks = getParam("has_picks") === "true" ? true : false;

	useEffect(() => {
		if (hasPicks) {
			setShowListOfPicksToRetain(true);
		} else {
			setShowListOfPicksToRetain(false);
		}

		return () => setShowListOfPicksToRetain(false);
	}, [hasPicks]);

	return (
		<Container>
			<PaddingX>
				<div className="mt-10 flex flex-col justify-center items-center">
					<div className="flex justify-center rounded-full border-2 border-akgreener">
						<BiCheck
							size={150}
							className="text-akgreener opacity-95"
						/>
					</div>

					<div
						className="flex flex-col gap-1 items-center text-center mt-4"
						style={{ maxWidth: 350 }}
					>
						<h1>Subscription payment Successful</h1>

						<h3>
							Your <span className="font-bold">{paymentMethod}</span> payment
							was recorded successfully
						</h3>

						<p>
							Browse{" "}
							<Link
								className="font-bold text-blue-800 text-base underline"
								to={`/books`}
							>
								books
							</Link>{" "}
							and pick the ones you want to enlist in your subscription. When
							you find a book you like, click the "Add to picks" button to
							enlist it in your subscription.
						</p>

						<p className="mt-4 pt-4 border-t border-gray-300 text-xs">
							You can read the books from your mobile device(s) using the
							library mobile app
						</p>
					</div>
				</div>

				<RetainBooksInPicks
					showListOfPicksToRetain={showListOfPicksToRetain}
					setShowListOfPicksToRetain={setShowListOfPicksToRetain}
				/>
			</PaddingX>
		</Container>
	);
};

export default SubscriptionSuccessResponse;

/**
 * @param {Object} props
 * @param {number|string} props.width
 * @param {number|string} props.height
 * @param {string} props.hexColor
 * @param {string} props.className
 * @param {React.ReactNode} props.children
 */
const WavyLoader = (props) => {
	const {
		width = 10,
		height = 10,
		hexColor = "#d1fae5",
		className = "",
		children,
	} = props;

	return (
		<div
			className={`relative overflow-hidden animate-wave rounded-xl ${className} `}
			style={{
				backgroundImage: `linear-gradient(to right, ${hexColor} 1px, transparent 1px), linear-gradient(to bottom, ${hexColor} 1px, transparent 1px)`,
				width,
				height,
			}}
		>
			{children}
		</div>
	);
};

export default WavyLoader;

// Add this CSS to your global styles or create a new CSS module
/*
.
*/

import { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSearchParams from "hooks/useSearchParams";
import { deleteEmptyObjects } from "utils";
import {
	filteringParams,
	paginationParams,
	searchingParams,
} from "./booksQueryParams";
import { rowsPerPageOptions } from "utils/constants";

const useBooksFilters = () => {
	const allSearchParams = [
		...paginationParams,
		...searchingParams,
		...filteringParams,
	];

	const defaultFilters = useMemo(() => {
		const filters = allSearchParams.reduce((acc, key) => {
			if (key === "rowsPerPage") {
				acc[key] = rowsPerPageOptions[0];
			} else if (key === "page") {
				acc[key] = 1;
			} else if (key === "firstPage") {
				acc[key] = 0;
			} else {
				acc[key] = "";
			}
			return acc;
		}, {});
		return filters;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [filters, setFilters] = useState(defaultFilters);
	const { getParam, setParam, deleteParam } = useSearchParams();
	const { push } = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		const params = Object.keys(defaultFilters).reduce((acc, key) => {
			const value = getParam(key);
			acc[key] = value
				? isNaN(value)
					? value
					: parseInt(value, 10)
				: defaultFilters[key];
			return acc;
		}, {});

		setFilters(params);

		return () => Object.keys(defaultFilters).forEach(deleteParam);
	}, [getParam, deleteParam, defaultFilters]);

	const updateFilter = (key, value) => {
		if (!value) {
			const newFilters = { ...filters, [key]: value || defaultFilters[key] };
			setFilters(newFilters);
			const q = deleteParam(key);
			return push(`${pathname}?${q}`);
		}
		const q = setParam(key, value);
		push(`${pathname}?${q}`);
	};

	const changeAllFiltersAtOnce = (data) => {
		const values = deleteEmptyObjects(data);
		setFilters({ ...defaultFilters, ...values });
		push(`${pathname}?${new URLSearchParams(values).toString()}`);
	};

	return {
		...filters,
		changeFilter: updateFilter,
		paginationParams,
		searchingParams,
		filteringParams,
		allSearchParams,
		changeAllFiltersAtOnce,
	};
};

export default useBooksFilters;

import Admin2 from "layouts/Admin2";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import useFetchUsers from "api/UserResource/useFetchUsers";
import { Column } from "primereact/column";
import DeleteUser from "admin/Users2/DeleteUser";
import UserLogins from "./UserLogins";
import useAuthStore from "store/authStore";
import useProfile from "api/UserResource/useProfile";
import { CustomPaginator } from "components";
import { Role } from "utils/server-constants/roles";
import { UserFilterProvider } from "./UsersFilter/UserFilterContext";
import useUsersFilters from "./UsersFilter/useUsersFilters";
import RowsPerPage from "admin/Filters/RowsPerPage";
import { formData } from "./UsersFilter/userQueryParams";
import Search from "admin/Filters/Search";
import ClearFilters from "admin/Filters/ClearFilters";
import UsersFilter from "./UsersFilter";
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import { profileNavTabs } from "admin/User/utils";

const Users2 = () => {
	const filters = useUsersFilters();

	const numberOfActiveFilters = filters?.allSearchParams
		?.filter((param) => !filters?.paginationParams?.includes(param))
		?.filter((param) => filters[param] !== "")?.length;

	const { hasRoles } = useAuthStore();

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	const userProfile = profile?.data;

	const publisherIs = hasRoles([Role.SeniorPublisher])
		? userProfile?.publishers[0]?.id
		: "";
	const institutionIs = hasRoles([Role.Institution, Role.Educator])
		? userProfile?.institutions[0]?.id
		: "";

	const { data: usersData, isLoading: isLoadingUsers } = useFetchUsers({
		page: filters?.page,
		rows_per_page: filters?.rowsPerPage,
		date_from: filters?.dateFrom,
		date_to: filters?.dateTo,
		search: filters?.search,
		"filter[trashed]": filters?.trashed,
		"filter[role_is]": filters?.roleIs,
		"filter[publisher_is]": filters?.publisherIs || publisherIs,
		"filter[institution_is]": filters?.institutionIs || institutionIs,
	});

	const users = usersData?.data?.records;

	const totalRecords = usersData?.data?.total_records || 0;

	const actionBodyTemplate = (row) => (
		<div className="flex flex-row gap-1 items-center text-akgreen">
			{row?.deleted_at === null && (
				<>
					<Link
						to={`/portal/users/${row?.id}?tab=${profileNavTabs.Profile}`}
						className="text-blue-700 h-10 w-10 flex justify-center items-center border border-gray-300 rounded-full"
					>
						<FiExternalLink size={20} />
					</Link>

					{/* <span className="h-10 w-10 flex justify-center items-center border border-gray-300 rounded-full">
						<EditUser
							firstName={row?.first_name}
							lastName={row?.last_name}
							email={row?.email}
							mobileNumber={row?.mobile_number}
							role={row?.role_name[0]}
							id={row?.id}
							userPermissions={row?.permission_names}
							allPermissions={allPermissions?.data}
							assignedSubjectGroups={row?.assignedSubjectGroups?.map(
								(subject) => subject.id
							)}
							subjectGroups={subjectGroups}
						/>
					</span> */}

					<span className="h-10 w-10 flex justify-center items-center border border-gray-300 rounded-full">
						<DeleteUser
							userId={row?.id}
							elementId={`element-${row?.id}`}
						/>
					</span>
				</>
			)}
		</div>
	);

	const loginsTemplate = (row) => <UserLogins logins={row?.logins} />;

	const createdAtTemplate = (row) => <span>{row?.created_at}</span>;

	const nameTemplate = (row) => (
		<p className={`flex flex-col `}>
			<span>
				{row.first_name} {row.last_name}
			</span>
			<span className="px-4 py-2 max-w-max rounded-full text-akgreener border border-akgreener text-xs text-center">
				{row?.role_name[0]}
			</span>
		</p>
	);

	const contactDetailsTemplate = (row) => (
		<div className="flex flex-col">
			<span>{row.email}</span>
			{row?.mobile_number && <span>{row.mobile_number}</span>}
		</div>
	);

	const [selectedUsers, setSelectedUsers] = useState([]);

	return (
		<Admin2 fullWidth>
			<UserFilterProvider>
				<div className="flex flex-col w-full mt-4">
					<DataTable
						header={
							<div className="space-y-4">
								<h3 className="font-bold flex items-center justify-between gap-3 rounded-full max-w-max">
									<span>Users' list</span>
									<span className="block bg-gray-400 rounded-full text-white py-1 w-14 text-center">
										{totalRecords || 0}
									</span>
								</h3>
								<div className="flex items-center gap-2 justify-between ">
									<RowsPerPage
										filters={filters}
										formData={formData}
									/>
									<Search
										filters={filters}
										formData={formData}
									/>
									<ClearFilters
										filters={filters}
										numberOfActiveFilters={numberOfActiveFilters}
									/>

									<UsersFilter numberOfActiveFilters={numberOfActiveFilters} />
								</div>
							</div>
						}
						loading={isLoadingUsers || isLoadingProfile}
						value={users}
						selection={selectedUsers}
						onSelectionChange={(e) => {
							setSelectedUsers(e.value);
						}}
						tableClassName="font-sans text-sm w-full"
						dataKey="id"
						autoLayout
					>
						{/* <Column
									selectionMode="multiple"
									headerStyle={{ width: "3em" }}
								/> */}

						<Column
							header="Full name"
							headerClassName="text-center "
							body={nameTemplate}
						/>

						<Column
							header="Contact details"
							headerClassName="text-center"
							bodyClassName="text-center"
							body={contactDetailsTemplate}
							sortable
						/>
						<Column
							header="Registered on"
							body={createdAtTemplate}
						/>
						<Column
							header="Logins"
							headerClassName="text-center"
							body={loginsTemplate}
						/>
						<Column
							header="Actions"
							headerClassName="text-center"
							body={actionBodyTemplate}
						/>
					</DataTable>

					<div className="flex-1 flex justify-center mt-6">
						<CustomPaginator
							firstPage={filters?.firstPage}
							setFirstPage={(val) => filters?.changeFilter("firstPage", val)}
							rowsPerPage={filters?.rowsPerPage}
							setRowsPerPage={(val) =>
								filters?.changeFilter("rowsPerPage", val)
							}
							totalRecords={totalRecords}
							setPage={(val) => filters?.changeFilter("page", val)}
							showRowsPerPageDropdown={false}
						/>
					</div>
				</div>
			</UserFilterProvider>
		</Admin2>
	);
};

export default Users2;

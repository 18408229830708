import useAddToCart from "api/BookResource/useAddToCart";
import { ButtonLoader } from "components";
import Button from "components/Button";
import TextField from "components/TextField";
import { Form, Formik } from "formik";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { Link } from "react-router-dom";
import useAuthStore from "store/authStore";
import * as Yup from "yup";
import CartItem from "./CartItem";
import { Role } from "utils/server-constants/roles";

const AddToCart = ({
	coverUrl,
	bookId,
	price,
	title,
	author,
	publisher,
	currencyCode,
	currencySymbol,
	isbn,
	label = "Purchase",
	...rest
}) => {
	const [visible, setVisible] = React.useState(false);

	// const { hasRoles, isAuthenticated } = useAuthUser();

	const isAuthenticated = useAuthStore((state) => state.loggedIn);
	const hasRoles = useAuthStore((state) => state.hasRoles);

	const customIcons = (
		<React.Fragment>
			<button className="p-sidebar-icon p-link p-mr-1">
				<span className="pi pi-shopping-cart" />
			</button>
		</React.Fragment>
	);

	const initialValues = {
		book_id: bookId,
		quantity: 1,
	};

	const addToCartSchema = Yup.object().shape({
		quantity: Yup.number().required("Quantity is required"),
	});

	const { mutateAsync, isLoading } = useAddToCart();

	const handleAddToCart = async (data) => {
		await mutateAsync({
			...data,
		});
		setVisible(false);
	};

	return (
		<>
			<div style={{ width: 160, padding: "0px 10px" }}>
				<button
					type="button"
					className={`book-item-button`}
					onClick={() => setVisible(true)}
				>
					{label}
				</button>
			</div>

			<Sidebar
				visible={visible}
				onHide={() => setVisible(false)}
				position="right"
				icons={customIcons}
			>
				{!isAuthenticated ? (
					<div className="flex flex-col justify-center gap-2 text-center">
						<p>Sorry. You are not authenticated!</p>
						<p>
							Login{" "}
							<Link
								to="/login"
								className="font-bold text-akgreener underline text-lg"
							>
								here
							</Link>{" "}
							first.
						</p>
					</div>
				) : (
					<div className="font-sans text-sm">
						<div className="flex flex-col items-center">
							<CartItem
								title={title}
								coverUrl={coverUrl}
								author={author}
								publisher={publisher}
								currencyCode={currencyCode}
								currencySymbol={currencySymbol}
								isbn={isbn}
								price={price}
								bookId={bookId}
							/>
						</div>

						<Formik
							initialValues={initialValues}
							validationSchema={addToCartSchema}
							onSubmit={(data) => handleAddToCart(data)}
						>
							{(formik) => {
								return (
									<Form className="">
										{!hasRoles([Role.Individual]) && (
											<TextField
												type="number"
												name="quantity"
												label="Quantity"
												className="flex flex-col justify-center mt-4 mb-3"
											/>
										)}

										<div className="mt-4">
											{isLoading ? (
												<ButtonLoader size={40} />
											) : (
												<Button type="submit">Add to Cart</Button>
											)}
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
				)}
			</Sidebar>
		</>
	);
};

export default AddToCart;

import useProfile from "api/UserResource/useProfile";
import UpdateProfile from "./UpdateProfile";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import AccountLayout from "./AccountLayout";

const MyAccount = () => {
	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<Container>
			<div className="mt-10 flex-1">
				<PaddingX>
					<AccountLayout
						isLoading={isLoadingProfile}
						profile={profile?.data}
					>
						<div style={{ maxWidth: 500 }}>
							<h4>My profile</h4>

							<p className="mb-4 pb-5 text-xs">
								All fields marked (*) are required. Please fill in all required
								fields to update your profile.
							</p>

							<div>
								<UpdateProfile
									userId={profile?.data?.id}
									firstName={profile?.data?.first_name || ""}
									lastName={profile?.data?.last_name || ""}
									mobileNumber={profile?.data?.mobile_number || ""}
									sex={profile?.data?.sex || ""}
									dob={profile?.data?.dob || ""}
								/>
							</div>
						</div>
					</AccountLayout>
				</PaddingX>
			</div>
		</Container>
	);

	// return (
	// 	<ProfileLayout
	// 		heading={heading}
	// 		isLoading={isLoadingProfile}
	// 		profile={profile?.data}
	// 	>
	// 		<div className="pt-1">
	// 			<div className="w-full hidden md:block">
	// 				<p className="flex justify-between items-center gap-2">
	// 					<span>First name</span>
	// 					<span>{profile?.data?.first_name}</span>
	// 				</p>
	// 				<p className="flex justify-between items-center gap-2">
	// 					<span>Last name</span>
	// 					<span>{profile?.data?.last_name}</span>
	// 				</p>
	// 				<p className="flex justify-between items-center gap-2">
	// 					<span>Phone number</span>
	// 					<span>{profile?.data?.mobile_number || "--"}</span>
	// 				</p>
	// 				<p className="flex justify-between items-center gap-2">
	// 					<span>Date of birth</span>
	// 					<span>{profile?.data?.dob || "--"}</span>
	// 				</p>
	// 				<p className="flex justify-between items-center gap-2">
	// 					<span>Gender</span>
	// 					<span>{profile?.data?.sex || "--"}</span>
	// 				</p>
	// 			</div>
	// 			<div className="mt-2 md:mt-8">
	// 				<p className="mb-4 pb-5 text-xs">
	// 					All fields marked (*) are required. Please fill in all required
	// 					fields to update your profile.
	// 				</p>
	// 				<UpdateProfile
	// 					userId={profile?.data?.id}
	// 					firstName={profile?.data?.first_name || ""}
	// 					lastName={profile?.data?.last_name || ""}
	// 					mobileNumber={profile?.data?.mobile_number || ""}
	// 					sex={profile?.data?.sex || ""}
	// 					dob={profile?.data?.dob || ""}
	// 				/>
	// 			</div>
	// 		</div>
	// 	</ProfileLayout>
	// );
};

export default MyAccount;

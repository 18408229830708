import useProfile from "api/UserResource/useProfile";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import AccountLayout from "../AccountLayout";
import useGetUserShippingAddresses from "api/AddressResource/useGetUserShippingAddresses";
import DeleteShippingAddress from "./DeleteShippingAddress";
import EditShippingAddress from "./EditShippingAddress";

const MyAddresses = () => {
	const heading = "My delivery addresses";

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	const { data: shippingAddresses, isLoading: isLoadingShippingAddresses } =
		useGetUserShippingAddresses();

	return (
		<Container>
			<div className="mt-10 flex-1">
				<PaddingX>
					<AccountLayout
						isLoading={isLoadingProfile || isLoadingShippingAddresses}
						profile={profile?.data}
						showProfile={false}
					>
						<div className="space-y-4">
							<h4>{heading}</h4>

							<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
								{shippingAddresses?.map((address) => (
									<div
										key={address.id}
										className="relative rounded-lg border border-gray-200 px-6 py-8 shadow-sm"
									>
										<DeleteShippingAddress
											addressId={address.id}
											className="absolute right-6 top-4 h-8 w-8 border border-red-300 rounded-full hover:bg-red-200"
										/>

										<EditShippingAddress
											addressId={address.id}
											nickname={address.nickname}
											recipientName={address.recipient_name}
											recipientPhone={address.recipient_phone}
											className="absolute right-6 top-16 h-8 w-8 border border-green-300 rounded-full hover:bg-green-200"
										/>

										<div>
											<p className="text-base font-bold pb-2">
												{address.nickname} ({address.street_address})
											</p>

											<p className="text-sm text-gray-600">{address.suburb}</p>
											<p className="text-sm text-gray-600">{address.city}</p>
											<p className="text-sm text-gray-600">{address.country}</p>
											<p>
												<span className="font-bold">Recipient name:</span>{" "}
												{address.recipient_name}
											</p>
											<p>
												<span className="font-bold">Recipient phone:</span>{" "}
												{address.recipient_phone}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</AccountLayout>
				</PaddingX>
			</div>
		</Container>
	);
};

export default MyAddresses;

import { rowsPerPageOptions } from "utils/constants";
import useInitialQueryData from "./useInitialQueryData";

/**
 * @typedef {Object} RowsPerPageProps
 * @property {Object} filters
 * @property {Object} formData
 *
 * @param {RowsPerPageProps} props
 */
const RowsPerPage = (props) => {
	const { filters, formData } = props;

	const { filterValues, setFilterValues } = useInitialQueryData(
		filters,
		formData
	);

	const changeInputValue = (e) => {
		const value = e.target?.value;
		const updatedFilters = { ...filterValues, [e.target.name]: value };
		setFilterValues(updatedFilters);
		filters?.changeAllFiltersAtOnce(updatedFilters);
	};

	return (
		<form>
			<select
				name="rowsPerPage"
				id="rowsPerPage"
				value={filters.rowsPerPage}
				onChange={changeInputValue}
				className="h-11 w-16 border border-gary-300 rounded-lg pl-3 bg-white pr-2"
			>
				{rowsPerPageOptions.map((option) => (
					<option
						key={option}
						value={option}
					>
						{option}
					</option>
				))}
			</select>
		</form>
	);
};

export default RowsPerPage;

import Price from "pages2/Checkout/Price";
import { Link } from "react-router-dom";
import { getActiveCurrencyCodeLabel } from "utils";
import { BookFormat } from "utils/server-constants/bookFormats";

const OrderSummary = ({ cart, currency }) => {
	const currencyLabel = getActiveCurrencyCodeLabel(currency);

	return (
		<div className="rounded-xl shadow-md border border-gray-100 p-4">
			{/* Header */}
			<div className="space-y-1 mb-4 sm:mb-6 ">
				<h3 className="text-lg sm:text-xl font-semibold text-gray-900">
					Order Summary
				</h3>
				<p className="text-sm text-gray-500">
					Currency:{" "}
					<span className="font-medium text-gray-900">{currencyLabel}</span>
				</p>
			</div>

			{/* Items List */}
			<div className="space-y-3 mb-6">
				{cart?.cart_items?.map((cart) => {
					const isPhysical = cart?.format === BookFormat.Physical;
					return (
						<div
							className="flex items-start justify-between gap-3 text-sm"
							key={cart.id}
						>
							<div className="flex-1 min-w-0">
								<p className="font-medium text-gray-900 truncate">
									{cart.book_title}
								</p>
								<p className="text-gray-500 text-xs">
									Quantity: {cart.quantity} • {cart.format}
								</p>
							</div>
							<div className="text-right whitespace-nowrap font-medium text-gray-900">
								{isPhysical ? (
									<Price
										sellable={true}
										usdPrice={cart?.book?.physical_usd_price * cart?.quantity}
										zwlPrice={cart?.book?.physical_zwl_price * cart?.quantity}
										isFree={false}
									/>
								) : (
									<Price
										sellable={true}
										usdPrice={cart?.book?.price * cart?.quantity}
										zwlPrice={cart?.book?.zwl_price * cart?.quantity}
										isFree={false}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>

			{/* Divider */}
			<div className="h-px bg-gray-100 my-6" />

			{/* Total */}
			<div className="flex items-center justify-between mb-6">
				<div>
					<p className="text-sm text-gray-500">Total Amount</p>
					<p className="text-base sm:text-lg font-semibold text-gray-900 mt-1">
						{currencyLabel}{" "}
						<Price
							sellable={true}
							usdPrice={cart?.grand_total}
							zwlPrice={cart?.grand_total_zwl}
							isFree={false}
						/>
					</p>
				</div>
			</div>

			{/* Checkout Button */}
			<Link
				to="/checkout"
				className="w-full inline-flex items-center justify-center px-6 py-3 rounded-full
					bg-akgreener text-white font-medium hover:bg-akgreener/90 transition-colors
					focus:outline-none focus:ring-2 focus:ring-akgreener focus:ring-offset-2"
			>
				Proceed to Checkout
			</Link>

			{/* Additional Info */}
			<p className="mt-4 text-center text-xs text-gray-500">
				Taxes and shipping calculated at checkout
			</p>
		</div>
	);
};

export default OrderSummary;

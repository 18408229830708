import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useChangeEmail = () => {
	const queryClient = useQueryClient();

	const { storeUser, userProfile } = useAuthStore();
	const { accessToken } = useTokenStore();

	const requestOtp = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		const response = await instance.post(`/users/change-email`, body);
		await queryClient.invalidateQueries("profile");
		return response?.data;
	};

	return useMutation(requestOtp, {
		onSuccess: async (body) => {
			storeUser({ ...userProfile, email: body?.data });
			toast.success("Email address changed successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useChangeEmail;

import { RiSearch2Line } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";

const Search = () => {
	const history = useHistory();
	const location = useLocation();

	// const { bookFilters, setBookFilters, setFilterCleared } =
	// 	useBooksFilterFrontStore();

	// const [searchValue, setSearchValue] = React.useState(
	// 	() => bookFilters.searchTerm
	// );

	// const handleSearch = async (e) => {
	// 	setSearchValue(e.target.value);
	// 	await sleep(1000);
	// 	setBookFilters({ ...bookFilters, searchTerm: e.target.value });
	// 	setFilterCleared(false);
	// };

	const handleClick = () => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.append("open_filters", "true");
		history.push(`/books?${searchParams.toString()}`);
	};

	return (
		<>
			<button
				type="button"
				onClick={handleClick}
				className={`flex justify-between items-center h-8 w-48 gap-2 px-4 bg-white border border-akgreenest rounded-full`}
			>
				<span className="text-gray-500">Search book...</span>
				{/* <input
					type="text"
					name="search"
					className="py-1 outline-none"
					value={searchValue}
					placeholder="Search book..."
					onClick={handleClick}
					onChange={handleSearch}
				/> */}
				<RiSearch2Line size={20} />
			</button>
		</>
	);
};

export default Search;

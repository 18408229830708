import useFetchRecommended from "api/BookResource/useFetchRecommended";
import usePickOfWeek from "api/BookResource/usePickOfWeek";
import useRecentBooks from "api/BookResource/useRecentBooks";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import { BookItem, ButtonLoader } from "components";
import HomeSlider from "components/v2/HomeSlider/index";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";

const Home = () => {
	const { data: body, isLoading: pickOfTheWeekIsLoading } = usePickOfWeek();
	const { data: recommended, isLoading: recommendedIsLoading } =
		useFetchRecommended();
	const { data: recentBooks, isLoading: recentIsLoading } = useRecentBooks();

	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();

	return (
		<Container className="flex flex-col min-h-screen w-full ">
			<div>
				<HomeSlider />
			</div>

			<div className="grid grid-cols-2 xl:grid-cols-5 bg-gray-300 flex-grow ">
				<div className="col-span-2 md:col-span-1 xl:col-span-3 bg-white py-6 h-full pb-4">
					<PaddingX side="left">
						<div className="flex flex-col xl:flex-row gap-6">
							<div className="space-y-4">
								<h2 className="font-cera-black text-akgreener">
									Pick of the week
								</h2>

								{pickOfTheWeekIsLoading || isLoadingSubscribed ? (
									<div style={{ width: 300 }}>
										<ButtonLoader size={100} />
									</div>
								) : (
									<BookItem
										coverUrl={body?.data?.book_cover?.url}
										title={body?.data?.title}
										description={body?.data?.description}
										price={body?.data?.price}
										credits={body?.data?.credits}
										publisher={
											body?.data?.user?.publishers[0]?.name ||
											`${body?.data?.user?.first_name} ${body?.data?.user?.last_name}`
										}
										author={body?.data?.author}
										reviews={body?.data?.ratings}
										bookId={body?.data?.id}
										sellable={body?.data?.sellable}
										rating={body?.data?.rating}
										isbn={body?.data?.isbn}
										isPurchased={body?.data?.is_purchased}
										inLibrary={body?.data?.in_library}
										subscription={body?.data?.subscription}
										hasSubscription={isSubscribed?.data}
										category={body?.data?.category?.name}
										isFree={body?.data?.isFree}
										genres={body?.data?.genre?.map((genre) => [
											genre?.id,
											genre?.name,
										])}
									/>
								)}
							</div>

							<div className="space-y-4">
								<h2 className="font-cera-black text-akgreener">
									Recommended book
								</h2>

								{recommendedIsLoading || isLoadingSubscribed ? (
									<div style={{ width: 300 }}>
										<ButtonLoader size={100} />
									</div>
								) : (
									<BookItem
										coverUrl={recommended?.data?.book_cover?.url}
										title={recommended?.data?.title}
										description={recommended?.data?.description}
										price={recommended?.data?.price}
										credits={recommended?.data?.credits}
										publisher={
											recommended?.data?.user?.publishers[0]?.name ||
											`${recommended?.data?.user?.first_name} ${recommended?.data?.user?.last_name}`
										}
										author={recommended?.data?.author}
										reviews={recommended?.data?.ratings}
										bookId={recommended?.data?.id}
										sellable={recommended?.data?.sellable}
										rating={recommended?.data?.rating}
										isbn={recommended?.data?.isbn}
										isPurchased={recommended?.data?.is_purchased}
										inLibrary={recommended?.data?.in_library}
										subscription={recommended?.data?.subscription}
										hasSubscription={isSubscribed?.data}
										category={recommended?.data?.category?.name}
										isFree={recommended?.data?.isFree}
										genres={recommended?.data?.genre?.map((genre) => [
											genre?.id,
											genre?.name,
										])}
									/>
								)}
							</div>
						</div>
					</PaddingX>
				</div>

				<div className="col-span-2 md:col-span-1 xl:col-span-2 pt-6 flex justify-start md:justify-center h-full pb-4 ">
					<PaddingX side="right">
						<div className="space-y-4 pl-4 md:pl-0">
							<h2 className="font-cera-black text-akgreenest">
								Recently Added
							</h2>

							{recentIsLoading ? (
								<div style={{ width: 300 }}>
									{/* <DataLoader /> */}
									<ButtonLoader size={100} />
								</div>
							) : (
								<BookItem
									coverUrl={recentBooks?.book_cover?.url}
									title={recentBooks?.title}
									description={recentBooks?.description}
									price={recentBooks?.price}
									// credits={recentBooks.credits}
									publisher={
										recentBooks?.user?.publishers[0]?.name ||
										`${recentBooks?.user?.first_name} ${recentBooks?.user?.last_name}`
									}
									author={recentBooks?.author}
									reviews={recentBooks?.ratings}
									bookId={recentBooks?.id}
									sellable={recentBooks?.sellable}
									rating={recentBooks?.rating}
									isbn={recentBooks?.isbn}
									isPurchased={recentBooks?.is_purchased}
									inLibrary={recentBooks?.in_library}
									subscription={recentBooks?.subscription}
									hasSubscription={isSubscribed?.data}
									category={recentBooks?.category?.name}
									isFree={recentBooks?.isFree}
									genres={recentBooks?.genre?.map((genre) => [
										genre?.id,
										genre?.name,
									])}
								/>
							)}
						</div>
					</PaddingX>
				</div>
			</div>
		</Container>
	);
};

export default Home;

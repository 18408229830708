import useSearchParams from "hooks/useSearchParams";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { BsExclamation } from "react-icons/bs";
import { Link } from "react-router-dom";
import useAuthStore from "store/authStore";

const PaymentErrorResponse = () => {
	const { userProfile } = useAuthStore();
	const { getParam } = useSearchParams();
	const paymentMethod = getParam("payment_method");
	const error = getParam("error");
	const retryUrl = getParam("retryUrl");

	return (
		<Container>
			<PaddingX>
				<div className="mt-10 lg:mt-24 flex flex-col justify-center items-center">
					<div className="flex justify-center items-center">
						<BsExclamation
							size={150}
							className="text-akgreener opacity-95"
						/>
					</div>

					<div
						className="flex flex-col items-center gap-1 text-center mt-4 px-2"
						style={{ maxWidth: 380 }}
					>
						<h1 className="text-center">Payment failure</h1>
						<h5>Your {paymentMethod} payment seems to have failed</h5>

						{error && <h4>{error}</h4>}

						<p className="my-4 pt-4 border-t border-gray-200 px-2">
							If money was deducted from your {paymentMethod} account, please
							contact &nbsp;
							<Link
								className="font-bold text-blue-800 text-base underline"
								to={`/support?name=${userProfile?.first_name} ${userProfile?.last_name}&email=${userProfile?.email}`}
							>
								support here
							</Link>
						</p>

						<Link
							to={retryUrl}
							className="px-3 py-2 bg-akgreener text-white text-base rounded transition-all hover:bg-akgreen"
						>
							Retry payment
						</Link>
					</div>
				</div>
			</PaddingX>
		</Container>
	);
};

export default PaymentErrorResponse;

const ServerErrors = ({ errorMessage, errors }) => {
	return (
		<div>
			<h5 className="text-red-900 text-sm">
				{errorMessage || "Something went wrong"}
			</h5>

			{typeof errors !== "string" && (
				<ul>
					{errors?.map((error, index) => {
						return (
							<li key={index}>
								{index + 1}. {error}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default ServerErrors;

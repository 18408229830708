/**
 * @typedef {Object} UserProfile
 * @property {string} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {string} mobile_number
 * @property {string} sex
 * @property {string} dob
 * @property {string[]} [logins]
 * @property {string} avatar
 */

import create from "zustand";
import { persist } from "zustand/middleware";

const authStore = (set, get) => ({
	/** @type {UserProfile} userProfile */
	userProfile: null,
	/** @type {string} accessToken */
	accessToken: null,
	/** @type {string} refreshToken */
	refreshToken: null,
	/** @type {string[]} roles */
	roles: null,
	/** @type {string[]} permissions */
	permissions: null,
	objectPermissions: null,
	loggedIn: false,
	hasSubscription: false,

	storeUser: (user) => set({ userProfile: user }),
	storeUserRoles: (roles) => set({ roles: roles }),
	storeUserPermissions: (permissions) => set({ permissions: permissions }),
	storeUserObjectPermissions: (objectPermissions) =>
		set({ objectPermissions: objectPermissions }),
	storeAccessToken: (token) => set({ accessToken: token }),
	storeRefreshToken: (token) => set({ refreshToken: token }),
	setSubscriptionStatus: (status) => set({ hasSubscription: status }),
	setLogInStatusToTrue: () => set({ loggedIn: true }),
	hasRoles: (rolesToCheck) =>
		rolesToCheck?.some((roleToCheck) =>
			get()
				.roles?.map((role) => role?.toLowerCase())
				?.includes(roleToCheck?.toLowerCase())
		),

	hasPermissions: (permissionsToCheck) =>
		permissionsToCheck?.some((permissionToCheck) =>
			get()
				.permissions?.map((permission) => permission?.toLowerCase())
				.includes(permissionToCheck?.toLowerCase())
		),

	endSession: () =>
		set({
			userProfile: null,
			accessToken: null,
			refreshToken: null,
			roles: null,
			permissions: null,
			objectPermissions: null,
			loggedIn: false,
			hasSubscription: false,
		}),
});

const useAuthStore = create(
	persist(authStore, { name: "zus-auth", getStorage: () => localStorage })
);

export default useAuthStore;

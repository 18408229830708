import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { ConfirmPopup } from "primereact/confirmpopup";
import { useState } from "react";
import { HiOutlinePencil } from "react-icons/hi";
import * as Yup from "yup";
import useUpdateShippingAddress from "api/AddressResource/useUpdateShippingAddress";

/**
 * @param {Object} props
 * @param {string} props.addressId
 * @param {string} props.nickname
 * @param {string} props.recipientName
 * @param {string} props.recipientPhone
 * @param {string} props.className
 */
const EditShippingAddress = (props) => {
	const { addressId, nickname, recipientName, recipientPhone, className } =
		props;
	const [visible, setVisible] = useState(false);

	const iconSize = 20;

	const targetId = `edit-shipping-address-${addressId}`;

	const defaultValues = {
		nickname: nickname || "",
		recipient_name: recipientName || "",
		recipient_phone: recipientPhone || "",
	};

	const validate = Yup.object().shape({
		nickname: Yup.string().required("Address identifier is required"),
		recipient_name: Yup.string().required("Recipient name is required"),
		recipient_phone: Yup.string().required("Recipient phone is required"),
	});

	const { mutateAsync, isLoading } = useUpdateShippingAddress(addressId);

	const handleEditAddress = async (data) => {
		try {
			await mutateAsync(data);
			setVisible(false);
		} catch (error) {}
	};

	return (
		<>
			<button
				type="button"
				onClick={() => setVisible(true)}
				className={` text-akgreener hover:text-green-500 flex justify-center items-center rounded-full  ${className}`}
				id={targetId}
			>
				<HiOutlinePencil size={iconSize} />
			</button>

			<ConfirmPopup
				target={document.getElementById(targetId)}
				visible={visible}
				onHide={() => setVisible(false)}
				className="font-sans text-sm "
				message={
					<Formik
						initialValues={defaultValues}
						validationSchema={validate}
						onSubmit={handleEditAddress}
					>
						{(formik) => (
							<Form className="space-y-4 divide-y divide-gray-200 w-72 ">
								<h4>Edit shipping address</h4>

								<div className="space-y-6 py-6 pt-10">
									<FormField
										name="nickname"
										label="Address identifier"
										placeholder="Enter address identifier"
									/>

									<FormField
										name="recipient_name"
										label="Recipient name"
										placeholder="Enter recipient name"
									/>

									<FormField
										name="recipient_phone"
										type="phone"
										label="Recipient Mobile Number*"
										value={formik.values.recipient_phone}
										_onChange={(phone) => {
											if (phone !== "+")
												formik.values.recipient_phone = "+" + phone;
											else formik.values.recipient_phone = phone;
										}}
										error={Boolean(
											formik.errors.recipient_phone &&
												formik.touched.recipient_phone
										)}
										errorText={formik.errors.recipient_phone}
									/>

									<button
										type="submit"
										className="w-full bg-akgreener text-white px-4 py-2 rounded-md"
									>
										{isLoading ? "Editing..." : "Edit"}
									</button>
								</div>
							</Form>
						)}
					</Formik>
				}
				acceptLabel="Edit"
				rejectLabel="Cancel"
				acceptClassName="hidden"
				rejectClassName="hidden"
			/>
		</>
	);
};

export default EditShippingAddress;

import useSearchParams from "hooks/useSearchParams";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { useEffect } from "react";
import { BiCheck } from "react-icons/bi";
import { BsExclamation } from "react-icons/bs";
import { Link } from "react-router-dom";
import useAuthStore from "store/authStore";
import useSupportQueryStore from "store/supportQueryStore";

const PaymentResponse = () => {
	const { userProfile } = useAuthStore();
	const { setQuery } = useSupportQueryStore();
	const { getParam } = useSearchParams();

	useEffect(() => {
		setQuery(
			`Hi, I am having issues with ecocash payment. Money was deducted from my wallet but the order was not processed successfully.Please help me resolve this issue: Reference: ${getParam(
				"reference"
			)}, Order ID: ${getParam("order_id")}, Phone: ${getParam("phone")}`
		);
	}, [getParam, setQuery]);

	// const { countdown } = useCountDown(2, true);

	const statusParamIsSuccess = getParam("status") === "Successful";
	// const shouldFetch = countdown === 0 && !statusParamIsSuccess;

	// const { isLoading, isError } = useCheckEcocashStatus(
	// 	{
	// 		reference: getParam("reference"),
	// 		order_id: getParam("order_id"),
	// 		phone: getParam("phone"),
	// 	},
	// 	false
	// );

	return (
		<Container>
			<PaddingX>
				<div className="mt-10 flex flex-col justify-center items-center">
					{(function () {
						if (statusParamIsSuccess) {
							return (
								<>
									<div className="flex justify-center rounded-full border-2 border-akgreener">
										<BiCheck
											size={150}
											className="text-akgreener opacity-95"
										/>
									</div>
									<div
										className="flex flex-col items-center gap-1 text-center mt-4"
										style={{ maxWidth: 350 }}
									>
										<h1>Payment success</h1>

										<h3>
											Your <span className="font-bold">EcoCash</span> payment
											was recorded successfully
										</h3>

										<p>
											Go to &nbsp;
											<Link
												className="font-bold text-blue-800 text-base underline"
												to={`/my-library`}
											>
												your library
											</Link>{" "}
											to see books that you have purchased
										</p>

										<p className="mt-4 pt-4 border-t border-gray-300 text-xs">
											You can read the books from your mobile device(s) using
											the library mobile app
										</p>
									</div>
								</>
							);
						}

						// if (countdown > 0) {
						// 	return (
						// 		<>
						// 			<div className="flex justify-center mb-4">
						// 				<HiOutlineStatusOnline
						// 					size={150}
						// 					className="text-akgreener opacity-90 animate-pulse"
						// 				/>
						// 			</div>
						// 			<div
						// 				className="flex flex-col gap-1 items-center text-center"
						// 				style={{ maxWidth: 350 }}
						// 			>
						// 				<h1>Checking payment status</h1>
						// 				<p className="w-60 text-center">
						// 					Please wait a few seconds while we check status of your
						// 					payment
						// 				</p>
						// 				<h3 className="font-bold animate-pulse">
						// 					Time remaining: {countdown} seconds
						// 				</h3>
						// 			</div>
						// 		</>
						// 	);
						// }
						// if (isLoading) {
						// 	return (
						// 		<>
						// 			<div className="flex justify-center mb-4">
						// 				<HiOutlineStatusOnline
						// 					size={150}
						// 					className="text-akgreener opacity-90 animate-pulse"
						// 				/>
						// 			</div>
						// 			<div
						// 				className="flex flex-col gap-1 items-center text-center"
						// 				style={{ maxWidth: 350 }}
						// 			>
						// 				<h1>Checking payment status</h1>
						// 				<p className="w-60 text-center">
						// 					Please wait a few seconds while we check status of your
						// 					payment
						// 				</p>
						// 				<h3 className="animate-pulse font-bold text-center">
						// 					Resolving...
						// 				</h3>
						// 			</div>
						// 		</>
						// 	);
						// }

						// if (isError) {
						return (
							<>
								<div className="flex justify-center">
									<BsExclamation
										size={150}
										className="text-akgreener opacity-70"
									/>
								</div>
								<div
									className="flex flex-col items-center gap-1 text-center mt-4 "
									style={{ maxWidth: 350 }}
								>
									<h1 className="text-center">Payment failure</h1>
									<h3>Your ecocash payment seems to have failed</h3>
									<p>
										If money was deducted from your ecocash wallet, please
										contact{" "}
										<Link
											className="font-bold text-blue-800 text-base underline"
											to={`/support?name=${userProfile?.first_name} ${userProfile?.last_name}&email=${userProfile?.email}`}
										>
											support here
										</Link>
									</p>
								</div>
							</>
						);
						// }

						// return (
						// 	<>
						// 		<div className="flex justify-center rounded-full border-2 border-akgreener">
						// 			<BiCheck
						// 				size={150}
						// 				className="text-akgreener opacity-95"
						// 			/>
						// 		</div>
						// 		<div
						// 			className="flex flex-col items-center gap-1 text-center mt-4"
						// 			style={{ maxWidth: 350 }}
						// 		>
						// 			<h1>Payment success</h1>

						// 			<h3>
						// 				Your <span className="font-bold">EcoCash</span> payment was
						// 				recorded successfully
						// 			</h3>

						// 			<p>
						// 				Go to &nbsp;
						// 				<Link
						// 					className="font-bold text-blue-800 text-base underline"
						// 					to={`/my-library`}
						// 				>
						// 					your library
						// 				</Link>{" "}
						// 				to see books that you have purchased
						// 			</p>

						// 			<p className="mt-4 pt-4 border-t border-gray-300 text-xs">
						// 				You can read the books from your mobile device(s) using the
						// 				library mobile app
						// 			</p>
						// 		</div>
						// 	</>
						// );
					})()}
				</div>
			</PaddingX>
		</Container>
	);
};

export default PaymentResponse;

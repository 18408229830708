import {
	PayPalButtons,
	PayPalScriptProvider,
	usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { ButtonLoader } from "components";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { activeCurrencies } from "utils";

const initialOptions = {
	"client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
	currency: activeCurrencies.USD.code,
	intent: "capture",
};

const PaypalBtns = ({ amount, _onComplete }) => {
	const [{ isPending, isResolved, isRejected }] = usePayPalScriptReducer();

	return (
		<>
			{isPending && (
				<div className="flex flex-col justify-center  items-center my-3">
					<ButtonLoader size={60} />
					<p>Getting paypal ready...</p>
				</div>
			)}

			{isRejected && (
				<div className="flex flex-col justify-center items-center">
					<IoCloudOfflineOutline size={80} />
					<p>
						Failed to pull <span className="font-bold">Paypal</span> action
						buttons
					</p>
					<p>Please check your internet connection!</p>
				</div>
			)}

			{isResolved && (
				<PayPalButtons
					createOrder={(data, actions) => {
						return actions.order.create({
							purchase_units: [
								{
									amount: {
										value: amount,
									},
								},
							],
						});
					}}
					onApprove={async (data, actions) => {
						const order = await actions.order.capture();
						await _onComplete(order);
					}}
					onError={(error) => {}}
					onCancel={async (data, actions) => {
						// const order = await actions.order.capture();
					}}
				/>
			)}
		</>
	);
};

const Paypal = ({ amount, _onComplete }) => {
	return (
		<PayPalScriptProvider options={initialOptions}>
			<PaypalBtns
				amount={amount}
				_onComplete={_onComplete}
			/>
		</PayPalScriptProvider>
	);
};

export default Paypal;

import { useState } from "react";
import { Card } from "primereact/card";
import { MAX_IMAGE_SIZE, MAX_PDF_SIZE, activeCurrencies } from "utils";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useEditBook from "api/BookResource/useEditBook";
import RichTextEditor from "components/RichTextEditor";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DeleteBook from "./DeleteBook";
import useSetPickOfTheWeek from "api/BookResource/useSetPickOfTheWeek";
import useAuthStore from "store/authStore";
import useSetRecommended from "api/BookResource/useSetRecommended";
import { Role } from "utils/server-constants/roles";
import { Sidebar } from "primereact/sidebar";
import Details from "./Details";
import Activation from "./Activation";
import { CgArrowLongRight } from "react-icons/cg";

const BookCard = ({
	bookId,
	title,
	author,
	price,
	zwlPrice,
	isbn,
	bookCover,
	publisher,
	isActive,
	isFree,
	sellable,
	onSubscription,
	category,
	genres,
	description,
	className,
	hasFile,
	numberOfCopiesPurchased,
	copiesAssignable,
	collections,
	categories,
	format,
	stockLevel,
	physicalUsdPrice,
	physicalZwlPrice,
}) => {
	const [visible, setVisible] = useState(false);

	const [showCoverField, setShowCoverField] = useState(false);
	const [showFileField, setShowFileField] = useState(false);
	const [showEditBook, setShowEditBook] = useState(false);

	// const { data: categories, isLoading: isCategoriesLoading } =
	// 	useFetchCategories();

	// const { data: collections, isLoading: isGenresLoading } = useGenres();

	const hasRoles = useAuthStore((state) => state.hasRoles);

	// const { currency } = useCurrencyStore();

	// function formattedPrice() {
	// 	if (isFree) {
	// 		return "";
	// 	} else if (currency === activeCurrencies.USD.code) {
	// 		return `$${price}`;
	// 	} else {
	// 		return `$${zwlPrice}`;
	// 	}
	// }

	const initialValues = {
		title: title || "",
		isbn: isbn || "",
		author: author || "",
		book_cover: null,
		book_file: null,
		price: price || "",
		zwl_price: zwlPrice || "",
		description: description || "",
		genres: genres?.id || "",
		category_id: category?.id || "",
		subscription: Boolean(onSubscription),
		sellable: Boolean(sellable),
		// is_active: Boolean(isActive),
		isFree: Boolean(isFree),
		format: format || "",
		stock_level: stockLevel || "",
		physical_usd_price: physicalUsdPrice || "",
		physical_zwl_price: physicalZwlPrice || "",
	};

	const validate = Yup.object().shape({
		title: Yup.string().required("Book title is required"),
		author: Yup.string().required("Author is required"),
		price: Yup.number().when("isFree", {
			is: true,
			then: Yup.number().notRequired(),
			otherwise: Yup.number()
				.required("Price is required")
				.moreThan(0, "Price should be greater than 0"),
		}),

		description: Yup.mixed(),
		category_id: Yup.number().required("Book category is required"),
		genres: Yup.string().required("Book genre is required"),
		book_cover: Yup.mixed()
			.test({
				name: "fileSize",
				test: (value) => !value || value.size / 1024 / 1024 <= MAX_IMAGE_SIZE,
				message: `Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
			})
			.test({
				name: "mimeType",
				test: (value) =>
					!value ||
					["image/jpeg", "image/png", "image/svg+xml", "image/webp"].includes(
						value.type
					),
				message:
					"Only images with these file extension are required: jpeg, jpg, png, svg, webp",
			}),

		book_file: Yup.mixed()
			.test({
				name: "fileSize",
				test: (value) => !value || value.size / 1024 / 1024 <= MAX_PDF_SIZE,
				message: `File size should not exceed ${MAX_PDF_SIZE}MB`,
			})
			.test({
				name: "mineType",
				test: (value) =>
					!value ||
					["application/pdf", "application/epub+zip"].includes(value.type),
				message: "Only Pdf or Epub documents are required",
			}),
		format: Yup.string().required("Book format is required"),
		stock_level: Yup.mixed().when("format", {
			is: (format) => ["physical", "both"].includes(format),
			then: Yup.number()
				.typeError("Stock level mus be a number")
				.required("Stock level is required"),
			otherwise: Yup.mixed().notRequired(),
		}),
		physical_usd_price: Yup.mixed().when("format", {
			is: (format) => ["physical", "both"].includes(format),
			then: Yup.number()
				.typeError("Stock level mus be a number")
				.required("Set price of the physical book"),
			otherwise: Yup.mixed().notRequired(),
		}),
	});

	const { mutateAsync, isLoading } = useEditBook(bookId, setShowEditBook);

	const handleSubmit = async (data) => {
		await mutateAsync({
			...data,
			is_active: data.is_active ? 1 : 0,
			subscription: data.subscription ? 1 : 0,
			sellable: data.sellable ? 1 : 0,
			isFree: data.isFree ? 1 : 0,
		});
		setShowEditBook(false);
	};

	const {
		mutateAsync: mutatePickOfTheWeek,
		isLoading: isSettingPickOfTheWeek,
	} = useSetPickOfTheWeek(bookId);

	const setAsPickofTheWeek = async () => {
		await mutatePickOfTheWeek();
	};

	const { mutateAsync: mutateSetRecommended, isLoading: isSettingRecommended } =
		useSetRecommended(bookId);

	const setAsRecommended = async () => {
		await mutateSetRecommended();
	};

	return (
		<>
			<Card
				title={
					<div>
						<h5>{title}</h5>
						<p className="font-normal">Written by {author}</p>
						<p className="font-normal">Publisher by {publisher}</p>
						<p className="font-normal">ISBN: {isbn || "N/A"}</p>
					</div>
				}
				className={`font-sans text-sm p-2 pt-6 w-full border shadow-none border-gray-300  ${className} `}
			>
				<div className="flex w-full gap-2 relative">
					{isFree && (
						<div className="absolute -bottom-4 right-2 ">
							<span
								className="text-lg font-bold transform  w-14 h-14 rounded-full bg-akgreener text-white flex items-center justify-center"
								style={{ rotate: "-30deg" }}
							>
								free
							</span>
						</div>
					)}

					<div
						className="bg-gray-200 flex justify-center items-center rounded border"
						style={{ minHeight: "230px", width: "170px" }}
					>
						{bookCover ? (
							<img
								src={bookCover}
								alt={title}
								width="130"
								height={230}
								className="rounded"
							/>
						) : (
							<span>150 x 230</span>
						)}
					</div>

					<div className="flex flex-col flex-1  gap-2">
						<div className="flex items-center gap-2">
							{hasRoles([
								Role.SuperAdmin,
								Role.Admin,
								Role.SeniorPublisher,
								Role.SeniorPublisher,
							]) && (
								<DeleteBook
									elementId={`delete-${bookId}`}
									bookId={bookId}
								/>
							)}

							{hasRoles([Role.SuperAdmin, Role.Admin]) && (
								<>
									<Activation
										bookId={bookId}
										isActive={isActive}
									/>
								</>
							)}

							{hasRoles([
								Role.SuperAdmin,
								Role.Admin,
								Role.SeniorPublisher,
								Role.Publisher,
							]) && (
								<button
									type="button"
									onClick={() => setVisible(true)}
									className="text-xs text-white flex justify-center py-1 px-2 rounded-md items-center gap-2 bg-gradient-to-t from-blue-400 to-blue-300 border border-blue-400"
								>
									More <CgArrowLongRight size={16} />
								</button>
							)}
						</div>

						{hasRoles([Role.SuperAdmin, Role.Admin]) && (
							<div className="space-y-2">
								<button
									type="button"
									onClick={() => setAsPickofTheWeek()}
									className="text-xs text-akgreener flex justify-center py-1 px-2 rounded-md items-center gap-2 bg-gradient-to-t from-gray-300 to-white border border-gray-300"
								>
									<span>Set as pick of the week</span>
									{isSettingPickOfTheWeek ? (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									) : (
										<IoIosArrowForward size={20} />
									)}
								</button>

								<button
									type="button"
									onClick={() => setAsRecommended()}
									className="text-xs text-akgreener flex justify-center py-1 px-2 rounded-md items-center gap-2 bg-gradient-to-t from-gray-300 to-white border border-gray-300"
								>
									<span>Set as recommended</span>
									{isSettingRecommended ? (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									) : (
										<IoIosArrowForward size={20} />
									)}
								</button>
							</div>
						)}

						{/* {hasRoles([
							Role.SUPER_ADMIN,
							Role.ADMIN,
							Role.SENIOR_PUBLISHER,
							Role.PUBLISHER,
						])} */}

						{hasRoles([
							Role.SeniorPublisher,
							Role.Publisher,
							Role.SuperAdmin,
							Role.Admin,
						]) && (
							<div className="space-y-1">
								<p
									className={`${
										hasFile ? "bg-akgreen" : "bg-red-700 line-through"
									} rounded-full px-4 text-white flex items-center gap-2 max-w-max h-6`}
								>
									{hasFile ? "Has file uploaded" : "No file uploaded"}
								</p>
								<p
									className={`${
										isActive ? "bg-akgreen" : "bg-red-700 line-through"
									} rounded-full px-4 text-white flex items-center gap-2 max-w-max h-6`}
								>
									{isActive ? "Active" : "Inactive"}
								</p>
							</div>
						)}

						{hasRoles([Role.Institution, Role.Educator]) && (
							<div className="space-y-2">
								<p className="font-bold px-3 py-1 rounded-full bg-akgreen text-white max-w-max">
									Copies purchased: {numberOfCopiesPurchased}
								</p>
								<p className="font-bold px-3 py-1 rounded-full bg-akgreener text-white max-w-max ">
									Available to assign: {copiesAssignable}
								</p>
							</div>
						)}
					</div>
				</div>
			</Card>

			<Sidebar
				visible={visible}
				style={{ minWidth: "700px", maxWidth: "800px" }}
				onHide={() => setVisible(false)}
				position="right"
				blockScroll
			>
				<div className="space-y-10 px-4">
					{!showEditBook && (
						<div>
							<Details
								title={title}
								bookCover={bookCover}
								author={author}
								publisher={publisher}
								category={category?.name}
								genre={genres?.name}
								usdPrice={price}
								zwlPrice={zwlPrice}
								description={description}
								isbn={isbn}
								physicalUsdPrice={physicalUsdPrice}
								physicalZwlPrice={physicalZwlPrice}
							/>
						</div>
					)}

					<div>
						<button
							className="bg-akgreener rounded-full py-1 px-4 text-white"
							onClick={() => setShowEditBook(!showEditBook)}
						>
							{showEditBook ? "Show book details" : "Edit book"}
						</button>
					</div>

					{showEditBook && (
						<Formik
							initialValues={initialValues}
							validationSchema={validate}
							onSubmit={handleSubmit}
						>
							{({ setFieldValue, values, errors, touched }) => {
								const hasPhysical = ["physical", "both"].includes(
									values?.format || ""
								);
								return (
									<Form className="space-y-6">
										<div className="flex gap-2">
											<div className="w-1/2">
												<FormField
													type="text"
													name="title"
													label="Title *"
												/>
											</div>
											<div className="w-1/2">
												<FormField
													type="text"
													name="isbn"
													label="ISBN Number"
												/>
											</div>
										</div>

										<div className="w-full">
											<FormField
												type="text"
												name="author"
												label="Author Name *"
											/>
										</div>

										<div className="flex gap-2">
											<div className="w-1/2">
												{bookCover && !showCoverField ? (
													<div className="flex flex-col justify-center mb-2">
														<span>Book already as a cover</span>
														<button
															type="button"
															onClick={() => setShowCoverField(true)}
															className="text-blue-700 max-w-max"
														>
															Update cover
														</button>
													</div>
												) : (
													<FormField
														type="file"
														name="book_cover"
														value={null}
														label="Book Cover"
														style={{
															paddingLeft: `${
																!values?.book_cover ? "90px" : ""
															} `,
														}}
														onChange={(event) =>
															setFieldValue("book_cover", event.target.files[0])
														}
													/>
												)}
											</div>
											<div className="w-1/2">
												{hasFile && !showFileField ? (
													<div className="flex flex-col justify-center mb-2">
														<span>Book already as a file</span>
														<button
															type="button"
															onClick={() => setShowFileField(true)}
															className="text-blue-700 max-w-max"
														>
															Update book file (pdf)
														</button>
													</div>
												) : (
													<FormField
														type="file"
														name="book_file"
														value={null}
														label="Book File"
														style={{
															paddingLeft: `${
																!values?.book_file ? "90px" : ""
															} `,
														}}
														onChange={(event) =>
															setFieldValue("book_file", event.target.files[0])
														}
													/>
												)}
											</div>
										</div>

										<div
											className={
												hasPhysical
													? "flex flex-row gap-2"
													: "flex flex-col gap-6"
											}
										>
											<div className={hasPhysical ? "w-1/2" : "w-full"}>
												<FormField
													type="select"
													name="format"
													label="Book format"
													options={[
														{
															label: "Digital copies (for online reading)",
															value: "digital",
														},
														{
															label: "Physical copies",
															value: "physical",
														},

														{
															label: "Both physical and digital copies",
															value: "both",
														},
													].map((option) => ({
														label: option.label,
														value: option.value,
													}))}
												/>
											</div>
											<div className={hasPhysical ? "w-1/2" : "w-full hidden"}>
												<FormField
													type="number"
													min="0"
													step="0.01"
													name="stock_level"
													label="Number of physical copies available"
												/>
											</div>
										</div>

										<div className="flex gap-2">
											<div className="w-1/2">
												<FormField
													type="number"
													min="0"
													step="0.01"
													name="price"
													label={`Price (${activeCurrencies.USD.label})`}
												/>
											</div>
											<div className="w-1/2">
												<FormField
													type="number"
													min="0"
													step="0.01"
													name="zwl_price"
													label={`Price (${activeCurrencies.ZWL.label})`}
												/>
											</div>
										</div>

										<div
											className={` ${hasPhysical ? "flex gap-2" : "hidden"}`}
										>
											<div className="w-1/2">
												<FormField
													type="number"
													min="0"
													step="0.01"
													name="physical_usd_price"
													label={`Physical book price (${activeCurrencies.USD.label})`}
												/>
											</div>
											<div className="w-1/2">
												<FormField
													type="number"
													min="0"
													step="0.01"
													name="physical_zwl_price"
													label={`Physical book price (${activeCurrencies.ZWL.label})`}
												/>
											</div>
										</div>

										<div className="flex gap-2">
											<div className="w-1/2">
												<FormField
													type="select"
													name="category_id"
													label="Book category"
													options={categories?.data?.map((category) => ({
														label: category.name,
														value: category.id,
													}))}
												/>
											</div>
											<div className="w-1/2">
												<FormField
													type="select"
													name="genres"
													label="Book Genre"
													options={collections?.data?.data?.map((genre) => ({
														label: genre.name,
														value: genre.id,
													}))}
												/>
											</div>
										</div>

										<div className="space-y-2">
											<p className="text-gray-500">Book summary</p>
											<RichTextEditor
												value={values.description}
												onTextChange={(e) =>
													setFieldValue("description", e.htmlValue)
												}
												error={
													!!(touched.description && errors.description) &&
													errors.description
												}
											/>
										</div>

										<div className=" space-y-2">
											<FormField
												type="switcher"
												name="subscription"
												label="Do you want people to subscribe to this book?"
											/>

											<FormField
												type="switcher"
												label="Can people buy this book?"
												name="sellable"
											/>

											{/* <FormField
												type="switcher"
												label="Activate the book?"
												name="is_active"
											/> */}

											<FormField
												type="switcher"
												label="Make the book free?"
												name="isFree"
											/>
										</div>

										<div className=" flex justify-center border-t border-gray-300 pt-2">
											<button
												type="submit"
												className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
											>
												{isLoading ? (
													<>
														Updating{" "}
														<AiOutlineLoading3Quarters
															size={20}
															className="animate-spin"
														/>
													</>
												) : (
													<>
														Update <IoIosArrowForward size={20} />
													</>
												)}
											</button>
										</div>
									</Form>
								);
							}}
						</Formik>
					)}
				</div>
			</Sidebar>
		</>
	);
};

export default BookCard;

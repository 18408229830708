import useAuthStore from "store/authStore";
import useOverlayStore from "store/overlayStore";
import { Permission } from "utils/server-constants/permissions";
import { Role } from "utils/server-constants/roles";

const useCreateResourceModal = () => {
	const { hasRoles, hasPermissions } = useAuthStore();
	const {
		setShowCreateBook,
		setShowCreateUser,
		setShowCreateCategory,
		setShowCreateCollection,
		setShowCreateSubscription,
		setShowUploadBooks,
		setShowCreateSubjectGroups,
		setShowUploadUsers,
	} = useOverlayStore();

	const createBook = {
		label: "Book",
		command: () => setShowCreateBook(true),
		available:
			hasRoles([
				Role.SuperAdmin,
				Role.Admin,
				Role.SeniorPublisher,
				Role.Publisher,
			]) && hasPermissions([Permission.CreateBook]),
	};
	const uploadBooks = {
		label: "Upload books",
		command: () => setShowUploadBooks(true),
		available:
			hasRoles([
				Role.SuperAdmin,
				Role.Admin,
				Role.SeniorPublisher,
				Role.Publisher,
			]) && hasPermissions([Permission.CreateBook]),
	};
	const createUser = {
		label: "User",
		command: () => setShowCreateUser(true),
		available:
			hasRoles([
				Role.SuperAdmin,
				Role.Admin,
				Role.SeniorPublisher,
				Role.Publisher,
				Role.Institution,
				Role.Educator,
				Role.Ba,
			]) && hasPermissions([Permission.CreateUser]),
	};
	const uploadUsers = {
		label: "Upload students",
		command: () => setShowUploadUsers(true),
		available: hasRoles([Role.Institution, Role.Educator]),
	};
	const createSubjectGroup = {
		label: "Subject Group",
		command: () => setShowCreateSubjectGroups(true),
		available:
			hasRoles([
				Role.SuperAdmin,
				Role.Admin,
				Role.Institution,
				Role.Educator,
			]) && hasPermissions([Permission.CreateSubjectGroup]),
	};
	const createBookCategory = {
		label: "Book category",
		command: () => setShowCreateCategory(true),
		available:
			hasRoles([Role.SuperAdmin, Role.Admin]) &&
			hasPermissions([Permission.CreateCategory]),
	};
	const createBookCollection = {
		label: "Book collection",
		command: () => setShowCreateCollection(true),
		available:
			hasRoles([Role.SuperAdmin, Role.Admin]) &&
			hasPermissions([Permission.CreateGenre]),
	};
	const createSubscriptionPlan = {
		label: "Subscription plan",
		command: () => setShowCreateSubscription(true),
		available:
			hasRoles([Role.SuperAdmin, Role.Admin]) &&
			hasPermissions([Permission.CreateSubscription]),
	};

	const items = [
		createBook,
		uploadBooks,
		createUser,
		uploadUsers,
		createSubjectGroup,
		createBookCategory,
		createBookCollection,
		createSubscriptionPlan,
	]?.filter((items) => items.available);

	return items;
};

export default useCreateResourceModal;

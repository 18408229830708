import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useBooksFilterStore from "store/booksFilterStore";
import useTokenStore from "store/tokenStore";

const useExportBooks = () => {
	const { accessToken } = useTokenStore();

	const { bookFilters } = useBooksFilterStore();

	const history = useHistory();

	const exportBooks = async () => {
		const instance = await InitApi({ accessToken });

		const response = await instance.post("/books/export", {
			"filter[isFree]": bookFilters["filter[isFree]"],
			"filter[is_active]": bookFilters["filter[is_active]"],
			"filter[category_is]": bookFilters["filter[category_is]"],
			"filter[publisher_is]": bookFilters["filter[publisher_is]"],
			"filter[pricing_model_is]": bookFilters["filter[pricing_model_is]"],
			without_file_collection: bookFilters["without_file_collection"],
			with_file_collection: bookFilters["with_file_collection"],
		});
		return response;
	};

	return useMutation(exportBooks, {
		onSuccess: async ({ data: body }) => {
			toast.success(body.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useExportBooks;

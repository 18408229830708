import { MdLocationOn } from "react-icons/md";

const SuggestionItem = ({ location, onSelect }) => {
	return (
		<button
			type="button"
			className="flex w-full  py-2  text-sm cursor-pointer  hover:bg-[#0000000F] border-b-2  "
			onClick={onSelect}
		>
			<MdLocationOn className="mr-2 text-lg text-[#cccccc]" />

			{location.structuredFormat ? (
				<div>
					<p className="text-sm text-left font-semibold leading-tight">
						{location.structuredFormat.mainText?.text}
					</p>
					<p className="text-xs text-left leading-tight">
						{location.structuredFormat.secondaryText?.text}
					</p>
				</div>
			) : (
				<div>
					<p className="text-sm text-left font-semibold leading-tight">
						{location.text?.text}
					</p>
				</div>
			)}
		</button>
	);
};

export default SuggestionItem;

// import { motion } from "framer-motion/dist/framer-motion";

import { DesktopHeader } from "components";
import PaddingX from "layouts/PaddingX";
import { now } from "moment";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import Moment from "react-moment";
import { Link } from "react-router-dom";

function QuickLinks({ path, label, width }) {
	return (
		<Link
			to={path}
			className="flex flex-col items-center"
		>
			<span>{label}</span>
			{/* <span
				className="border-b-4 border-akgreen pt-1 w-full"
				style={{ width: width ?? 24 }}
			/> */}
		</Link>
	);
}
// text-sm font-thin font-sans text-akgreenest min-h-screen relative bg-white

const Container = ({ children, className = "" }) => {
	return (
		<div
			className={`min-h-screen flex flex-col justify-between text-sm font-thin font-sans text-akgreenest relative bg-white  ${className}`}
		>
			<div className="flex-1  space-y-10">
				<DesktopHeader />
				<div>{children}</div>
			</div>

			<footer
				className="py-8 flex flex-col justify-end text-xs xs:text-sm "
				style={{
					maxHeight: 120,
				}}
			>
				<PaddingX>
					<div className="flex justify-center items-center gap-3 text-center ">
						<QuickLinks
							path="/terms-and-conditions"
							label="Terms and conditions"
							width={80}
						/>
						<QuickLinks
							path="/privacy-policy"
							label="Privacy policy"
							width={50}
						/>
						<QuickLinks
							path="/support"
							label="Support"
							width={30}
						/>
					</div>

					<div className="flex justify-center gap-2 border-gray-200">
						<Link to="/account-deletion-manual">Account deletion</Link>
						<Link to="/downloads">Downloads</Link>
					</div>

					<div className="flex justify-center gap-1 items-center pt-2 font-bold">
						<AiOutlineCopyrightCircle size={20} />{" "}
						<Moment format="y">{now()}</Moment> Akello library
					</div>
				</PaddingX>
			</footer>
		</div>
	);
};

export default Container;

import useEditUserProfile from "api/UserResource/useEditUserProfile";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import useViewableRoles from "hooks/useViewableRoles";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { phoneRegex } from "utils";
import * as Yup from "yup";

/**
 * @typedef {Object} ProfileProps
 * @property {string|number} id
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} mobileNumber
 * @property {string} role
 *
 * @param {ProfileProps} props
 */
const Profile = (props) => {
	const { id, firstName, lastName, email, mobileNumber, role } = props;

	const { roles } = useViewableRoles();

	const [enableEmailEditing, setEnableEmailEditing] = useState(false);

	const initialValues = {
		mobile_number: mobileNumber || "",
		first_name: firstName || "",
		last_name: lastName || "",
		email: email || "",
		role: role || "",
	};

	const validate = Yup.object().shape({
		mobile_number: Yup.string()
			.nullable()
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		email: Yup.string().nullable().email("Invalid email address"),
	});

	const { mutateAsync, isLoading } = useEditUserProfile(id);

	const handleUpdateProfile = async (data) => {
		await mutateAsync(data);
	};

	return (
		<div
			className="space-y-10"
			style={{ maxWidth: 600 }}
		>
			<div>
				<h4 className="">Profile information</h4>
				<p className="text-gray-500">
					Update user's profile information. Email should only be updated with
					the account owner's consent because it is used for signing in
				</p>
			</div>

			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={handleUpdateProfile}
			>
				{({ values, errors, touched }) => (
					<Form className="space-y-6">
						<FormField
							type="text"
							name="first_name"
							label="First Name *"
						/>
						<FormField
							type="text"
							name="last_name"
							label="Last Name *"
						/>

						<div className="relative">
							<FormField
								type="text"
								name="email"
								label="Email address *"
								readOnly={!enableEmailEditing}
								classNames={{
									input: `${!enableEmailEditing ? "bg-gray-300" : ""} h-9`,
								}}
							/>

							<button
								type="button"
								onClick={() => setEnableEmailEditing(!enableEmailEditing)}
								className="absolute right-0 top-0 rounded-r-lg bg-blue-500 text-white  px-2 py-1 text-xs h-9"
							>
								{enableEmailEditing ? "Disable editing" : "Enable editing"}
							</button>
						</div>

						<FormField
							name="mobile_number"
							type="phone"
							placeholder="phone"
							value={values.mobile_number}
							_onChange={(phone) => {
								if (phone !== "+") values.mobile_number = "+" + phone;
								else values.mobile_number = phone;
							}}
							error={Boolean(errors.mobile_number && touched.mobile_number)}
							errorText={errors.mobile_number}
						/>

						<FormField
							type="select"
							name="role"
							label="Role"
							options={roles?.map((role) => ({
								label: role.label,
								value: role.value,
							}))}
						/>

						<button
							type="submit"
							className="px-4 py-2 rounded-xl bg-akgreener text-white flex gap-1 items-center justify-center"
						>
							{isLoading ? (
								<>
									Updating{" "}
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								</>
							) : (
								<>
									Update profile <IoIosArrowForward size={20} />
								</>
							)}
						</button>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default Profile;

import useFetchSubjectGroups from "api/SubjectGroupResource/useFetchSubjectGroups";
import { ButtonLoader } from "components";
import Admin2 from "layouts/Admin2";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import EditSubjectGroup from "./EditSubjectGroup";
import DeleteSubjectGroup from "./DeleteSubjectGroup";
import { rowsPerPageOptions } from "utils/constants";

const SubjectGroups = () => {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [firstPage, setFirstPage] = useState(0);
	const [page, setPage] = useState(1);

	const { data: subjectGroups, isLoading: isLoadingSubjectGroups } =
		useFetchSubjectGroups({
			page: page,
			rows_per_page: rowsPerPage,
		});

	const tableData = subjectGroups?.data?.data?.map((subjectGroup) => ({
		id: subjectGroup.id,
		name: subjectGroup.name,
		numberOfStudents: subjectGroup?.assigned_users_count,
		created_by:
			subjectGroup?.user?.first_name + " " + subjectGroup?.user?.last_name,
		institution_name: subjectGroup?.user?.institutions[0]?.name,
		institution_contactNumber:
			subjectGroup?.user?.institutions[0]?.contact_number,
		institution_address: subjectGroup?.user?.institutions[0]?.address,
	}));

	const totalRecords = subjectGroups?.data?.total;

	const onBasicPageChange = (event) => {
		setFirstPage(event.first);
		setRowsPerPage(event.rows);
		setPage(event.page + 1);
	};

	const subjectTemplate = (row) => {
		return (
			<p className="font-bold text-base pl-3 pr-1 py-1 rounded-full bg-akgreener text-white flex gap-2 items-center  max-w-max">
				<span>{row?.name}</span>
				<span className="px-2 py-1 rounded-full bg-gray-100 text-gray-600 text-xs">
					{row?.numberOfStudents} students
				</span>
			</p>
		);
	};

	const actionBodyTemplate = (row) => {
		return (
			<div className="flex items-center gap-1 text-akgreen">
				<span className="h-8 w-8 flex justify-center items-center border-r border-b border-gray-300">
					<EditSubjectGroup
						id={row.id}
						name={row.name}
					/>
				</span>

				<span className="h-8 w-8 flex justify-center items-center border-l border-b border-gray-300">
					<DeleteSubjectGroup id={row.id} />
				</span>
			</div>
		);
	};

	return (
		<Admin2>
			{isLoadingSubjectGroups ? (
				<div className="flex justify-center">
					<ButtonLoader size={60} />
				</div>
			) : (
				<div
					className="flex flex-col items-center "
					style={{ maxWidth: "90%" }}
				>
					<DataTable
						value={tableData}
						tableClassName="font-sans text-sm"
						autoLayout
					>
						<Column
							header="Name"
							sortable
							headerClassName="text-center"
							body={subjectTemplate}
						/>
						<Column
							field="created_by"
							header="Created by"
							sortable
						/>
						<Column
							field="institution_name"
							header="Institution"
							sortable
						/>
						<Column
							header="Actions"
							headerClassName="text-center"
							body={actionBodyTemplate}
						/>
					</DataTable>
					<Paginator
						first={firstPage}
						rows={rowsPerPage}
						totalRecords={totalRecords}
						rowsPerPageOptions={rowsPerPageOptions}
						onPageChange={onBasicPageChange}
						pageLinkSize={4}
						className="font-sans bg-white mt-4"
						template={{
							layout:
								"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
						}}
					/>
				</div>
			)}
		</Admin2>
	);
};

export default SubjectGroups;

import useChangeBookActivationStatus from "api/BookResource/useChangeBookActivationStatus";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

/**
 * @typedef {object} ActivationProps
 * @property {string|id} bookId
 * @property {boolean} isActive
 *
 * @param {ActivationProps} prop
 */
const Activation = (prop) => {
	const { bookId, isActive } = prop;

	const { mutateAsync, isLoading } = useChangeBookActivationStatus({
		bookId,
		isActive,
	});

	const handleChangeStatus = async (status) => {
		await mutateAsync({ is_active: status });
	};

	return (
		<>
			<button
				type="button"
				onClick={() => {
					isActive ? handleChangeStatus(false) : handleChangeStatus(true);
				}}
				className="text-xs text-akgreener flex justify-center py-1 px-2 rounded-md items-center gap-2 bg-gradient-to-t from-gray-300 to-white border border-gray-300"
			>
				{isActive ? "Deactivate" : "Activate"}
				{isLoading && (
					<AiOutlineLoading3Quarters
						size={15}
						className="animate-spin"
					/>
				)}
			</button>
		</>
	);
};

export default Activation;

import useMoveBookToTrash from "api/BookResource/useMoveBookToTrash";
import { ConfirmPopup } from "primereact/confirmpopup";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const DeleteBook = ({ elementId, bookId }) => {
	const [visible, setVisible] = React.useState(false);

	const { mutateAsync, isLoading: bookIsDeleting } = useMoveBookToTrash(bookId);

	const accept = async () => {
		await mutateAsync();
	};

	return (
		<>
			<button
				type="button"
				className="text-xs text-akgreener flex justify-center py-1 px-2 rounded-md items-center gap-2 bg-gradient-to-t from-gray-300 to-white border border-gray-300"
				onClick={() => setVisible(true)}
				id={elementId}
			>
				{bookIsDeleting && (
					<AiOutlineLoading3Quarters
						size={15}
						className="animate-spin"
					/>
				)}{" "}
				Delete
			</button>

			<ConfirmPopup
				target={document.getElementById(elementId)}
				className="font-sans text-sm items-start"
				visible={visible}
				onHide={() => setVisible(false)}
				message={
					<div
						className="p-4 rounded-md space-y-2"
						style={{ maxWidth: 350 }}
					>
						<p>
							Are you sure you want to permanently delete this book? Books are
							in active use (through reading, purchase, or subscription) will
							not be deleted
						</p>
						<p className="text-red-600 font-cera-black">
							Note this action is irreversible
						</p>
					</div>
				}
				// icon="pi pi-exclamation-triangle"
				accept={accept}
			/>
		</>
	);
};

export default DeleteBook;

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { formatCurrency } from "utils";
import ActionErrorMsg from "./ActionErrorMsg";
import { BiPlus } from "react-icons/bi";

const ActionsCard = ({
	price,
	physicalPrice,
	currency,
	book,
	addToPicks,
	isAddingToPicks,
	handleAddToPicks,
	removeFromPicks,
	isRemovingFromLibrary,
	removeFromLibrary,
	addToCart,
	isAddingToCart,
	handleAddToCart,
	cartQuantity,
	handleChangeCartQuantity,
	hasRoles,
	Role,
	format,
	setFormat,
}) => {
	const physicalAvailable =
		book.format === "physical" || book.format === "both";
	const digitalAvailable = book.format === "digital" || book.format === "both";

	return (
		<div className="actions-card sm: bg-gray-100 p-5">
			<h3 className="font-bold text-2xl mb-5 ">
				{currency}{" "}
				{formatCurrency(format === "digital" ? price : physicalPrice).replace(
					"$",
					""
				)}
			</h3>

			<div className=" flex items-center gap-2 mb-5">
				<button
					type="button"
					disabled={!digitalAvailable}
					onClick={() => setFormat("digital")}
					className={`flex-1 text-left  text-black border border-gray-400 px-4 py-2 rounded-xl   ${
						format === "digital" ? "bg-akgreener  text-white" : ""
					}
          ${digitalAvailable ? "cursor-pointer" : "cursor-not-allowed"}
          `}
				>
					<h3 className="font-bold text-xl leading-none">
						{currency} {formatCurrency(price).replace("$", "")}
					</h3>
					<p className="text-sm">Digital copy</p>
				</button>

				<button
					type="button"
					disabled={!physicalAvailable}
					onClick={() => setFormat("physical")}
					className={`flex-1 text-left  text-black border border-gray-400 px-4 py-2 rounded-xl   ${
						format === "physical" ? "bg-akgreener  text-white" : ""
					} ${physicalAvailable ? "cursor-pointer" : "cursor-not-allowed"}`}
				>
					<h3 className="font-bold text-xl  leading-none">
						{currency} {formatCurrency(physicalPrice).replace("$", "")}
					</h3>
					<p className="text-sm">Physical copy</p>
				</button>
			</div>

			{format === "digital" && (
				<p className="leading-tight mb-10">
					Access your book instantly on your Akello Library mobile application.
					Enjoy the convenience of carrying your library with you wherever you
					go. Ideal for readers who prefer the flexibility of reading on-the-go.
				</p>
			)}

			{format === "physical" && (
				<p className="leading-tight mb-10">
					Delight in the feel of turning pages and the joy of adding a new book
					to your collection. Perfect for those who cherish the tradition of
					holding a book in hand.
				</p>
			)}
			{/* ADD TO CART */}

			{!Boolean(book?.isFree) &&
				(addToCart.possible ? (
					<div className="w-full rounded-full flex items-end gap-1  mb-5 ">
						<div className="flex flex-col gap-1">
							{hasRoles([Role.INSTITUTION, Role.EDUCATOR]) && (
								<>
									<p className="text-base">Quantity</p>
									<input
										type="number"
										min={1}
										value={cartQuantity}
										onChange={(e) => handleChangeCartQuantity(e.target.value)}
										className="w-20 h-10 pl-4 pr-1 rounded-full border-transparent border border-akgreen"
									/>
								</>
							)}
						</div>

						<div className="flex-1 ">
							<button
								type="button"
								onClick={handleAddToCart}
								className="w-full flex-1 px-4 py-2 h-10 border border-transparent rounded-full bg-akgreener text-white flex justify-center items-center gap-1"
							>
								Add to cart{" "}
								{isAddingToCart ? (
									<AiOutlineLoading3Quarters
										size={20}
										className=" animate-spin "
									/>
								) : (
									<BiPlus size={20} />
								)}
							</button>
						</div>
					</div>
				) : (
					<ActionErrorMsg
						heading="Add to cart"
						message={addToCart.message}
						action={addToCart.action}
						className="w-full px-4 py-2 rounded-full border border-akgreener bg-gray-100 text-gray-400 mb-5"
					>
						Add to cart
					</ActionErrorMsg>
				))}

			{/* PICKS */}
			{addToPicks.possible ? (
				<button
					type="button"
					onClick={handleAddToPicks}
					className="w-full px-4 py-2 rounded-full border border-akgreener flex items-center gap-2"
				>
					Add to picks{" "}
					{isAddingToPicks && (
						<AiOutlineLoading3Quarters
							size={30}
							className=" animate-spin "
						/>
					)}
				</button>
			) : (
				<ActionErrorMsg
					heading="Add to picks"
					message={addToPicks.message}
					className="w-full px-4 py-2 rounded-full border border-akgreener bg-gray-100 text-gray-400 "
					action={addToPicks.action}
				>
					Add to Picks
				</ActionErrorMsg>
			)}

			{removeFromPicks.possible && (
				<button
					type="button"
					onClick={removeFromLibrary}
					className="px-4 py-2 rounded-full border border-akgreener flex items-center gap-2"
				>
					Remove from picks{" "}
					{isRemovingFromLibrary && (
						<AiOutlineLoading3Quarters
							size={20}
							className=" animate-spin "
						/>
					)}
				</button>
			)}
		</div>
	);
};

export default ActionsCard;

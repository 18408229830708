import { FiSearch } from "react-icons/fi";
import React from "react";

/**
 *
 * @param {React.InputHTMLAttributes<HTMLInputElement>} props
 */

const PortalSearchInput = (props) => {
	const { className, ...rest } = props;

	return (
		<div className="relative w-full">
			<input
				className={`h-11 w-full border border-gray-300 rounded-lg pl-12 ${className} `}
				{...rest}
			/>

			<FiSearch
				size={20}
				className="absolute left-4 top-3 text-gray-400"
			/>
		</div>
	);
};

export default PortalSearchInput;

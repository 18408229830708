import useProfile from "api/UserResource/useProfile";
import { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import Admin2 from "layouts/Admin2";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import useAuthStore from "store/authStore";

import { ButtonLoader } from "components";
import EditProfile from "admin/UserProfile/EditProfile";
import ChangePassword from "admin/UserProfile/ChangePassword";
import UpdatePublisher from "admin/UserProfile/UpdatePublisher";
import UpdateInstitution from "./UpdateInstitution";
import { Role } from "utils/server-constants/roles";

const UserProfile = () => {
	const { hasRoles } = useAuthStore();

	const [activeIndex, setActiveIndex] = useState([0]);

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	const userProfile = profile?.data;

	const onClick = (itemIndex) => {
		let _activeIndex = activeIndex ? [...activeIndex] : [];

		if (_activeIndex.length === 0) {
			_activeIndex.push(itemIndex);
		} else {
			const index = _activeIndex.indexOf(itemIndex);
			if (index === -1) {
				_activeIndex.push(itemIndex);
			} else {
				_activeIndex.splice(index, 1);
			}
		}

		setActiveIndex(_activeIndex);
	};

	return (
		<Admin2>
			{isLoadingProfile ? (
				<div className="flex justify-center mt-6">
					<ButtonLoader size={60} />
				</div>
			) : (
				<div>
					<div className="flex gap-2 mb-4">
						<button
							type="button"
							onClick={() => onClick(0)}
							className="flex gap-2 items-center px-3 py-2 rounded-md text-akgreenest bg-green-100 shadow "
						>
							{activeIndex && activeIndex.some((index) => index === 0) ? (
								<AiOutlineMinus size={20} />
							) : (
								<AiOutlinePlus size={20} />
							)}
							Update Profile
						</button>
						<button
							type="button"
							onClick={() => onClick(1)}
							className="flex gap-2 items-center px-3 py-2 rounded-md text-akgreenest bg-green-100 shadow "
						>
							{activeIndex && activeIndex.some((index) => index === 1) ? (
								<AiOutlineMinus size={20} />
							) : (
								<AiOutlinePlus size={20} />
							)}
							Password Management
						</button>

						{hasRoles([Role.SeniorPublisher]) && (
							<button
								type="button"
								onClick={() => onClick(2)}
								className="flex gap-2 items-center px-3 py-2 rounded-md text-akgreenest bg-green-100 shadow "
							>
								{activeIndex && activeIndex.some((index) => index === 2) ? (
									<AiOutlineMinus size={20} />
								) : (
									<AiOutlinePlus size={20} />
								)}
								Publisher
							</button>
						)}

						{hasRoles([Role.Institution]) && (
							<button
								type="button"
								onClick={() => onClick(3)}
								className="flex gap-2 items-center px-3 py-2 rounded-md text-akgreenest bg-green-100 shadow "
							>
								{activeIndex && activeIndex.some((index) => index === 3) ? (
									<AiOutlineMinus size={20} />
								) : (
									<AiOutlinePlus size={20} />
								)}
								Institution
							</button>
						)}
					</div>

					<div
						className="accordion-demo"
						style={{ minWidth: "50%" }}
					>
						<Accordion
							multiple
							activeIndex={activeIndex}
							onTabChange={(e) => setActiveIndex(e.index)}
							className="font-sans text-sm"
						>
							<AccordionTab
								contentStyle={{ minWidth: "50%" }}
								header="Update Profile"
							>
								<EditProfile profile={profile?.data} />
							</AccordionTab>

							<AccordionTab header="Password Management">
								<ChangePassword />
							</AccordionTab>

							{hasRoles([Role.SeniorPublisher]) && (
								<AccordionTab header="Publisher">
									<UpdatePublisher publisher={userProfile?.publishers[0]} />
								</AccordionTab>
							)}

							{hasRoles([Role.Institution]) && (
								<AccordionTab header="Institution">
									{/* {userProfile?.institutions?.length > 0} */}
									<UpdateInstitution
										institution={userProfile?.institutions[0]}
									/>
								</AccordionTab>
							)}
						</Accordion>
					</div>

					<div className="flex gap-2 mt-4">
						<button
							type="button"
							onClick={() => onClick(0)}
							className="flex gap-2 items-center px-3 py-2 rounded-md text-akgreenest bg-green-100 shadow "
						>
							{activeIndex && activeIndex.some((index) => index === 0) ? (
								<AiOutlineMinus size={20} />
							) : (
								<AiOutlinePlus size={20} />
							)}
							Update Profile
						</button>
						<button
							type="button"
							onClick={() => onClick(1)}
							className="flex gap-2 items-center px-3 py-2 rounded-md text-akgreenest bg-green-100 shadow "
						>
							{activeIndex && activeIndex.some((index) => index === 1) ? (
								<AiOutlineMinus size={20} />
							) : (
								<AiOutlinePlus size={20} />
							)}
							Password Management
						</button>

						{hasRoles([Role.SeniorPublisher]) && (
							<button
								type="button"
								onClick={() => onClick(2)}
								className="flex gap-2 items-center px-3 py-2 rounded-md text-akgreenest bg-green-100 shadow "
							>
								{activeIndex && activeIndex.some((index) => index === 2) ? (
									<AiOutlineMinus size={20} />
								) : (
									<AiOutlinePlus size={20} />
								)}
								Publisher
							</button>
						)}

						{hasRoles([Role.Institution]) && (
							<button
								type="button"
								onClick={() => onClick(3)}
								className="flex gap-2 items-center px-3 py-2 rounded-md text-akgreenest bg-green-100 shadow "
							>
								{activeIndex && activeIndex.some((index) => index === 3) ? (
									<AiOutlineMinus size={20} />
								) : (
									<AiOutlinePlus size={20} />
								)}
								Institution
							</button>
						)}
					</div>
				</div>
			)}
		</Admin2>
	);
};

export default UserProfile;

import WavyLoader from "components/v2/WavyLoader";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";

const BooksPageLoader = () => {
	return (
		<Container>
			<PaddingX>
				<div className="space-y-8">
					{/* Filter Section */}
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
						<WavyLoader
							width="100%"
							height="40px"
							className="place-self-end"
						/>
						<WavyLoader
							width="100%"
							height="40px"
							className="hidden sm:block"
						/>
						<WavyLoader
							width="100%"
							height="40px"
							className="hidden lg:block"
						/>
						<WavyLoader
							width="100%"
							height="40px"
							className="hidden lg:block"
						/>
					</div>

					{/* Books Grid */}
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
						{[...Array(10)].map((_, index) => (
							<div
								key={index}
								className="space-y-3"
							>
								{/* Book Cover */}
								<WavyLoader
									width="100%"
									height="280px"
									className="rounded-lg"
								/>
								{/* Rating Stars */}
								<WavyLoader
									width="120px"
									height="20px"
								/>
								{/* Book Title */}
								<WavyLoader
									width="100%"
									height="24px"
								/>
								{/* Author */}
								<WavyLoader
									width="70%"
									height="20px"
								/>
								{/* Price */}
								<WavyLoader
									width="60px"
									height="24px"
								/>
							</div>
						))}
					</div>
				</div>
			</PaddingX>
		</Container>
	);
};

export default BooksPageLoader;

import { createContext, useContext, useState } from "react";
import useUsersFilters from "./useUsersFilters";

const UserFilterContext = createContext(null);

export const UserFilterProvider = ({ children }) => {
	const filters = useUsersFilters();

	const formValues = filters?.allSearchParams?.reduce((acc, key) => {
		acc[key] = "";
		return acc;
	}, {});

	const [filterValues, setFilterValues] = useState(formValues);

	const contextValue = {
		filters,
		filterValues,
		setFilterValues,
	};

	return (
		<UserFilterContext.Provider value={contextValue}>
			{children}
		</UserFilterContext.Provider>
	);
};

export const useUserFilterContext = () => {
	const context = useContext(UserFilterContext);
	if (!context) {
		throw new Error(
			"useUserFilterContext must be used within a UserFilterProvider"
		);
	}
	return context;
};

// import useCreateOrder from "api/OrderResource/useCreateOrder";
import useCreatePurchaseOrder from "api/OrderResource/useCreatePurchaseOrder";
import { ButtonLoader } from "components";
import Paypal from "components/Paypal";
import React from "react";
import { activeCurrencies } from "utils";

const PayPalPayment = ({
	grandTotal,
	books,
	setPaymentIsSuccessful,
	addressId,
}) => {
	//!

	const [applyVoucher, setApplyVoucher] = React.useState(false);

	const [paymentMethod, setPaymentMethod] = React.useState("PayPal");

	const [voucherNumber, setVoucherNumber] = React.useState("");

	const handleApplyVoucher = () => {
		setPaymentMethod("Voucher");
		setApplyVoucher(true);
	};

	const handleBackToEcocash = () => {
		setPaymentMethod("PayPal");
		setApplyVoucher(false);
	};

	const { mutateAsync, isLoading, error } = useCreatePurchaseOrder();

	const _onPaypalComplete = async (order) => {
		const reference = order
			? order?.id + "-" + order?.payer?.payer_id
			: Date.now();
		await mutateAsync({
			street_address:
				order?.purchase_units[0]?.shipping?.address?.address_line_1,
			city: order?.purchase_units[0]?.shipping?.address?.admin_area_2,
			province: order?.purchase_units[0]?.shipping?.address?.admin_area_1,
			country: order?.purchase_units[0]?.shipping?.address?.country_code,
			postcode: order?.purchase_units[0]?.shipping?.address?.postal_code,
			payment_method: paymentMethod,
			payment_type: "Purchase",
			subtotal: 0,
			grand_charges: 0,
			books: books,
			grand_total: grandTotal,
			grand_total_zwl: 0,
			reference: reference,
			status: order?.status === "COMPLETED" ? "Completed" : "Failed",
			initiated_from: "cart",
			voucher_number: voucherNumber,
			currency: activeCurrencies.USD.code,
			total_amount: grandTotal,
			address_id: addressId,
		});
		setPaymentIsSuccessful(true);
	};

	return (
		<div>
			{isLoading ? (
				<div className="flex flex-col gap-3 items-center justify-center mb-3">
					<p className="text-center">
						Wait while your payment is being recorded....
					</p>
					<ButtonLoader size={100} />
				</div>
			) : (
				<div className="mt-4">
					{!applyVoucher && (
						<>
							<Paypal
								_onComplete={(order) => _onPaypalComplete(order)}
								amount={grandTotal}
							/>

							<button
								type="button"
								onClick={handleApplyVoucher}
								className="text-xl text-blue-800 font-bold"
							>
								Would you like to pay using a voucher?
							</button>
						</>
					)}

					{applyVoucher && (
						<div className="mt-4">
							<p>
								Please enter your {activeCurrencies.USD.code} voucher number
							</p>
							<div className="space-y-2 pt-2">
								<div>
									<input
										name="voucher_number"
										className="border border-akgreener rounded-full px-3 py-2 w-full"
										placeholder="e.g TKRpiLHXXy"
										onChange={(e) => setVoucherNumber(e.target.value)}
									/>
									{error && (
										<p className="text-red-500 text-xs">
											{error?.response?.data?.message}
										</p>
									)}
								</div>

								<div className="flex items-center gap-1 mt-1">
									<button
										type="button"
										onClick={() => _onPaypalComplete(null)}
										className="rounded-full px-3 py-2 text-white bg-akgreenest"
									>
										Pay with voucher
									</button>

									<button
										type="button"
										onClick={handleBackToEcocash}
										className="flex items-center gap-2 border border-akgreener py-2 px-3 rounded-full"
									>
										Back to PayPal
										{/* <FiX size={20} /> */}
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);

	//!
};

export default PayPalPayment;

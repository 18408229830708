export const paginationParams = ["page", "rowsPerPage", "firstPage"];

export const searchingParams = ["search"];

export const filteringParams = [
	"categoryIs",
	"isActive",
	"genreIs",
	"isFree",
	"publisherIs",
	"pricingModelIs",
	"withoutFileCollection",
	"withFileCollection",
];

// export const rowsPerPageOptions = [20, 40, 60, 80, 100];

export const formData = [...searchingParams, ...filteringParams].reduce(
	(acc, key) => {
		acc[key] = "";
		return acc;
	},
	{}
);

import InitApi from "api/InitApi";
import { useInfiniteQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const reactQuerySearch = async ({
	accessToken,
	pageParam = 1,
	search = "",
}) => {
	const params = deleteEmptyObjects({
		search,
		page: pageParam,
		rows_per_page: 50,
	});
	const instance = await InitApi({ accessToken });
	const payload = await instance.get("institutions", {
		params,
	});
	const res = await payload?.data;

	return {
		records: res?.data?.records,
		nextPage: res?.data?.has_more_pages ? pageParam + 1 : undefined,
	};
};

/**
 * @typedef {Object} SelectInstitutionProps
 * @property {string} search
 *
 * @param {SelectInstitutionProps} props
 */
const useSelectInstitution = (props) => {
	const { search = "" } = props;
	const { accessToken } = useTokenStore();

	// const normalFetch = async (search) => {
	// 	const params = deleteEmptyObjects({
	// 		search,
	// 		rows_per_pae: 1,
	// 	});
	// 	const instance = await InitApi({ accessToken });
	// 	const payload = await instance.get("institutions", {
	// 		params,
	// 	});
	// 	const res = await payload.data;
	// 	return (
	// 		res?.data?.records?.map((data) => ({
	// 			id: data?.id,
	// 			name: data?.name,
	// 		})) || []
	// 	);
	// };

	return useInfiniteQuery(
		["select-institution", search],
		({ pageParam }) => reactQuerySearch({ pageParam, search, accessToken }),
		{
			getNextPageParam: (lastPage) => lastPage?.nextPage,
			keepPreviousData: true,
		}
	);
};

export default useSelectInstitution;

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";

const useCancelAutoDeductions = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const submit = async () => {
		const instance = await InitApi({ accessToken });

		const url = `/cancel-recurring-subscription`;

		const res = await instance.post(url);
		await queryClient.invalidateQueries("profile");
		return res.data;
	};
	return useMutation(submit, {
		onSuccess: async () => {
			toast.success("Auto-deduction cancelled successfully");
		},

		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useCancelAutoDeductions;

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useUpdateShippingAddress = (addressId) => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();

	const update = async (data) => {
		const instance = await InitApi({ accessToken });
		const payload = deleteEmptyObjects(data);
		const response = await instance.patch(
			`/shipping-addresses/${addressId}`,
			payload
		);
		await queryClient.invalidateQueries("shipping-addresses");
		await queryClient.invalidateQueries("orders");

		return response.data;
	};

	return useMutation(update, {
		onSuccess: () => {
			toast.success("Address updated successfully");
		},
		onError: (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useUpdateShippingAddress;

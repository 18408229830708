import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";

const useAssignSubscription = (id) => {
	const { accessToken } = useTokenStore();

	const history = useHistory();

	const save = async () => {
		const instance = await InitApi({ accessToken });
		const response = await instance.patch(`/subscriptions/assign/${id}`);
		return response.data;
	};

	return useMutation(save, {
		onSuccess: async (body) => {
			// queryClient.invalidateQueries("myLibrary");
			toast.success(body.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useAssignSubscription;

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
import useOverlayStore from "store/overlayStore";
import { deleteEmptyObjects } from "utils";

const useCreateOrder = (isPayPalSubscription = false) => {
	//!

	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const { setShowListOfPicksToRetain } = useOverlayStore();

	const history = useHistory();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		if (body?.payment_type === "Subscription") {
			let url;
			if (
				body.grand_total === 0 &&
				body?.payment_method !== "Stars Redemption"
			) {
				url = `subscriptions/subscribe-free/${body?.id}`;
			} else {
				url = `subscriptions/subscribe/${body?.id}`;
			}
			const response = await instance.patch(url, body);
			return response?.data;
		}
		const response = await instance.post("/orders/create", body);
		await queryClient.invalidateQueries("user_cart_items");
		await queryClient.invalidateQueries("orders");
		await queryClient.invalidateQueries("user_cart_count");
		await queryClient.invalidateQueries("is_subscribed");
		await queryClient.invalidateQueries("picks");
		await queryClient.invalidateQueries("subscribedBooks");
		return response?.data;
	};

	return useMutation(save, {
		onSuccess: async (body) => {
			if (isPayPalSubscription) {
				setShowListOfPicksToRetain(true);
			}

			toast.success(body.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});

	//!
};

export default useCreateOrder;

import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import AccountLayout from "../AccountLayout";
import useProfile from "api/UserResource/useProfile";
import useGetUserOrders from "api/OrderResource/useGetUserOrders";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getActiveCurrencyCodeLabel, getPaymentMethodLabel } from "utils";
import { Tag } from "primereact/tag";
import MoreOrderDetails from "./MoreOrderDetails";

// Table cell templates
const ReferenceCell = ({ ecocash_reference, reference }) =>
	ecocash_reference || reference || "N/A";

const PaymentMethodCell = ({ payment_method, phone }) => {
	if (!payment_method?.startsWith("Ecocash")) {
		return <span>{payment_method}</span>;
	}

	return (
		<div className="flex flex-col">
			<span>{getPaymentMethodLabel(payment_method)}</span>
			{phone && <span className="text-xs font-bold">{phone}</span>}
		</div>
	);
};

const AmountCell = ({ total_amount, currency }) => (
	<div className="font-bold">
		{`${getActiveCurrencyCodeLabel(currency)} $${total_amount}`}
	</div>
);

const StatusCell = ({ status }) => (
	<Tag
		rounded
		className="font-sans text-xs"
		severity={status.toLowerCase() === "completed" ? "warn" : "danger"}
		value={status}
	/>
);

// Table header component
const TableHeader = ({ totalRecords }) => (
	<h4 className="flex items-center justify-between gap-3 rounded-full max-w-max">
		<span>My orders</span>
		<span className="block bg-gray-400 rounded-full text-white py-1 w-14 text-center">
			{totalRecords}
		</span>
	</h4>
);

const MyOrders = () => {
	const { data: profile, isLoading: isLoadingProfile } = useProfile();
	const { data, isLoading: isLoadingOrders } = useGetUserOrders();

	const orders = data?.records;
	const totalRecords = data?.total_records || 0;
	const isLoading = isLoadingProfile || isLoadingOrders;

	console.log({ orders });

	return (
		<Container>
			<div className="mt-10 flex-1">
				<PaddingX>
					<AccountLayout
						isLoading={isLoading}
						profile={profile?.data}
						showProfile={false}
					>
						<DataTable
							loading={isLoadingOrders}
							value={orders}
							dataKey="id"
							header={<TableHeader totalRecords={totalRecords} />}
							tableClassName="font-sans text-sm w-full"
							autoLayout
						>
							<Column
								body={(row) => (
									<MoreOrderDetails
										order_number={row.order_number}
										books={row.books}
										paymentType={row.payment_type}
										subscription={row.subscription}
										reference={row.reference}
										status={row.status}
										paymentMethod={row.payment_method}
										shippingAddress={row.shipping_address}
										created_at={row.created_at}
									/>
								)}
								header="Order Number"
							/>
							<Column
								body={ReferenceCell}
								header="Reference"
								className="hidden sm:table-cell"
							/>
							<Column
								field="payment_type"
								header="Model"
								className="hidden lg:table-cell"
							/>
							<Column
								header="Payment method"
								body={PaymentMethodCell}
								className="hidden lg:table-cell"
							/>
							<Column
								field="created_at"
								header="Transaction Date"
								className="hidden md:table-cell"
							/>
							<Column
								header="Status"
								body={StatusCell}
								className="hidden md:table-cell"
							/>
							<Column
								header="Amount"
								body={AmountCell}
								className="w-36 text-right"
							/>
						</DataTable>
					</AccountLayout>
				</PaddingX>
			</div>
		</Container>
	);
};

export default MyOrders;

import RevenueReport from "admin/AdminDashboard2/RevenueReport";
import Admin2 from "layouts/Admin2";
import AggregateBooks from "./AggregateBooks";
import WeeklyRegistrationsGraph from "./WeeklyRegistrationsGraph";
import useAuthStore from "store/authStore";
import { Permission } from "utils/server-constants/permissions";
import AggregateRegistrations from "./AggregateRegistrations";
import ReadReport from "./ReadReport";

const AdminDashboard2 = () => {
	const { hasPermissions } = useAuthStore();

	return (
		<Admin2>
			<div
				className="space-y-8 my-4"
				style={{ minWidth: "80%" }}
			>
				{hasPermissions([Permission.ViewLibRevenue]) && <RevenueReport />}
				<ReadReport />
				<AggregateRegistrations />
				<WeeklyRegistrationsGraph />

				<AggregateBooks />
			</div>
		</Admin2>
	);
};

export default AdminDashboard2;

import InitApi from "api/InitApi";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";
import toast from "react-hot-toast";
import ServerErrors from "components/SeverErrors";

const useChangeOrderStatus = (orderId) => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const submit = async (data) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(data);
		const response = await instance.patch(`/orders/${orderId}`, body);
		await queryClient.invalidateQueries("orders");
		return response?.data;
	};

	return useMutation(submit, {
		onSuccess: async (body) => {
			toast.success(body.message);
		},
		onError: async (error) => {
			<ServerErrors
				errorMessage={error?.response?.data.message}
				errors={error?.response?.data?.data}
			/>;
		},
	});
};

export default useChangeOrderStatus;

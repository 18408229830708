import useClearCart from "api/CartResource/useClearCart";
import useUserCartItems from "api/CartResource/useUserCartItems";
import { Empty } from "components";
import { Container } from "layouts";
import CartItem from "./CartItem";
import OrderSummary from "./OrderSummary";
import { Link } from "react-router-dom";
import useCurrencyStore from "store/currencyStore";
import PaddingX from "layouts/PaddingX";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsCart3 } from "react-icons/bs";
import LoadingCart from "./LoadingCart";

const Cart = () => {
	const { currency } = useCurrencyStore();
	const { data: cart, isLoading: previewIsLoading } = useUserCartItems();
	const { mutateAsync, isLoading: isClearingCart } = useClearCart();

	const clearCart = async () => {
		await mutateAsync();
	};

	if (previewIsLoading) {
		return <LoadingCart />;
	}

	return (
		<Container>
			<PaddingX>
				<div className="max-w-7xl mx-auto py-6 sm:py-8 min-h-[calc(100vh-200px)]">
					{!cart?.cart_items?.length ? (
						<div className="h-full flex items-center justify-center">
							<Empty cartIconSize={150}>
								<div className="flex flex-col items-center gap-4">
									<h2 className="text-2xl font-semibold text-gray-800">
										Your cart is empty
									</h2>
									<p className="text-center text-gray-600">
										Start adding some books to your cart!
									</p>
									<Link
										to="/books"
										className="bg-akgreener hover:bg-akgreener/90 transition-colors px-8 py-3 
											text-base text-white rounded-full flex items-center gap-2"
									>
										<BsCart3 size={20} />
										Browse Books
									</Link>
								</div>
							</Empty>
						</div>
					) : (
						<div className="space-y-8">
							{/* Header */}
							<div className="flex flex-row justify-between items-start sm:items-center gap-4">
								<div>
									<h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-1 sm:mb-2">
										Shopping Cart
									</h1>
									<p className="text-sm sm:text-base text-gray-600">
										{cart?.cart_items?.length}{" "}
										{cart?.cart_items?.length === 1 ? "item" : "items"} in your
										cart
									</p>
								</div>

								<button
									type="button"
									className="min-w-max bg-white border-2 border-akgreener text-akgreener 
										rounded-full px-4 sm:px-6 py-2 sm:py-2.5 transition-all duration-200 
										hover:bg-akgreener hover:text-white flex items-center justify-center gap-2 
										font-medium focus:outline-none focus:ring-2 focus:ring-akgreener focus:ring-offset-2"
									onClick={clearCart}
									disabled={isClearingCart}
								>
									{isClearingCart ? (
										<>
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
											Clearing...
										</>
									) : (
										"Clear cart"
									)}
								</button>
							</div>

							{/* Cart Content */}

							<div className=" grid grid-cols-1 lg:grid-cols-12 gap-6 lg:gap-12">
								{/* Cart Items */}
								<div className="divide-y divide-gray-100 col-span-12 lg:col-span-8 space-y-4">
									{cart?.cart_items?.map((cart) => (
										<CartItem
											key={cart.id}
											title={cart?.book_title}
											coverUrl={cart?.book_cover?.url}
											author={cart?.author}
											quantity={cart?.quantity}
											book={cart?.book}
											format={cart?.format}
											currencySymbol={cart?.currency_symbol}
											cartId={cart?.id}
										/>
									))}
								</div>

								{/* Order Summary */}
								<div className="col-span-12 lg:col-span-4">
									<OrderSummary
										cart={cart}
										currency={currency}
									/>
								</div>
							</div>

							{/* Continue Shopping Link */}
							<div className="text-center">
								<Link
									to="/books"
									className="text-akgreener hover:text-akgreener/80 font-medium 
										inline-flex items-center gap-2 py-2"
								>
									Continue shopping
								</Link>
							</div>
						</div>
					)}
				</div>
			</PaddingX>
		</Container>
	);
};

export default Cart;

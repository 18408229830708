export const paginationParams = ["page", "rowsPerPage", "firstPage"];

export const searchingParams = ["search"];

export const filteringParams = ["fromDate", "toDate", "orderStatus"];

/**
 * @typedef {Object} FormData
 * @property {string} search
 * @property {string} roleIs
 * @property {string} dateFrom
 * @property {string} dateTo
 * @property {string} trashed
 * @property {string} publisherIs
 * @property {string} institutionIs
 * @property {string} subjectGroupIs
 */

/** @type {FormData} formData */
export const formData = [...searchingParams, ...filteringParams].reduce(
	(acc, key) => {
		acc[key] = "";
		return acc;
	},
	{}
);

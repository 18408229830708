import { useEffect, useState } from "react";

const useInitialQueryData = (filters, searchQueryData) => {
	const [filterValues, setFilterValues] = useState(searchQueryData);

	useEffect(() => {
		const newFilterValues = {};
		Object.keys(filterValues).forEach((key) => {
			newFilterValues[key] = filters[key] || "";
		});
		setFilterValues(newFilterValues);

		return () => {
			Object.keys(filterValues).forEach((key) => {
				newFilterValues[key] = "";
			});
			setFilterValues(newFilterValues);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	return { filterValues, setFilterValues };
};

export default useInitialQueryData;

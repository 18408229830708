import { FiX } from "react-icons/fi";
import PortalSearchInput from "components/v2/PortalSearchInput";
import useInitialQueryData from "./useInitialQueryData";

/**
 * @typedef {Object} SearchProps
 * @property {Object} filters
 * @property {Object} formData
 *
 * @param {SearchProps} props
 */
const Search = (props) => {
	const { filters, formData } = props;

	const { filterValues, setFilterValues } = useInitialQueryData(
		filters,
		formData
	);

	const changeInputValue = (e) => {
		const value = e.target?.value;
		setFilterValues((prev) => ({ ...prev, [e.target.name]: value }));
	};

	const handleSearch = (e) => {
		e.preventDefault();
		filters?.changeAllFiltersAtOnce(filterValues);
	};

	const clearSearchTerm = () => {
		setFilterValues({ ...filterValues, search: "" });
		if (filters?.search) {
			filters?.changeAllFiltersAtOnce({ ...filterValues, search: "" });
		}
	};

	return (
		<form
			className="relative w-full"
			onSubmit={handleSearch}
		>
			<PortalSearchInput
				name="search"
				type="text"
				value={filterValues.search}
				onChange={changeInputValue}
				placeholder="Search title, author, ISBN, keyword, etc..."
			/>

			<div
				className={`flex items-center gap-3 absolute right-0 top-0 ${
					filterValues?.search ? "inline" : "hidden"
				}`}
			>
				<button
					type="button"
					onClick={clearSearchTerm}
				>
					<FiX size={20} />
				</button>
				<button
					type="submit"
					className={` bg-akgreener text-white h-11 rounded-r-lg px-4  `}
				>
					Search
				</button>
			</div>
		</form>
	);
};

export default Search;

import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import WavyLoader from "components/v2/WavyLoader";

const SubscriptionsPageLoader = () => {
	return (
		<Container className="min-h-screen flex flex-col w-full bg-gradient-to-b from-white to-gray-50">
			<div className="mt-16 mb-20">
				{/* Title loader */}
				<div className="mb-12 text-center">
					<WavyLoader
						width={300}
						height={40}
						className="mx-auto"
					/>
				</div>

				<PaddingX>
					<div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-center gap-8 lg:gap-12 max-w-6xl mx-auto">
						{/* Create two subscription card loaders */}
						{[1, 2].map((index) => (
							<div
								key={index}
								className="rounded-3xl border-2 border-gray-100 bg-white text-center space-y-8 py-12 px-8 mx-auto lg:mx-0 w-full max-w-md"
							>
								{/* Subscription name loader */}
								<div>
									<WavyLoader
										width={200}
										height={32}
										className="mx-auto"
									/>
								</div>

								{/* Price section loader */}
								<div className="flex flex-col justify-center items-center space-y-3">
									<WavyLoader
										width={150}
										height={40}
										className="mx-auto"
									/>
									<div className="flex items-center gap-2">
										<WavyLoader
											width={100}
											height={24}
										/>
									</div>
								</div>

								{/* Features section loader */}
								<div className="space-y-4 py-6 border-t border-b border-gray-100">
									<WavyLoader
										width={250}
										height={24}
										className="mx-auto"
									/>
									<WavyLoader
										width={200}
										height={24}
										className="mx-auto"
									/>
									<div className="flex justify-center">
										<WavyLoader
											width={40}
											height={40}
											className="rounded-full"
										/>
									</div>
								</div>

								{/* Action button loader */}
								<div>
									<WavyLoader
										width={200}
										height={48}
										className="mx-auto rounded-full"
									/>
								</div>
							</div>
						))}
					</div>
				</PaddingX>
			</div>
		</Container>
	);
};

export default SubscriptionsPageLoader;

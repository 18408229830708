// import useAuthStore from "store/authStore";

import { useCallback, useEffect, useState } from "react";
import { useUserFilterContext } from "./UserFilterContext";
import useInitialQueryData from "hooks/useInitialQueryData";
import { formData } from "./userQueryParams";
import { BiMenuAltRight } from "react-icons/bi";
import { Sidebar } from "primereact/sidebar";
import useViewableRoles from "hooks/useViewableRoles";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import useSelectInstitution from "./useSelectInstitution";
import Select from "react-select";
import { delay } from "utils";

/**
 * @typedef {Object} UsersFilterProps
 * @property {number} numberOfActiveFilters
 *
 * @param {UsersFilterProps} props
 */

const UsersFilter = (props) => {
	const { numberOfActiveFilters = 0 } = props;

	const [searchTerm, setSearchTerm] = useState("");

	const {
		data,
		isFetching,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		refetch,
	} = useSelectInstitution({
		search: searchTerm,
	});

	const institutions = data?.pages?.flatMap((page) => page.records) || [];

	const debouncedSearch = useCallback((value) => {
		delay(2, () => {
			setSearchTerm(value);
			refetch();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [institutionSelectValue, setInstitutionSelectValue] = useState(null);

	const [visible, setVisible] = useState(false);

	const { roles } = useViewableRoles();

	const { filters } = useUserFilterContext();

	const { filterValues, setFilterValues } = useInitialQueryData(
		filters,
		formData
	);

	useEffect(() => {
		if (filters?.institutionIs && institutions?.length > 0) {
			const filtered = institutions?.find(
				(institution) => institution.id === filters?.institutionIs
			);
			setInstitutionSelectValue(filtered);
		} else {
			setInstitutionSelectValue(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters?.institutionIs]);

	const changeInputValue = (e) => {
		const value = e.target?.value;
		setFilterValues((prev) => ({ ...prev, [e.target.name]: value }));
		// filters?.changeAllFiltersAtOnce(updatedFilters);
		// setVisible(false);
	};

	const changeInput = (inputName, value) => {
		setFilterValues((prev) => ({ ...prev, [inputName]: value }));
		// filters?.changeAllFiltersAtOnce(updatedFilters);
		// setVisible(false);
	};

	const handleSelectInstitution = (institution) => {
		setInstitutionSelectValue(institution);
		return changeInput("institutionIs", institution?.id || "");
	};

	const handleApplyFilters = (e) => {
		e.preventDefault();
		filters?.changeAllFiltersAtOnce(filterValues);
		setVisible(false);
	};

	const handleClearFilters = () => {
		const params = filters?.allSearchParams
			?.map((param) => ({
				[param]: "",
			}))
			.reduce((acc, curr) => ({ ...acc, ...curr }), {});

		setFilterValues((prev) => ({ ...prev, ...params }));
		filters?.changeAllFiltersAtOnce(params);
		setVisible(false);
	};

	return (
		<>
			<button
				type="button"
				onClick={() => setVisible(!visible)}
				className="flex items-center gap-1 h-11 border border-gray-300 rounded-lg min-w-max px-4"
			>
				Advanced filter
				<BiMenuAltRight size={20} />
			</button>

			<Sidebar
				visible={visible}
				onHide={() => setVisible(false)}
				position="right"
				blockScroll
				className="max-w-max px-4 py-4 "
			>
				<form
					onSubmit={handleApplyFilters}
					className="mb-4 flex flex-col gap-3  py-4  "
					style={{
						height: "calc(100%-50px)",
					}}
				>
					<p className="font-bold">Filter users by the following</p>

					<select
						name="roleIs"
						id="roleIs"
						className={`${
							filterValues?.roleIs
								? "border-2 border-akgreener text-akgreener"
								: "border border-gray-300"
						}  bg-white rounded h-9 w-64 px-2`}
						onChange={changeInputValue}
						value={filterValues.roleIs}
					>
						<option
							label={
								filterValues?.roleIs ? "Clear filter [x]" : "- User role -"
							}
						></option>
						{roles?.map((role, index) => (
							<option
								key={index}
								value={role.value}
							>
								{role.label}
							</option>
						))}
					</select>

					<Calendar
						value={new Date(filterValues?.dateFrom)}
						name="dateFrom"
						dateFormat="yy-mm-dd"
						placeholder="From (yyy-mm-dd)"
						className="h-9 w-64 font-sans"
						inputClassName={`${
							filterValues?.dateFrom
								? "border-2 border-akgreener text-akgreener"
								: "border border-gray-300"
						}  bg-white rounded  px-2`}
						showIcon
						readOnlyInput
						showButtonBar
						onChange={(e) =>
							changeInput(
								"dateFrom",
								moment(e.target.value).format("YYYY-MM-DD")
							)
						}
						onClearButtonClick={() => changeInput("dateFrom", "")}
					/>

					<Calendar
						value={new Date(filterValues?.dateTo)}
						name="dateTo"
						dateFormat="yy-mm-dd"
						placeholder="To (yyyy-mm-dd)"
						className="h-9 w-64 font-sans"
						inputClassName={`${
							filterValues?.dateTo
								? "border-2 border-akgreener text-akgreener"
								: "border border-gray-300"
						}  bg-white rounded  px-2`}
						showIcon
						readOnlyInput
						showButtonBar
						onChange={(e) =>
							changeInput("dateTo", moment(e.target.value).format("YYYY-MM-DD"))
						}
						onClearButtonClick={() => changeInput("dateTo", "")}
					/>

					<Select
						isClearable
						isSearchable
						options={institutions}
						value={institutionSelectValue}
						getOptionLabel={(e) => e.name}
						getOptionValue={(e) => e.id}
						onMenuScrollToBottom={() => {
							if (hasNextPage && !isFetchingNextPage) {
								fetchNextPage();
							}
						}}
						defaultValue={institutionSelectValue}
						placeholder="Select an institution..."
						onChange={handleSelectInstitution}
						onInputChange={(val) => debouncedSearch(val)}
						isLoading={isFetching}
						className={`${
							institutionSelectValue
								? "border-2 border-akgreener text-akgreener rounded-md"
								: ""
						}`}
					/>

					{/* <AsyncSelect
						classNames={{
							control: (state) =>
								`${
									institutionSelectValue !== null
										? "border-2 border-akgreener text-akgreener"
										: "border border-gray-300"
								}  bg-white h-9 rounded w-64 `,
						}}
						className={`${
							institutionSelectValue
								? "border-2 border-akgreener text-akgreener rounded-md"
								: ""
						}`}
						isClearable
						backspaceRemovesValue
						value={institutionSelectValue}
						name="institutionIs"
						placeholder="Select institution..."
						defaultOptions
						loadOptions={institutionOptions}
						getOptionLabel={(e) => e.name}
						getOptionValue={(e) => e.id}
						onChange={handleSelectInstitution}
					/> */}

					<button
						type="submit"
						className="bg-akgreen flex items-center justify-center gap-2 text-white h-11 rounded-lg px-4"
					>
						Apply filters
					</button>

					{numberOfActiveFilters > 0 && (
						<button
							onClick={handleClearFilters}
							className="border border-akgreen flex items-center justify-center gap-2 h-11 rounded-lg px-4"
						>
							Clear filters
							<span className="flex items-center rounded-full justify-center w-8 h-8 border border-akgreen">
								{numberOfActiveFilters}
							</span>
						</button>
					)}
				</form>
			</Sidebar>
		</>
	);
};
export default UsersFilter;

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useUpdateUserPassword = (userId) => {
	// const history = useHistory();
	const { accessToken } = useTokenStore();

	async function update(obj) {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);

		const response = await instance.patch(
			`users/update-password/${userId}`,
			body
		);

		return response?.data;
	}

	return useMutation(update, {
		onSuccess: async () =>
			toast.success("User's password updated successfully"),
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useUpdateUserPassword;

import InitApi from "api/InitApi";
import { useMutation, useQueryClient } from "react-query";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { deleteEmptyObjects } from "utils";
import { useHistory } from "react-router-dom";
import useTokenStore from "store/tokenStore";

const useRetainBooksInPicks = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const history = useHistory();

	const submit = async (obj) => {
		const instance = await InitApi({ accessToken });

		const body = deleteEmptyObjects(obj);

		const response = await instance.post(`books/keep-in-picks`, body);
		await queryClient.invalidateQueries("picks");
		return response?.data;
	};

	return useMutation(submit, {
		onSuccess: async (body) => {
			toast.success(body.message);
			history.push("/my-library/picks");
		},

		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useRetainBooksInPicks;

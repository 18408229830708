import useMoveUserToTrash from "api/UserResource/useMoveUserToTrash";
import { ConfirmPopup } from "primereact/confirmpopup";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsExclamationDiamond } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";

const DeleteUser = ({ elementId, userId }) => {
	const [visible, setVisible] = React.useState(false);

	const { mutateAsync: remove, isLoading: isDeleting } = useMoveUserToTrash(
		userId,
		"permanent"
	);
	const removeAccount = async () => {
		await remove();
		setVisible(false);
	};

	return (
		<>
			<button
				type="button"
				className="appearance-none border-0"
				onClick={() => setVisible(true)}
				id={elementId}
			>
				{isDeleting ? (
					<AiOutlineLoading3Quarters
						size={20}
						className="animate-spin"
					/>
				) : (
					<FiTrash2 size={20} />
				)}
			</button>

			<ConfirmPopup
				target={document.getElementById(elementId)}
				visible={visible}
				onHide={() => setVisible(false)}
				className="font-sans text-sm"
				message={
					<div
						className="space-y-2"
						style={{ maxWidth: "400px" }}
					>
						<BsExclamationDiamond
							size={40}
							className="text-red-600"
						/>

						<p className="text-base font-bold">
							Do you want to permanently delete the user?
						</p>
						<p>
							Note that this action will result in the user losing their account
							and associated data. The account will not be recovered.
						</p>
					</div>
				}
				accept={removeAccount}
			/>
		</>
	);
};

export default DeleteUser;

import useProfile from "api/UserResource/useProfile";
import useAuthStore from "store/authStore";
import UpdatePublisher from "../UpdatePublisher";
import { Role } from "utils/server-constants/roles";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import AccountLayout from "../AccountLayout";

const PublisherDetails = () => {
	const { hasRoles } = useAuthStore();

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<Container>
			<div className="mt-10 flex-1">
				<PaddingX>
					<AccountLayout
						isLoading={isLoadingProfile}
						profile={profile?.data}
					>
						<div
							className="space-y-4"
							style={{ maxWidth: 500 }}
						>
							<div>
								{hasRoles([Role.SeniorPublisher]) && (
									<div>
										<UpdatePublisher
											publisherId={profile?.data?.publishers[0]?.id}
											name={profile?.data?.publishers[0]?.name}
											physicalAddress={
												profile?.data?.publishers[0]?.physical_address
											}
											focusArea={profile?.data?.publishers[0]?.focus_area}
											country={profile?.data?.publishers[0]?.country}
										/>
									</div>
								)}
							</div>
						</div>
					</AccountLayout>
				</PaddingX>
			</div>
		</Container>
	);

	// return (
	// 	<ProfileLayout
	// 		heading={heading}
	// 		isLoading={isLoadingProfile}
	// 		profile={profile?.data}
	// 	>
	// 		<div>
	// 			{hasRoles([Role.SENIOR_PUBLISHER]) && (
	// 				<div>
	// 					<UpdatePublisher
	// 						publisherId={profile?.data?.publishers[0]?.id}
	// 						name={profile?.data?.publishers[0]?.name}
	// 						physicalAddress={profile?.data?.publishers[0]?.physical_address}
	// 						focusArea={profile?.data?.publishers[0]?.focus_area}
	// 						country={profile?.data?.publishers[0]?.country}
	// 					/>
	// 				</div>
	// 			)}
	// 		</div>
	// 	</ProfileLayout>
	// );
};

export default PublisherDetails;

import useAuthStore from "store/authStore";
import { Role } from "utils/server-constants/roles";

const useViewableRoles = () => {
	const { roles: manageableRoles } = useAuthStore();

	const rolesMap = {
		[Role.SuperAdmin]: [
			Role.SuperAdmin,
			Role.Admin,
			Role.SeniorPublisher,
			Role.Publisher,
			Role.Institution,
			Role.Educator,
			Role.Student,
			Role.Individual,
			Role.Ba,
		],
		[Role.Admin]: [
			Role.Admin,
			Role.SeniorPublisher,
			Role.Publisher,
			Role.Institution,
			Role.Educator,
			Role.Student,
			Role.Individual,
			Role.Ba,
		],
		[Role.SeniorPublisher]: [Role.SeniorPublisher, Role.Publisher],
		[Role.Publisher]: [Role.Publisher],
		[Role.Institution]: [Role.Institution, Role.Educator, Role.Student],
		[Role.Educator]: [Role.Student],
		[Role.Ba]: [Role.Individual, Role.Ba],
	};

	/** @type {Array<{label:string, value:string|number}>} roles */
	const roles = manageableRoles
		? rolesMap[manageableRoles[0]]?.map((role) => ({
				label: role,
				value: role,
		  }))
		: null;

	return { roles };
};

export default useViewableRoles;

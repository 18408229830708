import { useEffect, useState } from "react";
import { ButtonLoader } from "components";
import axios from "axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PickOnMap from "./PickOnMap";
import SuggestionItem from "./SuggestionItem";

const vainonaAddressId = "ChIJmevAQNqvMRkRS1D0xQ8l7GM";
const GOOGLE_API = process.env.REACT_APP_GOOGLE_API_KEY;

export const googleIsAvailable = () => {
	if (typeof window !== "undefined" && window.google?.maps?.Map) {
		return true;
	}
	return false;
};

const AutoComplete = ({ address, setAddress }) => {
	const [googleAvailable, setGoogleAvailable] = useState(false);
	const [loadingCurrent, setLoadingCurrent] = useState(false);
	const [loadingPlaces, setLoadingPlaces] = useState(false);
	const [query, setQuery] = useState("");
	const [location, setLocation] = useState(null);
	const [suggestions, setSuggestions] = useState([]);
	const [sessionToken, setSessionToken] = useState("");
	const [checkWindow, setCheckWindow] = useState(0);

	const [error, setError] = useState("");
	const [pickOnMap, setPickOnMap] = useState(false);

	useEffect(() => {
		function _check() {
			if (googleIsAvailable()) {
				setGoogleAvailable(true);
			} else {
				setCheckWindow(checkWindow + 1);
			}
		}

		const timeout = setTimeout(() => {
			_check();
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [checkWindow]);

	useEffect(() => {
		if (googleAvailable) {
			console.log("Google available");

			const token = new window.google.maps.places.AutocompleteSessionToken();
			setSessionToken(token);
		}
	}, [googleAvailable]);

	useEffect(() => {
		if (address) {
			axios
				.post(
					"https://routes.googleapis.com/directions/v2:computeRoutes",
					{
						origin: {
							placeId: vainonaAddressId,
						},
						destination: {
							placeId: address.id,
						},

						travelMode: "DRIVE",
						routingPreference: "TRAFFIC_AWARE",
						computeAlternativeRoutes: false,
						routeModifiers: {
							avoidTolls: false,
							avoidHighways: false,
							avoidFerries: false,
						},
						languageCode: "en-US",
						units: "IMPERIAL",
					},
					{
						headers: {
							"Content-Type": "application/json",
							"X-Goog-Api-Key": GOOGLE_API,
							"X-Goog-FieldMask": "routes.duration,routes.distanceMeters",
						},
					}
				)
				.then(({ data }) => {
					const distanceMeters = data.routes[0].distanceMeters;
					const duration = data.routes[0].duration;

					setAddress({ ...address, distanceMeters, duration });
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [address?.id]);

	// useEffect(() => {
	//   if (query && sessionToken) {
	//     if (autoComplete) {
	//       autoComplete.getPlacePredictions(
	//         {
	//           input: query,
	//           componentRestrictions: { country: ["ZW"] },
	//           sessionToken: sessionToken,
	//         },
	//         (predictions, status) => {
	//           if (predictions?.length > 0) setSuggestions([...predictions]);
	//         }
	//       );
	//     }
	//   } else {
	//     setSuggestions([]);
	//   }
	// }, [query, sessionToken, autoComplete]);

	function onChange(event) {
		setSuggestions([]);
		const value = event.target.value;
		setQuery(value);
	}

	const _onSelect = async (value) => {
		if (value.addressComponents && value.location) {
			_fixLocation(value);
		} else {
			const place = await _getPlaceDetails(value.placeId);

			_fixLocation(place);
		}
	};

	function _onUseCurrentLocation() {
		if (navigator.geolocation && !location) {
			setLoadingCurrent(true);
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const latitude = position.coords.latitude;
					const longitude = position.coords.longitude;

					const geocoder = new window.google.maps.Geocoder();

					geocoder.geocode(
						{
							location: { lat: latitude, lng: longitude },
						},
						(results, status) => {
							if (status === "OK") {
								_onSelect({ ...results[0], placeId: results[0].place_id });
							} else {
								console.log("An arror occurred in decoding current location");
							}
						}
					);

					setError("");
				},
				() => {
					setLoadingCurrent(false);
					setLocation(null);

					setError(
						"Failed to get your location. Make sure you allow location access."
					);
				}
			);
		}
	}

	useEffect(() => {
		if (query) {
			setAddress(null);
			_getSuggestion(query).then((res) => {
				setSuggestions(res);
			});
		}
	}, [query]);

	async function _getSuggestion(query) {
		setLoadingPlaces(true);
		try {
			const { data } = await axios.post(
				"https://places.googleapis.com/v1/places:autocomplete",
				{
					input: query,
					includedRegionCodes: ["zw"],
				},
				{
					headers: {
						"Content-Type": "application/json",
						"X-Goog-Api-Key": GOOGLE_API,
						"X-Goog-FieldMask": "*",
					},
				}
			);

			setLoadingPlaces(false);
			return data?.suggestions?.map((item) => item.placePrediction) || [];
		} catch (error) {
			setLoadingPlaces(false);
			return [];
		}
	}

	async function _getPlaceDetails(placeId) {
		try {
			const { data } = await axios.get(
				"https://places.googleapis.com/v1/places/" + placeId,

				{
					headers: {
						"Content-Type": "application/json",
						"X-Goog-Api-Key": GOOGLE_API,
						"X-Goog-FieldMask":
							"id,formattedAddress,addressComponents,googleMapsUri,location",
					},
				}
			);

			return data;
		} catch (error) {
			return [];
		}
	}

	function _pickedLocation(coordinates) {
		const geocoder = new window.google.maps.Geocoder();

		geocoder.geocode(
			{
				location: coordinates,
			},
			(results, status) => {
				if (status === "OK") {
					_onSelect({ ...results[0], placeId: results[0].place_id });
				} else {
					console.log("An arror occurred in decoding current location");
				}
			}
		);
	}

	function _fixLocation(place) {
		const data = {
			id: place.id,
			googleMapsUri: place.googleMapsUri,
			street_address: "",
			...place.location,
		};

		data.address = place.formattedAddress;

		for (const component of place.addressComponents) {
			if (component.types.includes("sublocality")) {
				data.suburb = component.longText;
			} else if (component.types.includes("locality")) {
				data.city = component.longText;
			} else if (component.types.includes("country")) {
				data.country_name = component.longText;
				data.country_code = component.shortText;
			}
		}

		data.street_address = data.address?.split(",")[0];

		setPickOnMap(false);
		setLoadingCurrent(false);
		setQuery("");
		setSuggestions([]);
		setLocation(data);
		setAddress(data);
	}

	return (
		<div className="">
			<input
				value={query || location?.street_address || ""}
				name="Query"
				onChange={onChange}
				placeholder="Street Address"
				className="w-full rounded-lg bg-gray-100 border-0 text-sm text-gray-600 px-2 h-10 "
			/>

			{!suggestions?.length && (
				<div className="flex items-center justify-between ">
					<button
						type="button"
						onClick={() => {
							setPickOnMap(true);
						}}
						className="p-1 px-2"
					>
						Pick on map
					</button>

					<button
						disabled={loadingCurrent}
						type="button"
						onClick={(e) => {
							_onUseCurrentLocation();
						}}
						className="p-1 px-2 flex items-center gap-2"
					>
						Use my current location{" "}
						{loadingCurrent && (
							<AiOutlineLoading3Quarters
								className="animate-spin"
								size={20}
							/>
						)}
					</button>
				</div>
			)}

			{(query || loadingPlaces || suggestions?.length > 0) && (
				<div className="mt-2 border rounded-lg p-3">
					{query && !loadingPlaces && suggestions?.length === 0 && (
						<div className="w-full flex flex-col items-center py-5">
							<div className="text-black">No suggestions found</div>
						</div>
					)}

					{loadingPlaces && (
						<div className="w-full flex justify-center py-5">
							<ButtonLoader size={40} />
						</div>
					)}
					{suggestions?.map((item, index) => (
						<SuggestionItem
							key={index}
							location={item}
							onSelect={() => _onSelect(item)}
							className="flex w-full  py-[12px]   text-[15px] cursor-pointer   border-b-2 border-gray2 "
						/>
					))}
				</div>
			)}

			<PickOnMap
				visible={pickOnMap}
				_onConfirm={_pickedLocation}
				setVisible={setPickOnMap}
			/>

			<div id="map" />
		</div>
	);
};

// const SuggestionItem = ({ location, onSelect }) => {
// 	return (
// 		<button
// 			type="button"
// 			className="flex w-full  py-2  text-sm cursor-pointer  hover:bg-[#0000000F] border-b-2  "
// 			onClick={onSelect}
// 		>
// 			<MdLocationOn className="mr-2 text-lg text-[#cccccc]" />

// 			{location.structuredFormat ? (
// 				<div>
// 					<p className="text-sm text-left font-semibold leading-tight">
// 						{location.structuredFormat.mainText?.text}
// 					</p>
// 					<p className="text-xs text-left leading-tight">
// 						{location.structuredFormat.secondaryText?.text}
// 					</p>
// 				</div>
// 			) : (
// 				<div>
// 					<p className="text-sm text-left font-semibold leading-tight">
// 						{location.text?.text}
// 					</p>
// 				</div>
// 			)}
// 		</button>
// 	);
// };

export default AutoComplete;

import useUpdateUserPermissions from "api/UserResource/useUpdateUserPermissions";
import { Form, Formik } from "formik";
import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";

/**
 * @param {Object} props
 * @param {string|number} props.id
 * @param {string[]} props.permissions
 * @param {Array<{id:string, name:string}>} props.allPermissions
 */
const Permissions = (props) => {
	const { id, permissions, allPermissions } = props;

	const [selectOptions, setSelectOptions] = useState("default");

	const initialValues = {
		permission_names: permissions || [],
	};

	const { mutateAsync, isLoading } = useUpdateUserPermissions(id);

	async function handleUpdatePermissions(data) {
		await mutateAsync(data);
	}

	return (
		<div>
			<div>
				<h4 className="">Manage permissions</h4>
				<p
					className="text-gray-500"
					style={{ maxWidth: 500 }}
				>
					Control what this user can do on the platform. Be careful with these
					settings as they can affect the user's ability to perform certain
					actions. If you are unsure, please contact the administrator.
				</p>
			</div>

			<Formik
				initialValues={initialValues}
				onSubmit={handleUpdatePermissions}
			>
				{({
					values,
					errors,
					touched,
					setFieldValue,
					handleChange,
					handleBlur,
				}) => {
					const handleSelectAll = () => {
						setSelectOptions("all");
						let all = allPermissions?.map((permission) => permission?.name);
						setFieldValue("permission_names", [...all]);
					};

					const handleSelectDefault = () => {
						setSelectOptions("default");
						setFieldValue("permission_names", [...permissions]);
					};

					const handleDeselectAll = () => {
						setSelectOptions("none");
						setFieldValue("permission_names", []);
					};

					return (
						<Form className="space-y-6 py-6 mt-6 border-t">
							<div className="space-y-4">
								<div className="flex justify-between gap-2">
									<div className="flex gap-2">
										<button
											className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
											type="button"
											onClick={handleSelectAll}
										>
											Select all
											{selectOptions === "all" && <BsCheckCircle size={20} />}
										</button>

										<button
											className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
											type="button"
											onClick={handleDeselectAll}
										>
											Deselect all
											{selectOptions === "none" && <BsCheckCircle size={20} />}
										</button>
									</div>

									<button
										className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
										type="button"
										onClick={handleSelectDefault}
									>
										Select assigned
										{selectOptions === "default" && <BsCheckCircle size={20} />}
									</button>
								</div>

								<div className="grid grid-cols-5 gap-4">
									{allPermissions?.map((permission) => (
										<div
											className="flex gap-2 items-center"
											key={permission.id}
										>
											<Checkbox
												inputId={permission.name}
												name="permission_names"
												value={permission.name}
												onChange={handleChange}
												checked={values.permission_names?.includes(
													permission.name
												)}
												onBlur={handleBlur}
											/>
											<label
												htmlFor={permission.name}
												className=" cursor-pointer text-xs "
											>
												{permission.label}
											</label>
										</div>
									))}
								</div>
							</div>
							<button
								type="submit"
								className="px-4 py-2 rounded-xl bg-akgreener text-white flex gap-1 items-center justify-center"
							>
								{isLoading ? (
									<>
										Updating{" "}
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									</>
								) : (
									<>
										Update permissions <IoIosArrowForward size={20} />
									</>
								)}
							</button>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default Permissions;

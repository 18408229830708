import { Container } from "layouts";
import useFetchSubscriptions from "api/SubscriptionsResource/useFetchSubscriptions";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import PaddingX from "layouts/PaddingX";
import useCurrencyStore from "store/currencyStore";
import { Link } from "react-router-dom";
import { activeCurrencies, getMoneyPrefix } from "utils";
import { Tooltip } from "primereact/tooltip";
import { BsInfoCircle, BsBook } from "react-icons/bs";
import SubscriptionsPageLoader from "./SubscriptionsPageLoader";
import { motion } from "framer-motion";
import { BiInfinite } from "react-icons/bi";

const Subscriptions = () => {
	const { currency } = useCurrencyStore();

	const { data: subscriptions, isLoading: isLoadingSubscriptions } =
		useFetchSubscriptions();

	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();

	const isLoading = isLoadingSubscribed || isLoadingSubscriptions;

	if (isLoading) {
		return <SubscriptionsPageLoader />;
	}

	return (
		<Container className="min-h-screen flex flex-col w-full bg-gradient-to-b from-white via-gray-50 to-white">
			<div className="relative overflow-hidden">
				{/* Decorative background elements */}
				<div className="absolute inset-0 bg-gradient-to-b from-akgreener/5 to-transparent" />
				<div className="absolute top-0 left-0 w-96 h-96 bg-akgreener/10 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2" />
				<div className="absolute top-0 right-0 w-96 h-96 bg-akgreenest/10 rounded-full filter blur-3xl translate-x-1/2 -translate-y-1/2" />

				<div className="relative mt-16 mb-20">
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.5 }}
						className="text-center mb-16"
					>
						<h1 className="text-4xl font-bold text-gray-800 mb-4">
							Choose your subscription
						</h1>
						<p className="text-gray-600 max-w-2xl mx-auto">
							Select the perfect plan for your reading journey. All plans
							include access to our extensive library of books.
						</p>
					</motion.div>

					<PaddingX>
						<div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-center gap-8 lg:gap-12 max-w-4xl mx-auto">
							{subscriptions?.data?.map((subscription, index) => {
								let label = null;
								if (subscription?.name.toLowerCase() === "weekly") {
									label = "(7 days)";
								} else if (subscription?.name.toLowerCase() === "monthly") {
									label = "(30 days)";
								}

								const hasActiveSubscription =
									Boolean(isSubscribed) &&
									Boolean(isSubscribed?.data?.id === subscription?.id);

								return (
									<motion.div
										key={subscription.id}
										initial={{ opacity: 0, y: 20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.2, delay: index * 0.1 }}
										className={`${
											isSubscribed?.data &&
											isSubscribed?.data?.id === subscription?.id
												? "border-4 shadow-xl transform scale-105"
												: "border-2 hover:border-3 hover:shadow-xl transition-all duration-200 hover:scale-110"
										} rounded-3xl border-akgreener hover:border-akgreenest bg-white text-center space-y-8 py-12 px-8 mx-auto md:mx-0 w-full max-w-md relative overflow-hidden`}
									>
										{hasActiveSubscription && (
											<div className="absolute top-4 right-4 bg-akgreenest text-white px-3 py-1 rounded-full text-sm font-medium">
												Current Plan
											</div>
										)}

										<div>
											<h2 className="capitalize font-bold text-2xl mb-2 text-gray-800">
												{subscription.name} {label}
											</h2>
										</div>

										<div className="flex flex-col justify-center items-center space-y-3">
											<div className="relative">
												<p className="flex items-center justify-center text-4xl font-bold text-akgreenest">
													<span>
														{getMoneyPrefix(currency)}&nbsp;
														{currency === activeCurrencies.USD.code
															? subscription?.cost
															: subscription?.zwl_price}
													</span>
												</p>
												{subscription?.name.toLowerCase() === "monthly" && (
													<div className="absolute -top-5 -right-2 bg-akgreener text-white text-xs px-2 py-1 rounded-full">
														Best Value
													</div>
												)}
											</div>

											<div className="flex items-center gap-2 text-gray-600">
												<span className="text-sm">Or</span>
												<span className="font-semibold text-lg flex items-center gap-1">
													<span className="text-yellow-500">★</span>{" "}
													{subscription?.credits} stars
												</span>
											</div>
										</div>

										<div className="space-y-4 py-6 border-t border-b border-gray-100">
											<div className="flex items-center justify-center gap-2 text-gray-700">
												<BsBook
													size={24}
													className="text-akgreener"
												/>
												<p className="font-medium">
													Access to{" "}
													<span className="font-bold text-akgreenest">
														{subscription?.max_number_of_books} books
													</span>
												</p>
											</div>
											<div className="flex items-center justify-center gap-2 text-gray-700">
												<BiInfinite
													size={24}
													className="text-akgreener"
												/>
												<p className="font-medium">Unlimited free titles</p>
											</div>

											<Tooltip
												target={`#pr-info-${subscription.id}`}
												position="top"
												className="light-tooltip text-center"
												autoHide={false}
											>
												<span>
													You can filter and explore titles <br /> available on
													subscription{" "}
													<Link
														to={`/books/?pricing_model_is=has_subscription`}
														className="font-bold text-blue-700 underline"
													>
														here
													</Link>
												</span>
											</Tooltip>
											<button
												id={`pr-info-${subscription.id}`}
												type="button"
												className="flex items-center justify-center p-1 border rounded-full mx-auto relative border-gray-200 hover:border-blue-300 hover:scale-105 transition-all duration-300"
											>
												<BsInfoCircle
													size={24}
													className="text-blue-600"
												/>
											</button>
										</div>

										<div>
											{hasActiveSubscription ? (
												<span className="py-3 px-6 text-sm bg-akgreenest rounded-full text-white inline-flex items-center justify-center font-medium">
													Your current subscription
												</span>
											) : (
												<Link
													to={`/subscription/pay/${subscription.id}`}
													className="py-4 px-8 rounded-full bg-gradient-to-r from-akgreener to-akgreenest text-white text-lg font-medium transition-all duration-300 inline-block hover:shadow-lg hover:scale-105 hover:from-akgreenest hover:to-akgreener"
												>
													Subscribe Now
												</Link>
											)}
										</div>
									</motion.div>
								);
							})}
						</div>
					</PaddingX>
				</div>
			</div>
		</Container>
	);
};

export default Subscriptions;

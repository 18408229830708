import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useEcocashPayBooks = () => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();

	const history = useHistory();
	const location = useLocation();
	const retryUrl = location.pathname;

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });

		const body = deleteEmptyObjects(obj);

		const response = await instance.post(`/orders/create`, body, {
			timeout: 10000000,
			timeoutErrorMessage: "Connection timed out...",
		});
		await queryClient.invalidateQueries("user_cart_items");
		await queryClient.invalidateQueries("user_cart_count");
		await queryClient.invalidateQueries("picks");
		await queryClient.invalidateQueries("purchased");
		return response?.data;
	};

	return useMutation(save, {
		onSuccess: async ({ data }) => {
			if (data?.status === "Failed") {
				const queryString = `retryUrl=${retryUrl}&order_id=${data?.id}&ecocash_reference=${data?.ecocash_reference}&reference=${data?.reference}&phone=${data?.phone}`;
				history.push(`/payment-response?${queryString}`);
			} else {
				history.push(
					`/payment-success-response?payment_method=${data?.payment_method}`
				);
			}
		},
		onError: async (error) => {
			const status = error?.response?.status;
			if (status === 422) {
				return toast.error(
					<ServerErrors
						errorMessage={error?.response?.data?.message}
						errors={error?.response?.data?.data}
					/>,
					{
						className: "max-w-max",
					}
				);
			}
			const errorMsg =
				error?.response?.data?.message || "An error occurred. Try again";
			const queryString = `retryUrl=${retryUrl}&payment_method=EcoCash&error=${errorMsg}`;
			history.push(`/payment-error-response?${queryString}`);
		},
	});
};

export default useEcocashPayBooks;

export const Role = Object.freeze({
	SuperAdmin: "Super Administrator",
	Admin: "Administrator",
	Ba: "Brand Ambassador",
	Publisher: "Publisher",
	SeniorPublisher: "Senior Publisher",
	Educator: "Educator",
	Institution: "Institution",
	Student: "Student",
	Individual: "Individual",
});

import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { FaAppStore, FaApple, FaUbuntu } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { SiHuawei, SiWindows } from "react-icons/si";

const mobileAppLinks = [
	{
		name: "Google Play Store",
		icon: <IoLogoGooglePlaystore size={40} />,
		url: "https://play.google.com/store/apps/details?id=com.akellolibrary",
	},
	{
		name: "App Store",
		icon: <FaAppStore size={40} />,
		url: "https://apps.apple.com/zw/app/akello-library/id6451323375",
	},
	{
		name: "Huawei App Gallery",
		icon: <SiHuawei size={40} />,
		url: "https://appgallery.huawei.com/app/C108363007",
	},
];

const desktopAppLinks = [
	{
		name: "Windows",
		icon: <SiWindows size={40} />,
		url:
			process.env.REACT_APP_WINDOWS_APP_LINK ||
			"https://library.akello.co/akello-windowsx64-installation-file.zip",
	},
	{
		name: "Linux",
		icon: <FaUbuntu size={40} />,
		url: "#",
	},
	{
		name: "Mac",
		icon: <FaApple size={40} />,
		url: "#",
	},
];

const Downloads = () => {
	return (
		<Container>
			<PaddingX>
				<div className="mt-10 lg:mt-16">
					<h1
						className="text-center opacity-75 font-bold text-2xl mx-auto"
						style={{ maxWidth: 500 }}
					>
						Get the application that suits your needs and start reading now
					</h1>

					<div className="flex flex-col sm:flex-row justify-center mt-3 gap-3">
						<div className="space-y-2 py-4">
							<h4 className="font-bold text-center">Download for mobile</h4>
							<div className="flex flex-col gap-3">
								{mobileAppLinks.map((link) => (
									<a
										href={link.url}
										key={link.name}
										rel="noreferrer noopener"
										target="_blank"
									>
										<div
											className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gradient-to-t from-gray-300 via-gray-100 to-gray-50 border border-gray-300"
											style={{ minWidth: 250 }}
										>
											{link.icon}
											<span className="flex flex-col items-start justify-center">
												<span className="mb-0">Download on</span>
												<span className="font-bold text-base">{link.name}</span>
											</span>
										</div>
									</a>
								))}
							</div>
						</div>

						<div className="space-y-2 py-4">
							<h4 className="font-bold text-center">Download for desktop</h4>
							<div className="flex flex-col gap-3">
								{desktopAppLinks.map((link) => (
									<a
										href={link.url}
										key={link.name}
										rel="noreferrer noopener"
										target={link.url === "#" ? "_self" : "_blank"}
									>
										<div
											className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gradient-to-t from-gray-300 via-gray-100 to-gray-50 border border-gray-300  "
											style={{ minWidth: 250 }}
										>
											{link.icon}
											<span className="flex flex-col items-start justify-center">
												<span className="mb-0">Download for</span>
												<div className="flex gap-1 items-center">
													<span className="font-bold text-base">
														{link.name}
													</span>
													{link.url === "#" && (
														<span className="text-blue-900 text-xs">
															- Coming soon!
														</span>
													)}
												</div>
											</span>
										</div>
									</a>
								))}
							</div>
						</div>
					</div>
				</div>
			</PaddingX>
		</Container>
	);
};

export default Downloads;

import useBooksInSubscriptionCollection from "api/BookResource/useBooksInSubscriptionCollection";
import { BookListItem, CustomPaginator } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { useMemo } from "react";
import useMyLibraryFilters from "../useMyLibraryFilters";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import BooksLoader from "pages2/Books/BooksLoader";
import { Link, useLocation } from "react-router-dom";
import { IoDocumentsOutline } from "react-icons/io5";
import MyLibraryLayout from "../MyLibraryLayout";
import BookPaginationLoader from "pages2/Books/BookPaginationLoader";

const MyPicks = () => {
	const filters = useMyLibraryFilters();

	const { pathname } = useLocation();

	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();

	const { data: subscribedBooks, isLoading: isLoadingSubscribedBooks } =
		useBooksInSubscriptionCollection({
			page: filters?.page,
			rows_per_page: filters?.rowsPerPage,
		});

	const disableRowsPerPageChanger = useMemo(() => {
		if (
			parseInt(filters?.page) ===
				parseInt(subscribedBooks?.data?.total_pages) &&
			parseInt(filters?.page) !== 1
		) {
			return true;
		}
		return false;
	}, [filters?.page, subscribedBooks]);

	const handleChangeRowsPerPage = (value) => {
		if (value === filters?.rowsPerPage) return;
		filters.changeRowsPerPage(value);
	};

	return (
		<Container>
			<div className="mt-2">
				<PaddingX>
					<MyLibraryLayout>
						{(function () {
							if (isLoadingSubscribedBooks || isLoadingSubscribed) {
								return (
									<>
										<div className="pt-5">
											<BookPaginationLoader />
										</div>

										<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-4 gap-y-6 place-items-stretch mt-4">
											{Array.from({ length: 18 }, (_, i) => (
												<BooksLoader key={i} />
											))}
										</div>
									</>
								);
							}

							return (
								<>
									<div className="flex justify-between items-center pt-2">
										<div className="hidden xs:block">
											<p className="text-base sm:text-lg">
												Showing your picks - page {filters?.page} of{" "}
												{subscribedBooks?.data?.total_pages} page(s)
											</p>
										</div>

										<span className="flex items-center justify-end xs:hidden ">
											<Link
												to="/my-library"
												className={`py-2 px-2 border border-akgreener rounded-l-full ${
													pathname === "/my-library"
														? "bg-akgreener text-white"
														: ""
												} `}
											>
												Purchased
											</Link>
											<Link
												to="/my-library/picks"
												className={`py-2 px-2 border border-akgreener rounded-r-full ${
													pathname === "/my-library/picks"
														? "bg-akgreener text-white"
														: ""
												}`}
											>
												Picked
											</Link>
										</span>

										<div className="flex items-center justify-end gap-2 ">
											<span>Show</span>

											<select
												name="rowsPerPage"
												id="rowsPerPage"
												value={filters.rowsPerPage}
												onChange={(e) =>
													handleChangeRowsPerPage(e.target.value)
												}
												disabled={disableRowsPerPageChanger}
												className="border border-gray-300 bg-white rounded h-10 px-2"
											>
												<option value="10">10</option>
												<option value="20">20</option>
												<option value="40">40</option>
												<option value="50">50</option>
											</select>
										</div>
									</div>

									<div className="mt-4">
										{(function () {
											if (subscribedBooks?.data?.records?.length === 0) {
												return (
													<div className="flex flex-col items-center justify-center gap-4">
														<IoDocumentsOutline
															size={200}
															className="text-green-200"
														/>

														<div className="flex flex-col items-center gap-x-20">
															<h4 className="text-center">
																You have not added any picks yet
															</h4>
															<div className="flex justify-center divide-x-2 divide-akgreener tex-base font-bold">
																<Link
																	to="/books"
																	className="text-akgreener px-3 py-2 text-center"
																>
																	Browse books to <br />
																	add picks
																</Link>

																<Link
																	to="/my-library"
																	className="text-akgreener px-3 py-2 text-center"
																>
																	View purchased <br /> books
																</Link>
															</div>
														</div>

														<div
															className="text-center mt-2 pt-2 border-t px-2 space-y-1"
															style={{ maxWidth: 330 }}
														>
															<p>Want to add some books to picks?</p>
															<p className="text-xs">
																First you need to have an active subscription.
																Browse books and click the book you want to add
																to picks. On the details page, click the "Add to
																picks" button.
															</p>
														</div>
													</div>
												);
											}

											return (
												<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-4 gap-y-6 ">
													{subscribedBooks?.data?.records?.map(
														(subscribedBook) => (
															<BookListItem
																key={subscribedBook.id}
																coverUrl={subscribedBook?.book_cover?.url}
																title={subscribedBook?.title}
																author={subscribedBook?.author}
																description={subscribedBook?.description}
																price={subscribedBook?.price}
																zwl_price={subscribedBook?.zwl_price}
																credits={subscribedBook.credits}
																publisher={
																	subscribedBook?.user?.publishers[0]?.name ||
																	`${subscribedBook?.user?.first_name} ${subscribedBook?.user?.last_name}`
																}
																reviews={subscribedBook.ratings}
																bookId={subscribedBook.id}
																sellable={subscribedBook.sellable}
																rating={subscribedBook.rating}
																isbn={subscribedBook.rating}
																isPurchased={subscribedBook.is_purchased}
																inLibrary={subscribedBook.in_library}
																subscription={subscribedBook.subscription}
																hasSubscription={isSubscribed?.data}
																category={subscribedBook?.category?.name}
																isFree={subscribedBook?.isFree}
																genres={subscribedBook?.genre?.map((genre) => [
																	genre.id,
																	genre.name,
																])}
															/>
														)
													)}
												</div>
											);
										})()}

										<div className="flex-1 flex items-end mt-6 border-t pt-6">
											<CustomPaginator
												firstPage={filters.firstPage}
												setFirstPage={filters.changeFirstPage}
												rowsPerPage={filters.rowsPerPage}
												setRowsPerPage={filters.changeRowsPerPage}
												totalRecords={subscribedBooks?.data?.total_records}
												setPage={filters.changePage}
												showRowsPerPageDropdown={false}
											/>
										</div>
									</div>
								</>
							);
						})()}
					</MyLibraryLayout>
				</PaddingX>
			</div>
		</Container>
	);
};

export default MyPicks;

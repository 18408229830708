import useBooks from "api/BookResource/useBooks";
import { CustomPaginator } from "components";
import Admin2 from "layouts/Admin2";
import { useState } from "react";
import useAuthStore from "store/authStore";
import BookCard from "./BookCard";
import BookFilter from "./BookFilter";
import { BsCheckAll, BsCloudDownload, BsX } from "react-icons/bs";
import useExportBooks from "api/BookResource/useExportBooks";
import { FaCircleNotch } from "react-icons/fa";
import AdminPageLoader from "components/AdminPageLoader";
import useGenres from "api/GenreResource/useGenres";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useFetchPublishers from "api/PublisherResource/useFetchPublishers";
import { Role } from "utils/server-constants/roles";
import { BookFilterProvider } from "./BookFilter/BookFilterContext";
import BulkActions from "./BulkActions";
import { useFormik } from "formik";
import ClearFilters from "admin/Filters/ClearFilters";
import RowsPerPage from "admin/Filters/RowsPerPage";
import { formData } from "./BookFilter/booksQueryParams";
import Search from "admin/Filters/Search";
import useBooksFilters from "./BookFilter/useBooksFilters";

const AllBooks2 = () => {
	const filters = useBooksFilters();
	const hasRoles = useAuthStore((state) => state.hasRoles);
	const [selectedBooks, setSelectedBooks] = useState([]);
	const [showGenerateBookCollection, setShowGenerateBookCollection] =
		useState(true);
	const [showDownloadBookCollection, setShowDownloadBookCollection] =
		useState(false);

	// Get number of active filters excluding pagination params
	const numberOfActiveFilters = filters?.allSearchParams
		?.filter((param) => !filters?.paginationParams?.includes(param))
		?.filter((param) => filters[param] !== "")?.length;

	// Data fetching hooks
	const { data: genres, isLoading: isLoadingGenres } = useGenres("yes");
	const { data: categories, isLoading: isLoadingCategories } =
		useFetchCategories("yes");
	const { data: publishers, isLoading: isLoadingPublishers } =
		useFetchPublishers();
	const { mutateAsync: mutateExport, isLoading: isExporting } =
		useExportBooks();

	// Fetch books with filters
	const { data: books, isLoading: isBooksLoading } = useBooks({
		page: filters.page,
		rows_per_page: filters.rowsPerPage,
		from_backend: true,
		"filter[is_active]": filters.isActive,
		"filter[category_is]": filters.categoryIs,
		"filter[genre_is]": filters.genreIs,
		"filter[isFree]": filters.isFree,
		"filter[publisher_is]": filters.publisherIs,
		"filter[pricing_model_is]": filters.pricingModelIs,
		without_file_collection: filters.withoutFileCollection,
		with_file_collection: filters.withFileCollection,
		searchTerm: filters.search,
	});

	const totalRecords = books?.data?.total_records || 0;

	// Form handling for bulk actions
	const { values, setFieldValue } = useFormik({
		initialValues: {
			books: [...selectedBooks],
			action: "",
		},
		enableReinitialize: true,
	});

	const handleGenerateBookCollection = async () => {
		await mutateExport();
		setShowGenerateBookCollection(false);
		setShowDownloadBookCollection(true);
	};

	const handleSelectAllBooks = () => {
		setSelectedBooks((prev) =>
			prev?.length <= 0 ? [...books?.data?.records?.map((book) => book.id)] : []
		);
	};

	if (
		isBooksLoading ||
		isLoadingCategories ||
		isLoadingGenres ||
		isLoadingPublishers
	) {
		return <AdminPageLoader />;
	}

	const renderBookActions = (book) => {
		if (!hasRoles([Role.SuperAdmin, Role.Admin, Role.SeniorPublisher])) {
			return null;
		}

		const isChecked = values.books.includes(book.id);
		const toggleChecked = () => {
			setFieldValue(
				"books",
				isChecked
					? values.books.filter((id) => id !== book.id)
					: [...values.books, book.id]
			);
		};

		return (
			<>
				<span
					className={`h-7 px-4 text-xs cursor-pointer border-akgreener border rounded-full ${
						isChecked ? "bg-akgreener text-white" : "bg-white"
					} absolute -top-2 -left-2 flex items-center justify-center`}
				>
					{isChecked ? "Deselect" : "Select"}
				</span>

				<input
					type="checkbox"
					name="books"
					hidden
					value={Number(book.id)}
					checked={isChecked}
					onChange={toggleChecked}
					id={`bookitem${book.id}`}
					className={`w-6 h-6 absolute -top-2 -left-1 ${
						!isChecked ? "border-2 border-akgreen" : ""
					}`}
				/>
			</>
		);
	};

	return (
		<Admin2 fullWidth>
			<BookFilterProvider>
				<div className="my-4 space-y-6 w-full">
					<h3 className="font-bold flex items-center justify-between gap-3 rounded-full max-w-max">
						<span>Books' list</span>
						<span className="block bg-gray-400 rounded-full text-white py-1 w-14 text-center">
							{totalRecords}
						</span>
					</h3>

					<div className="flex items-center gap-x-2 justify-between">
						<RowsPerPage
							filters={filters}
							formData={formData}
						/>
						<Search
							filters={filters}
							formData={formData}
						/>
						<ClearFilters
							numberOfActiveFilters={numberOfActiveFilters}
							filters={filters}
						/>
						<BookFilter
							genres={genres}
							categories={categories}
							publishers={publishers}
							numberOfActiveFilters={numberOfActiveFilters}
						/>
					</div>

					<div className="space-y-4">
						<form className="flex flex-col relative">
							{hasRoles([
								Role.SuperAdmin,
								Role.Admin,
								Role.SeniorPublisher,
							]) && (
								<div className="flex items-center gap-2 mb-4">
									{showGenerateBookCollection && (
										<button
											type="button"
											className="py-1 px-3 gap-2 h-11 border border-gray-300 rounded-md flex items-center justify-center"
											onClick={handleGenerateBookCollection}
										>
											Generate meta data of books
											{isExporting ? (
												<FaCircleNotch
													size={15}
													className="animate-spin"
												/>
											) : (
												<BsCloudDownload size={15} />
											)}
										</button>
									)}

									{showDownloadBookCollection && (
										<a
											href={`${process.env.REACT_APP_FILES_BASE_URL}/downloads/books_collection.xlsx`}
											onClick={() => {
												setShowDownloadBookCollection(false);
												setShowGenerateBookCollection(true);
											}}
											target="_blank"
											rel="noreferrer"
											className="py-1 px-3 h-11 gap-2 border border-gray-300 rounded-md flex items-center justify-center"
										>
											Download books collection
										</a>
									)}

									<label
										htmlFor="selectActions"
										className="flex items-center gap-1"
									>
										<input
											type="checkbox"
											name="select"
											hidden
											checked={
												selectedBooks?.length ===
													books?.data?.records?.length &&
												selectedBooks?.length > 0
											}
											onChange={handleSelectAllBooks}
											id="selectActions"
										/>
										<span
											className={`flex items-center gap-1 h-11 px-2 py-1 ${
												selectedBooks.length <= 0 ? "border" : "border-2"
											} border-gray-300 rounded cursor-pointer`}
										>
											{selectedBooks.length <= 0 ? (
												<>
													Select All <BsCheckAll size={20} />
												</>
											) : (
												<>
													Deselect All <BsX size={20} />
												</>
											)}
										</span>
									</label>

									{values?.books?.length > 0 && (
										<div>
											<BulkActions
												books={values?.books}
												resetBooks={() => {
													setFieldValue("books", []);
													setSelectedBooks([]);
												}}
											/>
										</div>
									)}
								</div>
							)}

							<div className="grid grid-cols-2 xl:grid-cols-3 gap-6 mt-2">
								{books?.data?.records?.map((book) => (
									<div key={book.id}>
										<label
											htmlFor={`bookitem${book.id}`}
											className="relative"
										>
											{renderBookActions(book)}
										</label>

										<BookCard
											bookId={book?.id}
											title={book?.title}
											author={book?.author}
											price={book?.price}
											zwlPrice={book?.zwl_price}
											isbn={book?.isbn}
											description={book?.description}
											sellable={book?.sellable}
											onSubscription={book?.subscription}
											isActive={book?.is_active}
											isFree={book?.isFree}
											credits={book?.credits}
											bookCover={book?.book_cover?.url}
											bookUrl={book?.book_file?.url}
											category={book?.category}
											genres={book?.genres[0]}
											hasFile={book?.has_book_file}
											publisher={book?.publisher}
											categories={categories}
											collections={genres}
											isAdmin={hasRoles([Role.SuperAdmin, Role.Admin])}
											format={book?.format}
											stockLevel={book?.stock_level}
											physicalUsdPrice={book?.physical_usd_price}
											physicalZwlPrice={book?.physical_zwl_price}
										/>
									</div>
								))}
							</div>
						</form>

						<div className="flex justify-center rounded-full mt-4">
							<CustomPaginator
								firstPage={filters?.firstPage}
								rowsPerPage={filters?.rowsPerPage}
								setFirstPage={(val) => filters?.changeFilter("firstPage", val)}
								setRowsPerPage={(val) =>
									filters?.changeFilter("rowsPerPage", val)
								}
								totalRecords={books?.data?.total_records}
								setPage={(val) => filters?.changeFilter("page", val)}
								showRowsPerPageDropdown={false}
							/>
						</div>
					</div>
				</div>
			</BookFilterProvider>
		</Admin2>
	);
};

export default AllBooks2;

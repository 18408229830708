import useCountUserCartItems from "api/CartResource/useCountUserCartItems";
import { GiShoppingCart } from "react-icons/gi";
import { Link } from "react-router-dom";

const Cart = () => {
	const { data: body } = useCountUserCartItems();

	return (
		<Link
			to="/cart"
			className="flex appearance-none"
		>
			<GiShoppingCart size={25} />
			<span className="h-6 w-6 rounded-full p-1 border border-akgreenest text-sm bg-white flex items-center justify-center -mx-1">
				{body?.data || 0}
			</span>
		</Link>
	);
};

export default Cart;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-green/theme.css";

import "primeicons/primeicons.css";

import "react-phone-input-2/lib/style.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./App.css";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();

import useFetchOrders from "api/OrderResource/useFetchOrders";
import Admin2 from "layouts/Admin2";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import ChangeStatus from "./ChangeStatus";
import { activeCurrencies, getPaymentMethodLabel } from "utils";
import useOrdersFilters from "./OrdersFilter/useOrdersFilters";
import { OrderFilterProvider } from "./OrdersFilter/OrderFilterContext";
import OrdersFilter from "./OrdersFilter";
// import Search from "./OrdersFilter/Search";
// import ClearFilters from "./OrdersFilter/ClearFilters";
// import RowsPerPage from "./OrdersFilter/RowsPerPage";
import { CustomPaginator } from "components";
import RowsPerPage from "admin/Filters/RowsPerPage";
import { formData } from "./OrdersFilter/ordersQueryParams";
import Search from "admin/Filters/Search";
import ClearFilters from "admin/Filters/ClearFilters";

const Orders = () => {
	const filters = useOrdersFilters();

	const { data: orders, isLoading: isLoadingOrders } = useFetchOrders({
		page: filters?.page,
		rows_per_page: filters?.rowsPerPage,
		from_date: filters?.fromDate,
		to_date: filters?.toDate,
		order_status: filters?.orderStatus,
		search: filters?.search,
	});

	const ordersList = orders?.data?.records;

	const totalRecords = orders?.data?.total_records || 0;

	const numberOfActiveFilters = filters?.allSearchParams
		?.filter((param) => !filters?.paginationParams?.includes(param))
		?.filter((param) => filters[param] !== "")?.length;

	const amountTemplate = (row) => {
		let amountLabel = "-";
		if (row?.grand_total && Number(row?.grand_total) > 0) {
			amountLabel = `${activeCurrencies.USD.label} $${Number(
				row?.grand_total
			)?.toFixed(2)}`;
		} else if (row?.grand_total_zwl) {
			amountLabel = `${activeCurrencies.ZWL.label} $${Number(
				row?.grand_total_zwl
			)?.toFixed(2)}`;
		}

		return <span className="text-base font-bold">{amountLabel}</span>;
	};

	const paymentMethodTemplate = (row) => {
		if (row?.payment_method?.startsWith("Ecocash")) {
			return (
				<div className="flex flex-col">
					<span>{getPaymentMethodLabel(row?.payment_method)}</span>
					<span className="text-xs font-bold">{row?.phone}</span>
				</div>
			);
		}
		return <span>{row?.payment_method}</span>;
	};

	const customerTemplate = (row) => (
		<div className="flex flex-col">
			<span className="font-bold">
				{row?.user?.first_name} {row?.user?.last_name}
			</span>
			<span>{row?.user?.email}</span>
		</div>
	);

	const statusTemplate = (row) => (
		<Tag
			rounded
			className="font-sans text-xs"
			severity={row.status.toLowerCase() === "completed" ? "warn" : "danger"}
			value={row?.status}
		/>
	);

	const actionTemplate = (row) => (
		<ChangeStatus
			orderId={row.id}
			currentOrderStatus={row.status}
			orderNumber={row.order_number}
		/>
	);

	return (
		<Admin2>
			<OrderFilterProvider>
				<div className="flex flex-col w-full mt-4">
					<DataTable
						loading={isLoadingOrders}
						value={ordersList}
						dataKey="id"
						header={
							<div className="space-y-4">
								<h3 className="font-bold flex items-center justify-between gap-3 rounded-full max-w-max">
									<span>Subscription and purchase orders</span>
									<span className="block bg-gray-400 rounded-full text-white py-1 w-14 text-center">
										{totalRecords}
									</span>
								</h3>
								<div className="flex items-center gap-2 justify-between ">
									<RowsPerPage
										filters={filters}
										formData={formData}
									/>
									<Search
										filters={filters}
										formData={formData}
									/>
									<ClearFilters
										filters={filters}
										numberOfActiveFilters={numberOfActiveFilters}
									/>
									<OrdersFilter numberOfActiveFilters={numberOfActiveFilters} />
								</div>
							</div>
						}
						tableClassName="font-sans text-sm w-full"
						autoLayout
					>
						<Column
							header="Customer"
							body={customerTemplate}
						/>
						<Column
							field="order_number"
							header="Order Number"
						/>
						<Column
							body={(row) => (
								<span className="text-akgreener">
									{row?.ecocash_reference
										? row?.ecocash_reference
										: row?.reference}
								</span>
							)}
							header="Reference"
						/>
						<Column
							header="Payment method"
							body={paymentMethodTemplate}
						/>
						<Column
							field="payment_type"
							header="Model"
						/>
						<Column
							header="Amount"
							body={amountTemplate}
						/>
						<Column
							field="created_at"
							header="Date created"
						/>
						<Column
							header="Status"
							body={statusTemplate}
						/>
						<Column
							header="Action"
							body={actionTemplate}
						/>
					</DataTable>

					<div className="flex-1 flex justify-center mt-6">
						<CustomPaginator
							firstPage={filters?.firstPage}
							setFirstPage={(val) => filters?.changeFilter("firstPage", val)}
							rowsPerPage={filters?.rowsPerPage}
							setRowsPerPage={(val) =>
								filters?.changeFilter("rowsPerPage", val)
							}
							totalRecords={totalRecords}
							setPage={(val) => filters?.changeFilter("page", val)}
							showRowsPerPageDropdown={false}
						/>
					</div>
				</div>
			</OrderFilterProvider>
		</Admin2>
	);
};

export default Orders;

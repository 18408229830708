import { Route, Switch, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Home from "pages2/Home";
import ContactUs from "pages2/ContactUs";
import Login from "pages2/Login";
import Register from "pages2/Register";
import PasswordResetOptions from "pages2/PasswordResetOptions";
import SendPasswordResetLink from "pages2/SendPasswordResetLink";
import ResetPassword from "pages2/ResetPassword";
import Profile from "pages2/Profile";
import Books from "pages2/Books";
import MyLibrary from "pages2/MyLibrary";
import BookPreview from "pages2/BookPreview";
import Subscriptions from "pages2/Subscriptions";
import Checkout from "pages2/Checkout";
import NotFound from "pages2/NotFound";
import Book from "pages2/Book";
import TermsAndConditions from "pages2/TermsAndConditions";
import PrivacyPolicy from "pages2/PrivacyPolicy";
import Support from "pages2/Support";

import Unauthorized from "pages2/Unauthorized";

import PortalProfile from "admin/UserProfile";
import MyUploadedBooks from "admin/Books";
import AllBooks from "admin/AllBooks2";
import InstitutionBooks from "admin/InstitutionDashboard/InstitutionBooks";
import Users from "admin/Users2";
import GenreCategory from "admin/Categories";
import AdminDashboard from "admin/AdminDashboard2";
import PublisherDashboard from "admin/PublisherDashboard2";
import InstitutionDashboard from "admin/InstitutionDashboard";

import InstitutionUsers from "admin/InstitutionUsers";
import PublisherUsers from "admin/PublisherUsers";

import SubjectGroups from "admin/SubjectGroups";

import RolesPermissions from "admin/RolesPermissions";

import CommandCenter from "admin/CommandCenter";

import useAuthStore from "store/authStore";
import Vouchers from "admin/Vouchers";
import PayPublisher from "admin/PublisherPayments/PayPublisher";
import AccountDeletionManual from "pages2/AccountDeletionManual";
import MyAccount from "pages2/MyAccount";
import SubscriptionCheckout from "pages2/SubscriptionCheckout";
import Orders from "admin/Orders";
import PaymentResponse from "pages2/PaymentResponse";
import PaymentSuccessResponse from "pages2/PaymentSuccessResponse";
import PaymentErrorResponse from "pages2/PaymentErrorResponse";
import SubscriptionSuccessResponse from "pages2/SubscritpionSuccessResponse";
import SubscriptionResponse from "pages2/SubscriptionResponse";
import SubscriptionErrorResponse from "pages2/SubscriptionErrorResponse";
import MyPicks from "pages2/MyLibrary/MyPicks";
import Emails from "pages2/MyAccount/Emails";
import Passwords from "pages2/MyAccount/Passwords";
import MySubscription from "pages2/MyAccount/MySubscription";
import PublisherDetails from "pages2/MyAccount/PublisherDetails";
import MyInstitution from "pages2/MyAccount/MyInstitution";
import PublisherPurchasedBooks from "admin/PublisherPurchasedBooks";
import Downloads from "pages2/Downloads";
import Publishers from "admin/Publishers";
import Exceptions from "admin/Exceptions";
import BrandAmbassadorDashboard from "admin/BrandAmbassadorDashboard";
import BaClientPayments from "admin/BaClientPayments";
import BrandAmbassadors from "admin/BrandAmbassadors";
import BrandAmbassadorPayouts from "admin/BrandAmbassadorPayouts";
import Institutions from "admin/Institutions";
import { Role } from "utils/server-constants/roles";
import { Permission } from "utils/server-constants/permissions";
import SsoLogin from "pages2/SsoLogin";
import AutoDeductions from "pages2/MyAccount/AutoDeductions";
import User from "admin/User";
import Cart from "pages2/Cart";
import MyOrders from "pages2/MyAccount/MyOrders";
import MyAddresses from "pages2/MyAccount/MyAddresses";

const Routes = () => {
	const location = useLocation();

	const isAuthenticated = useAuthStore((state) => state.loggedIn);
	const hasRoles = useAuthStore((state) => state.hasRoles);
	const hasPermissions = useAuthStore((state) => state.hasPermissions);

	const routes = [
		{
			path: "/",
			secure: false,
			component: Home,
			hasRight: true,
		},

		{
			path: "/sso-login",
			secure: false,
			component: SsoLogin,
			hasRight: true,
		},

		{
			path: "/my-account/emails",
			secure: true,
			component: Emails,
			hasRight: true,
		},

		{
			path: "/my-account/password",
			secure: true,
			component: Passwords,
			hasRight: true,
		},

		{
			path: "/my-account/subscriptions",
			secure: true,
			component: MySubscription,
			hasRight: true,
		},

		{
			path: "/my-account/publisher",
			secure: true,
			component: PublisherDetails,
			hasRight: true,
		},

		{
			path: "/my-account/institution",
			secure: true,
			component: MyInstitution,
			hasRight: true,
		},

		{
			path: "/my-account/orders",
			secure: true,
			component: MyOrders,
			hasRight: true,
		},

		{
			path: "/my-account/my-addresses",
			secure: true,
			component: MyAddresses,
			hasRight: true,
		},

		{
			path: "/my-account/auto-deductions",
			secure: true,
			component: AutoDeductions,
			hasRight: true,
		},

		{
			path: "/my-account",
			secure: true,
			component: MyAccount,
			hasRight: true,
		},

		{
			path: "/contact-us",
			secure: false,
			component: ContactUs,
			hasRight: true,
		},

		{
			path: "/account-deletion-manual",
			secure: false,
			component: AccountDeletionManual,
			hasRight: true,
		},

		{
			path: "/privacy-policy",
			secure: false,
			component: PrivacyPolicy,
			hasRight: true,
		},

		{
			path: "/terms-and-conditions",
			secure: false,
			component: TermsAndConditions,
			hasRight: true,
		},

		{
			path: "/downloads",
			secure: false,
			component: Downloads,
			hasRight: true,
		},

		{
			path: "/support",
			secure: false,
			component: Support,
			hasRight: true,
		},

		{
			path: "/login",
			secure: false,
			component: Login,
			hasRight: true,
		},

		{
			path: "/password-reset-options",
			secure: false,
			component: PasswordResetOptions,
			hasRight: true,
		},

		{
			path: "/send-reset-link",
			secure: false,
			component: SendPasswordResetLink,
			hasRight: true,
		},
		{
			path: "/reset-password",
			secure: false,
			component: ResetPassword,
			hasRight: true,
		},
		{
			path: "/register",
			secure: false,
			component: Register,
			hasRight: true,
		},
		{
			path: "/profile",
			secure: true,
			component: Profile,
			hasRight: true,
		},
		{
			path: "/books",
			secure: false,
			component: Books,
			hasRight: true,
		},

		{
			path: "/book/:bookId",
			secure: false,
			component: Book,
			hasRight: true,
		},

		{
			path: "/books/admin/my-books",
			secure: true,
			component: MyUploadedBooks,
			hasRight: hasRoles([Role.SeniorPublisher, Role.Publisher]),
		},

		{
			path: "/books/admin/all",
			secure: true,
			component: AllBooks,
			hasRight:
				hasRoles([
					Role.SuperAdmin,
					Role.Admin,
					Role.SeniorPublisher,
					Role.Publisher,
				]) && hasPermissions([Permission.ViewBooks]),
		},

		{
			path: "/error-report",
			secure: true,
			component: Exceptions,
			hasRight:
				hasRoles([Role.SuperAdmin]) && hasPermissions([Permission.ViewErrors]),
		},

		{
			path: "/ba-dashboard",
			secure: true,
			component: BrandAmbassadorDashboard,
			hasRight: hasRoles([Role.Ba]),
		},
		{
			path: "/institutions",
			secure: true,
			component: Institutions,
			hasRight: hasRoles([Role.SuperAdmin, Role.Admin]),
		},
		{
			path: "/ba/client-payments",
			secure: true,
			component: BaClientPayments,
			hasRight: hasRoles([Role.Ba]),
		},

		{
			path: "/books/publisher/purchased",
			secure: true,
			component: PublisherPurchasedBooks,
			hasRight:
				hasRoles([Role.SeniorPublisher, Role.Publisher]) &&
				hasPermissions([Permission.ViewSoldBooks]),
		},

		{
			path: "/brand-ambassadors",
			secure: true,
			component: BrandAmbassadors,
			hasRight:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewUser]),
		},
		{
			path: "/pay-brand-ambassador",
			secure: true,
			component: BrandAmbassadorPayouts,
			hasRight: hasRoles([Role.SuperAdmin, Role.Admin]),
		},

		{
			path: "/books/institution",
			secure: true,
			component: InstitutionBooks,
			hasRight:
				hasRoles([Role.Institution, Role.Educator]) &&
				hasPermissions([Permission.ViewBooks]),
		},

		// InstitutionBooks

		{
			path: "/subject-groups",
			secure: true,
			component: SubjectGroups,
			hasRight:
				hasRoles([
					Role.SuperAdmin,
					Role.Admin,
					Role.Institution,
					Role.Educator,
				]) && hasPermissions([Permission.ViewSubjectGroup]),
		},

		{
			path: "/books/admin/genres-and-categories",
			secure: true,
			component: GenreCategory,
			hasRight:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewCategory, Permission.ViewGenre]),
		},

		{
			path: "/my-library/picks",
			secure: true,
			component: MyPicks,
			hasRight: true,
		},

		{
			path: "/my-library",
			secure: true,
			component: MyLibrary,
			hasRight: true,
		},

		{
			path: "/books/:book_id",
			secure: false,
			component: BookPreview,
			hasRight: true,
		},

		{
			path: "/subscriptions",
			secure: false,
			component: Subscriptions,
			hasRight: true,
		},

		{
			path: "/subscription/pay/:subscriptionId",
			secure: false,
			component: SubscriptionCheckout,
			hasRight: true,
		},

		{
			path: "/cart",
			secure: true,
			component: Cart,
			hasRight: true,
		},

		{
			path: "/checkout",
			secure: true,
			component: Checkout,
			hasRight: true,
		},

		{
			path: "/payment-response",
			secure: true,
			component: PaymentResponse,
			hasRight: true,
		},

		{
			path: "/payment-error-response",
			secure: true,
			component: PaymentErrorResponse,
			hasRight: true,
		},

		{
			path: "/payment-success-response",
			secure: true,
			component: PaymentSuccessResponse,
			hasRight: true,
		},

		{
			path: "/subscription-response",
			secure: true,
			component: SubscriptionResponse,
			hasRight: true,
		},

		{
			path: "/subscription-error-response",
			secure: true,
			component: SubscriptionErrorResponse,
			hasRight: true,
		},

		{
			path: "/subscription-success-response",
			secure: true,
			component: SubscriptionSuccessResponse,
			hasRight: true,
		},

		{
			path: "/users/institution",
			secure: true,
			component: InstitutionUsers,
			hasRight: hasRoles([Role.Institution, Role.Educator]),
		},

		{
			path: "/users/publisher",
			secure: true,
			component: PublisherUsers,
			hasRight: hasRoles([Role.SeniorPublisher]),
		},

		{
			path: "/users/admin/all",
			secure: true,
			component: Users,
			hasRight:
				hasRoles([
					Role.SuperAdmin,
					Role.Admin,
					Role.SeniorPublisher,
					Role.Institution,
					Role.Educator,
				]) && hasPermissions(["view user"]),
		},

		{
			path: "/portal/users/:userId",
			secure: true,
			component: User,
			hasRight:
				hasRoles([
					Role.SuperAdmin,
					Role.Admin,
					Role.SeniorPublisher,
					Role.Institution,
					Role.Educator,
				]) && hasPermissions(["view user"]),
		},

		{
			path: "/admin-dashboard",
			secure: true,
			component: AdminDashboard,
			hasRight: hasRoles([Role.SuperAdmin, Role.Admin]),
		},

		{
			path: "/publishers",
			secure: true,
			component: Publishers,
			hasRight:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewPublisher]),
		},

		{
			path: "/publisher-dashboard",
			secure: true,
			component: PublisherDashboard,
			hasRight: hasRoles([
				Role.SeniorPublisher,
				Role.Publisher,
				Role.SuperAdmin,
			]),
		},

		{
			path: "/institution-dashboard",
			secure: true,
			component: InstitutionDashboard,
			hasRight: hasRoles([Role.Institution, Role.Educator]),
		},

		{
			path: "/roles-and-permissions",
			secure: true,
			component: RolesPermissions,
			hasRight:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewRole]),
		},

		{
			path: "/command-center",
			secure: true,
			component: CommandCenter,
			hasRight:
				hasRoles([Role.SuperAdmin]) && hasPermissions([Permission.RunCli]),
		},

		{
			path: "/pay-publisher",
			secure: true,
			component: PayPublisher,
			hasRight:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.RecordPubPayouts]),
		},

		{
			path: "/portal-profile",
			secure: true,
			component: PortalProfile,
			hasRight: true,
		},

		{
			path: "/access-denied",
			secure: true,
			component: Unauthorized,
			hasRight: true,
		},

		{
			path: "/vouchers",
			secure: true,
			component: Vouchers,
			hasRight:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewVoucher]),
		},

		{
			path: "/orders",
			secure: true,
			component: Orders,
			hasRight:
				hasRoles([Role.SuperAdmin, Role.Admin]) &&
				hasPermissions([Permission.ViewOrder]),
		},
	];

	return (
		// <Suspense fallback={<Fallback />}>
		<Switch
			location={location}
			key={location.pathname}
		>
			{routes.map(({ path, secure, component, hasRight }, index) => {
				return secure ? (
					<PrivateRoute
						exact
						path={path}
						isAuthenticated={isAuthenticated}
						hasRight={hasRight}
						key={index}
						component={component}
					/>
				) : (
					<PublicRoute
						exact
						path={path}
						isAuthenticated={isAuthenticated}
						key={index}
						component={component}
					/>
				);
			})}
			<Route component={NotFound} />
		</Switch>
		// </Suspense>
	);
};

export default Routes;

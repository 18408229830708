import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useSaveShippingAddress = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		const res = await instance.post("/shipping-addresses", body);
		await queryClient.invalidateQueries("shipping-addresses");
		await queryClient.invalidateQueries("orders");

		return res?.data?.data;
	};

	return useMutation(save, {
		onSuccess: async () => {
			toast.success("Shipping address saved successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};
export default useSaveShippingAddress;

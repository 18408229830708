import LocationPicker from "location-picker";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";

const PickOnMap = ({ visible, setVisible, _onConfirm }) => {
	const [location, setLocation] = useState(null);

	const googleMap = useRef(null);

	useEffect(() => {
		const google = window.google;

		const timeout = setTimeout(() => {
			if (google && visible && googleMap.current) {
				let locationPicker = new LocationPicker(
					googleMap.current,
					{
						setCurrentPosition: true,
					},
					{
						zoom: 15,
					}
				);

				google.maps.event.addListener(
					locationPicker.map,
					"idle",
					function (event) {
						let location = locationPicker.getMarkerPosition();
						setLocation(location);
					}
				);
			}
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [visible]);

	return (
		<Dialog
			visible={visible}
			header={<div className="py-2">Pick location on map</div>}
			onHide={() => setVisible(false)}
		>
			<div
				className="relative"
				style={{ width: "70vw" }}
			>
				<div
					className="w-full "
					style={{ height: 500 }}
					ref={googleMap}
				/>

				<div className="mt-7 flex justify-end">
					<button
						type="button"
						onClick={() => _onConfirm(location)}
						className={`px-4 py-2 flex items-center justify-center gap-2 text-white rounded-full text-base bg-gradient-to-r from-akgreen to-akgreenest
                                       `}
					>
						Confirm Location
					</button>
				</div>
			</div>
		</Dialog>
	);
};

export default PickOnMap;

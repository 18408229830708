import { useEffect } from "react";

/**
 * @typedef {Object} ShippingAddress
 * @property {string} id
 * @property {string} street_address
 * @property {string} google_address_id
 * @property {string} suburb
 * @property {string} city
 * @property {string} province
 * @property {string} country
 * @property {string} country_code
 * @property {number} distance_in_meters
 * @property {number} latitude
 * @property {number} longitude
 * @property {string} recipient_name
 * @property {string} recipient_phone
 * @property {boolean} is_default
 * @param {Object} props
 * @param {ShippingAddress} props.selectedShippingAddress
 * @param {React.Dispatch<React.SetStateAction<ShippingAddress>>} props.setSelectedShippingAddress
 * @param {ShippingAddress[]} props.shippingAddresses
 */
const ShippingAddressToUse = (props) => {
	const {
		selectedShippingAddress,
		setSelectedShippingAddress,
		shippingAddresses = [],
	} = props;

	const shouldShowAddressSelector = shippingAddresses?.length > 1;

	// Check if addresses exist before accessing
	const defaultShippingAddress = shippingAddresses?.[0];

	useEffect(() => {
		// Only set if not already selected and addresses exist
		if (defaultShippingAddress) {
			setSelectedShippingAddress(defaultShippingAddress);
		}
	}, [defaultShippingAddress, setSelectedShippingAddress]);

	const handleChangeShippingAddress = (e) => {
		const addressId = e.target.value;
		const address = shippingAddresses.find(
			(address) => Number(address.id) === Number(addressId)
		);
		if (address) {
			setSelectedShippingAddress(address);
		}
	};

	// Don't render if no addresses
	if (!shippingAddresses?.length) {
		return <div>No shipping addresses found</div>;
	}

	return (
		<div className="w-full flex flex-col-reverse lg:flex-row justify-between gap-4 space-y-2 ">
			<div>
				<h3 className="font-cera-black">Delivery address</h3>

				<div>
					{selectedShippingAddress && (
						<>
							<p>
								Identified as{" "}
								<span className="font-bold">
									{selectedShippingAddress.nickname}
								</span>
							</p>
							<p>
								{selectedShippingAddress.street_address},{" "}
								{selectedShippingAddress.suburb}
							</p>
							<p>{selectedShippingAddress.city}</p>
							<p>{selectedShippingAddress.province}</p>
							<p>{selectedShippingAddress.country}</p>
							<p>
								<span className="font-bold">Recipient name:</span>{" "}
								{selectedShippingAddress.recipient_name}
							</p>
							<p>
								<span className="font-bold">Recipient phone:</span>{" "}
								{selectedShippingAddress.recipient_phone}
							</p>
						</>
					)}
				</div>
			</div>

			{shouldShowAddressSelector && (
				<div className="flex flex-col gap-2">
					<p className="text-gray-500 lg:text-right">Change address</p>
					<select
						className="h-8 px-2 rounded w-auto xs:w-80"
						onChange={handleChangeShippingAddress}
						value={selectedShippingAddress?.id || ""} // Add controlled value
					>
						{shippingAddresses.map((address) => {
							const nickname =
								address.nickname ||
								`${address.street_address} ${address.suburb}, ${address.city} (${address.country_code})`;
							return (
								<option
									key={address.id}
									value={address.id}
								>
									{nickname}
								</option>
							);
						})}
					</select>
				</div>
			)}
		</div>
	);
};

export default ShippingAddressToUse;

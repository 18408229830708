import useAuthStore from "store/authStore";
import { Role } from "utils/server-constants/roles";

const usePortalDashboard = () => {
	const { hasRoles, roles } = useAuthStore();
	const dashboardMap = {
		"Super Administrator": "admin-dashboard",
		Administrator: "admin-dashboard",
		"Senior Publisher": "publisher-dashboard",
		Publisher: "publisher-dashboard",
		Institution: "institution-dashboard",
		Educator: "institution-dashboard",
		"Brand Ambassador": "ba-dashboard",
	};

	const dashboardUrl = roles ? dashboardMap[roles[0]] : "#";

	const canAccessPortal = hasRoles([
		Role.SuperAdmin,
		Role.Admin,
		Role.Publisher,
		Role.SeniorPublisher,
		Role.Institution,
		Role.Educator,
		Role.Ba,
	]);

	return {
		dashboardUrl,
		canAccessPortal,
	};
};

export default usePortalDashboard;

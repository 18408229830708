import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useGetUserShippingAddresses = (userId = null) => {
	const { accessToken } = useTokenStore();
	const url = userId
		? `/shipping-addresses/user/${userId}`
		: "/shipping-addresses/user";
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const res = await instance.get(url);
		return res.data?.data?.addresses;
	};

	return useQuery(["shipping-addresses", userId], () => fetch(), {
		...fetchConfig,
	});
};

export default useGetUserShippingAddresses;

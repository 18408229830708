import { useHistory, useLocation } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { isNotAuthenticated } from "utils/errors";

const useRedirectOnUnauthenticated = () => {
	const history = useHistory();
	const location = useLocation();

	return (statusCode) => {
		if (isNotAuthenticated(statusCode)) {
			reactLocalStorage.clear();
			return history.push(`/login?intended=${location.pathname}`);
		}

		return;
	};
};

export default useRedirectOnUnauthenticated;

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useApplyAutoDeduction = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const submit = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);

		const url = `/apply-recurring-subscription/${body?.subscription}`;

		const res = await instance.post(url, body);
		await queryClient.invalidateQueries("profile");
		return res.data;
	};
	return useMutation(submit, {
		onSuccess: async () => {
			toast.success("Auto-deduction applied successfully");
		},

		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useApplyAutoDeduction;

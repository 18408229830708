import React from "react";
import useDeleteShippingAddress from "api/AddressResource/useDeleteShippingAddress";
import { FiTrash } from "react-icons/fi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

/**
 * @param {Object} props
 * @param {string} props.addressId
 * @param {string} props.className
 * @returns {React.ReactNode}
 */
const DeleteShippingAddress = (props) => {
	const { addressId, className } = props;
	const iconSize = 20;

	const { mutateAsync, isLoading } = useDeleteShippingAddress(addressId);

	const handleDeleteAddress = async () => {
		await mutateAsync();
	};

	return (
		<button
			type="button"
			onClick={() => handleDeleteAddress()}
			className={` text-red-400 hover:text-red-500 flex justify-center items-center rounded-full ${className}`}
		>
			{isLoading ? (
				<AiOutlineLoading3Quarters
					size={iconSize}
					className="animate-spin"
				/>
			) : (
				<FiTrash size={iconSize} />
			)}
		</button>
	);
};

export default DeleteShippingAddress;

import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useUpdateUserSubjectGroups = (userId) => {
	const queryClient = useQueryClient();

	const { accessToken } = useTokenStore();

	const update = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		const response = await instance.patch(
			`/users/update-subject-groups/${userId}`,
			body
		);
		await queryClient.invalidateQueries("users");
		return response?.data;
	};

	return useMutation(update, {
		onSuccess: async () => {
			toast.success("User updated successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);
		},
	});
};

export default useUpdateUserSubjectGroups;

import { BookListItem, CustomPaginator } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { useMemo } from "react";
import useMyLibraryFilters from "./useMyLibraryFilters";
import useMyLibrary from "api/BookResource/useMyLibrary";
import BooksLoader from "pages2/Books/BooksLoader";
import { Link, useLocation } from "react-router-dom";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import { IoDocumentsOutline } from "react-icons/io5";
import MyLibraryLayout from "./MyLibraryLayout";
import BookPaginationLoader from "pages2/Books/BookPaginationLoader";

const MyLibrary = () => {
	const filters = useMyLibraryFilters();

	const { pathname } = useLocation();

	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();

	const { data: purchasedBooks, isLoading: isLoadingPurchasedBooks } =
		useMyLibrary({
			page: filters?.page,
			rows_per_page: filters?.rowsPerPage,
		});

	const disableRowsPerPageChanger = useMemo(() => {
		if (
			parseInt(filters?.page) === parseInt(purchasedBooks?.data?.total_pages) &&
			parseInt(filters?.page) !== 1
		) {
			return true;
		}
		return false;
	}, [filters?.page, purchasedBooks]);

	const handleChangeRowsPerPage = (value) => {
		if (value === filters?.rowsPerPage) return;
		filters.changeRowsPerPage(value);
	};

	return (
		<Container>
			<div className="mt-2">
				<PaddingX>
					<MyLibraryLayout>
						{(function () {
							if (isLoadingPurchasedBooks || isLoadingSubscribed) {
								return (
									<>
										<div className="pt-5 ">
											<BookPaginationLoader />
										</div>

										<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-4 gap-y-6 place-items-stretch mt-4">
											{Array.from({ length: 18 }, (_, i) => (
												<BooksLoader key={i} />
											))}
										</div>
									</>
								);
							}

							return (
								<>
									<div className="flex justify-between items-start pt-2">
										<div className="hidden xs:block">
											<p className="text-base sm:text-lg">
												Showing your purchases - page {filters?.page} of{" "}
												{purchasedBooks?.data?.total_pages} page(s)
											</p>
										</div>

										<span className="flex items-center justify-end xs:hidden ">
											<Link
												to="/my-library"
												className={`py-2 px-2 border border-akgreener rounded-l-full ${
													pathname === "/my-library"
														? "bg-akgreener text-white"
														: ""
												} `}
											>
												Purchased
											</Link>
											<Link
												to="/my-library/picks"
												className={`py-2 px-2 border border-akgreener rounded-r-full ${
													pathname === "/my-library/picks"
														? "bg-akgreener text-white"
														: ""
												}`}
											>
												Picked
											</Link>
										</span>

										<div className="flex items-center justify-end gap-2 ">
											<span>Show</span>

											<select
												name="rowsPerPage"
												id="rowsPerPage"
												value={filters.rowsPerPage}
												onChange={(e) =>
													handleChangeRowsPerPage(e.target.value)
												}
												disabled={disableRowsPerPageChanger}
												className="border border-gray-300 bg-white rounded h-10 px-2"
											>
												<option value="10">10</option>
												<option value="20">20</option>
												<option value="40">40</option>
												<option value="50">50</option>
											</select>
										</div>
									</div>

									<div className="mt-4">
										{(function () {
											if (purchasedBooks?.data?.records?.length === 0) {
												return (
													<div className="flex flex-col items-center justify-center gap-4">
														<IoDocumentsOutline
															size={200}
															className="text-green-200"
														/>

														<div className="flex flex-col items-center gap-x-20">
															<h4 className="text-center">
																You have not purchased any books yet
															</h4>
															<div className="flex justify-center divide-x-2 divide-blue-400 tex-base font-bold">
																<Link
																	to="/books"
																	className="text-blue-900 px-3 py-2 "
																>
																	Browse books
																</Link>

																<Link
																	to="/my-library/picks"
																	className="text-blue-900 px-3 py-2"
																>
																	View picks
																</Link>
															</div>
														</div>
													</div>
												);
											}

											return (
												<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-4 gap-y-6 ">
													{purchasedBooks?.data?.records?.map(
														(purchasedBook) => (
															<BookListItem
																key={purchasedBook.id}
																coverUrl={purchasedBook?.book_cover?.url}
																title={purchasedBook?.title}
																author={purchasedBook?.author}
																description={purchasedBook?.description}
																price={purchasedBook?.price}
																zwl_price={purchasedBook?.zwl_price}
																credits={purchasedBook.credits}
																publisher={
																	purchasedBook?.user?.publishers[0]?.name ||
																	`${purchasedBook?.user?.first_name} ${purchasedBook?.user?.last_name}`
																}
																reviews={purchasedBook.ratings}
																bookId={purchasedBook.id}
																sellable={purchasedBook.sellable}
																rating={purchasedBook.rating}
																isbn={purchasedBook.rating}
																isPurchased={purchasedBook.is_purchased}
																inLibrary={purchasedBook.in_library}
																subscription={purchasedBook.subscription}
																hasSubscription={isSubscribed?.data}
																category={purchasedBook?.category?.name}
																isFree={purchasedBook?.isFree}
																genres={purchasedBook?.genre?.map((genre) => [
																	genre.id,
																	genre.name,
																])}
															/>
														)
													)}
												</div>
											);
										})()}

										<div className="flex-1 flex items-end mt-6 border-t pt-6">
											<CustomPaginator
												firstPage={filters.firstPage}
												setFirstPage={filters.changeFirstPage}
												rowsPerPage={filters.rowsPerPage}
												setRowsPerPage={filters.changeRowsPerPage}
												totalRecords={purchasedBooks?.data?.total_records}
												setPage={filters.changePage}
												showRowsPerPageDropdown={false}
											/>
										</div>
									</div>
								</>
							);
						})()}
					</MyLibraryLayout>
				</PaddingX>
			</div>
		</Container>
	);
};

export default MyLibrary;

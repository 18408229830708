import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useImportUsers = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const history = useHistory();

	const importBooks = async (obj) => {
		const instance = await InitApi({ accessToken }, "multipart/form-data");

		const body = deleteEmptyObjects(obj);

		const formData = new FormData();
		Object.keys(body).forEach((key) => {
			formData.append(key, body[key]);
		});

		await queryClient.invalidateQueries("users");

		const response = await instance.post("/users/import", formData);
		return response;
	};

	return useMutation(importBooks, {
		onSuccess: async ({ data: body }) => {
			// queryClient.invalidateQueries("myUploadedBooks");
			toast.success(body.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>,
				{
					className: "max-w-max",
				}
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useImportUsers;
